import "react-toastify/dist/ReactToastify.css";
import "./CustomToast.css";

import { Slide, toast } from "react-toastify";

const CustomToast = (msg: string, type: string) => {
  switch (type) {
    case "success":
      return toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_success",
      });
    case "error":
      return toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_error",
      });
    case "warning":
      return toast.warn(msg, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_warning",
      });
    case "info":
      return toast.info(msg, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_info",
      });
    case "networkDisconnected":
      return toast.error(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_network_disconnected",
      });
    case "networkConnected":
      return toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_network_connected",
      });
    default:
      return toast(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        className: "toast_message_default",
      });
  }
};

export default CustomToast;
