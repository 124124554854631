import './Loader.scss';

import React from 'react';
import { Spin } from 'antd';

interface LoaderProps {
  spinning?: boolean;
  children?: React.ReactNode;
}

const Loader: React.FC<LoaderProps> = ({ spinning = true, children }) => {
  return (
    <Spin spinning={spinning} tip="Loading...">
      {children}
    </Spin>
  );
};

export default Loader;
