export const urlConstants = Object.freeze({
    NODE_ENV_TEST_URL: 'https://seedmain.seedinternaldev.xyz/api/1',
    NODE_ENV_PROD_URL: 'https://agency.massic.io/api/1',
    NODE_ENV_Localhost_URL: 'http://localhost:4922/api/1',
    CORE_ENV_TEST_URL: 'https://seedcore.seedinternaldev.xyz/api',
    CORE_ENV_PROD_URL: 'https://core.massic.io/api',
    CopyRights: `Copyrights @ Kanahiku LLC ${new Date().getFullYear()}.`,
    TEST_AI_CHATBOT_URL: 'https://reviewai.seedinternaldev.xyz',
    SessionTimeout: 30 * 60 * 1000,
    ENV_URL: 'https://www.seedinternaldev.xyz', //http://localhost:3000 or https://www.seedinternaldev.xyz //https://massic.io/
    AI_API_KEY: 'sk-proj-J3DiIDImJmJ6LuCnkcxCT3BlbkFJOe6mOPcgxsZD0W5gKvac',
    CLIENT_ID: '137948605480-56kn6b8a8ti77d95qu3usknlanq05fsj.apps.googleusercontent.com',
    MASSIC_VERSION: "massic v1.1.0",
    SENDER_EMAIL_ID: "hello@updates.massic.io",  //hello@updates.massic.io   rajkumar@kanahiku.com
    ALLOWED_HOSTNAMES: ["www.seedinternaldev.xyz", "massic.io"],   //, "localhost" "www.seedinternaldev.xyz" "massic.io"
    VAPIDKEY: "BK1KLGvUQ602zcedceVPvoxTybmDrmecvP4yntrfMJGQdrLLtrod5iS64e0NOknlv4g1d3ZOlEzeJRWZAphx1SU"
                //BF_3h614XeLHdIOnoojqDqf7Hk5Or3yoKgJhfFI_PKKkUxcmYxEXLHgg-o8Ds_vy-xbUmpKO27aPdY1MsFnvpII  -PROD
               // BK1KLGvUQ602zcedceVPvoxTybmDrmecvP4yntrfMJGQdrLLtrod5iS64e0NOknlv4g1d3ZOlEzeJRWZAphx1SU -TEST
})
  

