import "./CommonModalPopUp.scss";

import { Box, Button, IconButton, Modal, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface CommonModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message1: string;
  message2: string;
  standardLabel: string;
  cancelLabel?: string;
  onStandard: () => void;
  onCancel?: () => void;
}

const CommonModalPopUp: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  message1,
  message2,
  standardLabel,
  cancelLabel,
  onStandard,
  onCancel,
}) => {
  return (
    <Modal open={open} onClose={onClose} className="modal-wrapper">
      <Box className="common-modal">
        <Box className="common-modal-header">
          <Typography variant="h6">{title}</Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="common-modal-content">
          <Typography variant="body1">{message1}</Typography>
          <Typography variant="subtitle1">{message2}</Typography>
        </Box>

        <Box className="common-modal-actions">
          {cancelLabel && onCancel && (
            <Button
              onClick={onCancel}
              color="error"
              size="large"
              variant="outlined"
            >
              {cancelLabel}
            </Button>
          )}
          <Button
            onClick={onStandard}
            color="primary"
            variant="contained"
            size="large"
          >
            {standardLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonModalPopUp;
