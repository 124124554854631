import "./Fileupload.scss";

import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { MdCloudUpload, MdDelete } from "react-icons/md";
import React, { useState } from "react";
import  { useApi, IAPIConfig } from "../../../custom_hooks/useApi";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CustomToast from "../../../utils/cutomToast/CustomToast";

interface CustomFileuploadProps {
  handleViewPreview: () => void;
  setSelectedFile: (file: File | null) => void; // Accept a file or null
  fileSupported?: string;
  // container: string; 
  // entity: string;
  // itemId: string;
}

interface SettingsData {
  Container: string;
  Entity: string;
  ItemId: string;
}


const CustomFileupload: React.FC<CustomFileuploadProps> = ({
  handleViewPreview,
  setSelectedFile,
  fileSupported,
  // container,
  // entity,
  // itemId
}) => {

  const { doApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [uploadFileUrl, setUploadedFileUrl] = useState<string | null>(null);

  console.log("selectedImage",uploadFileUrl);
  const [fileName, setFileName] = useState<string>("No selected file");
  const [image, setImage] = useState<string>("");
  const [selectedFile, setFile] = useState<File | null>(null); // Track the selected file

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    setFileName(file.name);
    setSelectedFile(file); // Set the selected file to parent component state
    setFile(file); // Set file in local state

    // Handle image or file preview (if PDF, create a preview URL)
    if (file.type === "application/pdf") {
      setImage("https://techterms.com/img/lg/pdf_109.png"); // Show a pdf icon for the PDF if necessary
    } else {
      setImage("https://www.pk-anexcelexpert.com/wp-content/uploads/2018/10/Excel-Icon.png");
    }
  };


  const debounceImmediate = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout | null;
    
    return (...args: any[]) => {
      const callNow = !timeoutId;
      clearTimeout(timeoutId as NodeJS.Timeout);
  
      timeoutId = setTimeout(() => {
        timeoutId = null;
      }, delay);
  
      if (callNow) func(...args);
    };
  };

  // Debounced version of toast.error
  const debouncedToastError = debounceImmediate((message: string) => {
    CustomToast(message, "error");
  }, 3000);

  const uploadFile = async () => {

    
    // Check if sessionStorage has form data
    const savedSettingsDataString = sessionStorage.getItem('formData');

    const savedSettingsData: SettingsData | null = savedSettingsDataString ? JSON.parse(savedSettingsDataString) : null;

    if(!savedSettingsData){
      debouncedToastError("Please configure the settings");
    }else{
        if (selectedFile) {
          const file = selectedFile;
          
          // Convert file to binary using FileReader
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onloadend = async () => {
            const binaryData = reader.result as ArrayBuffer;
    
            // Prepare form data for the API request
            const formData: any = new FormData();
    
            const fileBlob = new Blob([binaryData], { type: file.type });
    
            formData.append("UploadFile", fileBlob, file.name);
    
            try {
              setLoading(true);
              const FileUploadConfig: IAPIConfig = {
                URL: `/Storage/UploadToBlob?Container=${savedSettingsData?.Container}&Entity=${savedSettingsData?.Entity}&ItemId=${savedSettingsData?.ItemId}`,
                method: "POST",
                payLoad: formData,
              };
              const res: any = await doApi(FileUploadConfig, "seedcore");
    
              if (res.data.err ===  false) {
                CustomToast("File Upload Successfully", "success");
    
                setUploadedFileUrl(res?.data?.uri);
                setFileName(res?.data?.filename);
                setSelectedFile(null); // Clear the selected file in parent state
                setFile(null); // Clear file in local state
                setImage("");
              } else {
                setFileName("No selected file");
                setSelectedFile(null); // Clear the selected file in parent state
                setFile(null); // Clear file in local state
                setImage("");
                setUploadedFileUrl("");
              }
            } catch (err: any) {
              CustomToast(err.message, "error");
              setFileName("No selected file");
              setSelectedFile(null); // Clear the selected file in parent state
              setFile(null); // Clear file in local state
              setImage("");
              setUploadedFileUrl("");
    
            } finally {
              setLoading(false);
            }
          };
        }
    }
   
  };



  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<String | null>(null);


  const handleClickOpen = (doc: String) => {
    setSelectedDoc(doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  // const getFileExtension = (url: string) => {
  //   const ext = url.split('.').pop(); // Extract the part after the last '.'
  //   return ext ? ext.toLowerCase() : ''; // Ensure it's lowercase for consistency
  // };
  

  return (
    <>
    <div>
      {(image || uploadFileUrl) ? (
        uploadFileUrl ? (
          <div className="upload-form">
            <CheckCircleIcon className="sucessIcon"  />
            <h2>Upload Successfully!</h2>
            <h4>File Name: {fileName}</h4>
            <p>
              To view preview.{" "}
              <Button onClick={()=>handleClickOpen(String(uploadFileUrl))}>Click Here</Button>
              {/* <a className="preview-txt" href={uploadFileUrl} target="_blank" rel="noreferrer noopener" >
                Click Here
              </a> */}
            </p>
          </div>
        ): (
        <div className="upload-form">
          <img src={image} className="placeholder-img" alt={fileName} />
          <button
          disabled={loading}
           className="upload-btn" 
           onClick={uploadFile}>
           {loading? "Uploading..." : "Upload"}
          </button> {/* Trigger upload */}
          <p>
            To view preview.{" "}
            <span onClick={handleViewPreview} className="preview-txt">
              Click Here
            </span>
          </p>
        </div>
        )
      ) : (
        <form
          onClick={() =>
            document.querySelector<HTMLInputElement>(".input-field")?.click()
          }
          className="upload-form"
        >
          <input
            type="file"
            accept={fileSupported}
            className="input-field"
            hidden
            onChange={handleFile}
          />

          <>
            <MdCloudUpload className="uploadIcon" size={60} />
            <p>Browse Files to upload</p>
            <span className="example">(Eg. - {fileSupported})</span>
          </>
        </form>
      )}
      {(image || uploadFileUrl) && (
        <section className="uploaded-row">
          <span className="upload-content">
            {fileName} -
            <MdDelete
              color="#ff6f0f"
              onClick={() => {
                setFileName("No selected file");
                setSelectedFile(null); // Clear the selected file in parent state
                setFile(null); // Clear file in local state
                setImage("");
                setUploadedFileUrl("");
              }}
              style={{ cursor: "pointer" }}
            />
          </span>
        </section>
      )}
    </div>
    

    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
        <DialogTitle>
          File Preview
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        {selectedDoc && 
      
     
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(String(selectedDoc))}&embedded=true`}
            width="100%"
            height="500px"
            title="File Preview"
            style={{ border: 'none' }}
          />
      
      
         }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomFileupload;
