/* eslint-disable react-hooks/exhaustive-deps */

import "./MassicChatView.scss";

import * as XLSX from "xlsx";

import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CustomDialog from "../Dialog/CustomDialog";
import CustomFileupload from "./UploadFile/FileUpload";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DataArchives from "../../Pages/dataarchieve/DataArchives";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneIcon from "@mui/icons-material/Done";
import LoopIcon from "@mui/icons-material/Loop";
import Papa from "papaparse";
import Popover from "@mui/material/Popover";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import { TextField } from "@mui/material";
import axios from "axios";

// Define types for messages
interface Message {
  text: string;
  sender: "user" | "bot";
}

interface SettingsData {
  Container: string;
  Entity: string;
  ItemId: string;
}

interface MassicChatViewProps {
  // isChatOpen: boolean; // Visibility passed as a prop
  // onToggleChat: () => void; // Callback for toggling chat visibility
  defaultTitle?: string; // Add title prop here
  height?: Number;
  timeout?: number; // Timeout in minutes
  apiUrl: string; // API URL
  apiMethod: "GET" | "POST" | "PUT" | "DELETE"; // API method
  fileSupported?: string;
}

const MassicChatView: React.FC<MassicChatViewProps> = ({
  defaultTitle,
  height,
  timeout,
  apiMethod,
  apiUrl,
  fileSupported,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [hasError, setHasError] = useState<boolean>(false); // Error state
  const [isTyping, setIsTyping] = useState<boolean>(false); // Typing animation state
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [title, setTitle] = useState(defaultTitle);
  const [apiEndPoint, setApiEndPoint] = useState(
    "https://reviewai-pescabeach.seedinternaldev.xyz"
  );
  const [anchorElRefresh, setAnchorElRefresh] = useState<HTMLElement | null>(
    null
  );
  const [selectedValue, setSelectedValue] = useState(
    "https://reviewai-pescabeach.seedinternaldev.xyz"
  );

  const [dialogState, setDialogState] = useState<{
    fileUpload: boolean;
    filePreview: boolean;
    settings: boolean;
  }>({
    fileUpload: false,
    filePreview: false,
    settings: false,
  });

  // Function to toggle dialogs
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };

  const [isChatOpen, setIsChatOpen] = useState(true);

  // Function to toggle chat visibility
  const handleToggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  // Function to handle message submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.trim() === "") return;

    // Add user message to the chat history
    const userMessage: Message = { text: `${input}`, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Clear the input field
    setInput("");

    // Simulate loading and typing state
    setIsLoading(true);
    setIsTyping(true);
    setHasError(false);

    const controller = new AbortController();
    const signal = controller.signal;

    //Convert timeout from minutes to milliseconds (1 minute = 60,000 milliseconds)
    const timeoutInMs = (timeout || 1) * 60000; // Default to 1 minute if no timeout is provided

    // Set timeout to abort the request if it takes longer than 5 seconds
    const timeoutId = setTimeout(() => {
      controller.abort(); // Abort the request
    }, timeoutInMs);

    try {
      const config = {
        method: apiMethod,
        url: `${apiEndPoint}${apiUrl}=${encodeURIComponent(input)}`,
        signal,
      };

      const response = await axios(config);

      clearTimeout(timeoutId); // Clear the timeout if the request completes in time

      const data = response.data; // Access the JSON response

      // Now access the answer from the chatbot
      const botMessage: Message = { text: data?.answer, sender: "bot" };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error: any) {
      if (error.name === "AbortError") {
        setHasError(true); // Set error state for timeout
        console.error("Request timed out");
      } else {
        setHasError(true); // Set error state for other types of failure
        console.error("Axios error:", error);
      }
    } finally {
      setIsLoading(false); // End loading state
      setIsTyping(false); // End typing state
    }
  };

  useEffect(() => {
    setApiEndPoint(apiEndPoint);
    setTitle(title);
  }, [selectedValue]);

  // Function to handle clearing the chat
  const handleClear = () => {
    setMessages([]);
  };

  const handleInputClear = () => {
    setInput("");
  };

  const [fileData, setFileData] = useState<any[]>([]);

  // Handle CSV Parsing (only for CSV files)
  const parseCSVFile = (file: File) => {
    Papa.parse(file, {
      complete: (result: any) => {
        // Extract the headers (first row) from the CSV
        const headers = result.data[0];
        
        // Filter the rest of the rows (excluding the first row) to remove empty rows
        const filteredData = result.data.slice(1).filter((row: any) => {
          return Object.values(row).some(value => value !== null && value !== '');
        });
  
        // Re-add the headers at the start of the filtered data
        const finalData = [headers, ...filteredData];

  
        setFileData(finalData); // Set the cleaned-up data to state
      },
      header: true, // If CSV has headers
    });
  };

  const parseExcelFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setFileData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  // Handle View Preview Button
  const handleViewPreview = () => {
    if (selectedFile) {
      const fileType = selectedFile.type;

      if (fileType === "text/csv") {
        parseCSVFile(selectedFile); // Parse CSV before showing preview
      } else if (fileType === "application/pdf") {
        const pdfPreviewURL = URL.createObjectURL(selectedFile);
        setFilePreview(pdfPreviewURL); // Set the preview URL for rendering
      } else if (fileType === "application/vnd.ms-excel") {
        console.log("selectedFile", selectedFile);

        parseExcelFile(selectedFile); // Parse Excel before showing preview
      }

      toggleDialog("filePreview"); // Open the preview dialog
    }
  };

  // Handle preview dialog close
  const handleClosePreview = () => {
    toggleDialog("filePreview");
    setFilePreview(null); // Clean up the preview URL
  };

  const handleFileUploadDialog = () => {
    toggleDialog("fileUpload");
    setFileData([]);
  };

  // const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // To control tabs

  // const handleSettingsOpen = () => {
  //   setIsSettingsOpen(!isSettingsOpen);
  // };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function to save data to sessionStorage

  const [settingsData, setSettingsData] = useState<SettingsData>({
    Container: "",
    Entity: "",
    ItemId: "",
  });

  const [errors, setErrors] = useState<SettingsData>({
    Container: "",
    Entity: "",
    ItemId: "",
  });

  useEffect(() => {
    // Check if sessionStorage has form data
    const savedData = sessionStorage.getItem("formData");
    if (savedData) {
      setSettingsData(JSON.parse(savedData));
    }
  }, []);

  // Function to update formData state when input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettingsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear error message when user types in the field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = (): boolean => {
    let formIsValid = true;
    const newErrors: SettingsData = {
      Container: "",
      Entity: "",
      ItemId: "",
    };

    // Check for empty fields
    if (!settingsData.Container) {
      formIsValid = false;
      newErrors.Container = "Category is required";
    }
    if (!settingsData.Entity) {
      formIsValid = false;
      newErrors.Entity = "Sub Category is required";
    }
    if (!settingsData.ItemId) {
      formIsValid = false;
      newErrors.ItemId = "Business is required";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = () => {
    // Validate form before saving
    if (validateForm()) {
      // Save form data to sessionStorage
      sessionStorage.setItem("formData", JSON.stringify(settingsData));
      CustomToast("Settings Saved Successfully", "success");
      handleClose();
    } else {
      CustomToast("Please fill in all required fields.", "error");
    }
  };

  const radioOptions = [
    {
      label: "Pesca Beach",
      value: "https://reviewai-pescabeach.seedinternaldev.xyz",
      enabled: true,
    },
    {
      label: "ValleyIsleMotors",
      value: "https://reviewai-valleyislemotors.seedinternaldev.xyz",
      enabled: true,
    },
    { label: "Banyan Networks", value: "banyanNetworks", enabled: false },
    { label: "SallyMorinLaw", value: "sallyMorinLaw", enabled: false },
    { label: "Dental Properties", value: "dentalProperties", enabled: false },
  ];

  // Handle click to open the popover
  const handleRefreshChat = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRefresh(event.currentTarget);
  };

  // Handle close of the popover
  const handleCloseRefresh = () => {
    setAnchorElRefresh(null);
  };

  const openRefresh = Boolean(anchorElRefresh);
  const idRefresh = openRefresh ? "simple-pop-over-refresh" : undefined;

  // Handle radio button selection
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const selectedOption = radioOptions.find(
      (option) => option.value === selectedValue
    );

    
    setTitle(`Chat With ${selectedOption?.label || ""}`);
    setApiEndPoint(selectedValue);
    setSelectedValue(selectedValue);
    setAnchorElRefresh(null);
  };

  return (
    <>
      <div className="chatView-container">
        <div className="dataArchives-container" >
          <DataArchives />
        </div>

        <div className="chat-content">
          {/* Toggle Arrow Button */}
          <div onClick={handleToggleChat} className="toggleButton">
            {isChatOpen ? (
              <ArrowRightIcon className="arrowIcon" />
            ) : (
              <ArrowLeftIcon className="arrowIcon" />
            )}
          </div>

          {/* Chat Window */}
          {isChatOpen && (
            <div className="chatContainer">
              {/* Toolbar Section */}
              <div className="toolbar">
                <button
                  className="toolbarButton"
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <SettingsIcon />
                </button>
                <button
                  className="toolbarButton"
                  onClick={handleFileUploadDialog}
                >
                  <AddIcon />
                </button>
                <button className="toolbarButton" onClick={handleRefreshChat}>
                  <LoopIcon />
                </button>
                <button className="toolbarButton" onClick={handleClear}>
                  <DeleteOutlineIcon />
                </button>
              </div>

              {/* Popover to display the radio buttons */}
              <Popover
                id={idRefresh}
                open={openRefresh}
                anchorEl={anchorElRefresh}
                onClose={handleCloseRefresh}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {/* Scrollable container with a maximum height */}
                <Box sx={{ p: 2, maxHeight: 200, overflow: "auto" }}>
                  <Typography variant="h6">Select Website</Typography>
                  <RadioGroup
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    {radioOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        checked={option.value === selectedValue}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        disabled={!option.enabled}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Popover>
              {/* Settings Dialog */}

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 140, left: 1163 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Upload" />
                  <Tab label="Settings1" />
                  <Tab label="Settings2" />
                </Tabs>

                <div className="tabContent">
                  {tabIndex === 0 && (
                    <Box className="form-container">
                      <form>
                        <div className="form-item">
                          <label>Category:</label>
                          <TextField
                            variant="outlined"
                            placeholder=""
                            fullWidth
                            name="Container"
                            value={settingsData.Container}
                            onChange={handleChange}
                            error={!!errors.Container}
                            helperText={errors.Container}
                          />
                        </div>
                        <div className="form-item">
                          <label>Sub-Category:</label>
                          <TextField
                            variant="outlined"
                            placeholder=""
                            fullWidth
                            name="Entity"
                            value={settingsData.Entity}
                            onChange={handleChange}
                            error={!!errors.Entity}
                            helperText={errors.Entity}
                          />
                        </div>
                        <div className="form-item">
                          <label>Business:</label>
                          <TextField
                            variant="outlined"
                            placeholder=""
                            fullWidth
                            name="ItemId"
                            value={settingsData.ItemId}
                            onChange={handleChange}
                            error={!!errors.ItemId}
                            helperText={errors.ItemId}
                          />
                        </div>
                        <div className="buttonGroup">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </div>
                      </form>
                    </Box>
                  )}

                  {tabIndex === 1 && (
                    <Box p={2}>
                      <h3>Settings 1</h3>
                      <p>Settings 1 content...</p>
                    </Box>
                  )}

                  {tabIndex === 2 && (
                    <Box p={2}>
                      <h3>Settings 2</h3>
                      <p>Settings 2 content...</p>
                    </Box>
                  )}
                </div>
              </Popover>

              {/* Header Section */}
              <div className="header">
                <h2 className="headerTitle">{title}</h2>
              </div>

              {/* Chat messages */}
              <div className="chatBox">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={
                      msg.sender === "user"
                        ? "userMessage"
                        : msg.text.startsWith("I'm sorry")
                        ? "botMessage error"
                        : "botMessage"
                    }
                  >
                    {msg.text}
                    {msg.sender === "user" && <DoneIcon className="doneIcon" />}
                  </div>
                ))}

                {/* Typing animation */}
                {isTyping && (
                  <div className="botMessage">
                    <div className="typing">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}

                {/* Loading Spinner */}
                {isLoading && (
                  <div className="loading">
                    <div className="loader"></div>
                  </div>
                )}

                {/* Error Message */}
                {hasError && (
                  <div className="errorMessage">
                    <span className="errorIcon">⚠️</span> Failed to get
                    response.
                  </div>
                )}
              </div>

              {/* Input and Send button */}
              <div className="inputContainer">
                {/* Image Preview */}
                {/* <div >
                    {filePreview && (
                      <div className="imageContainer" >
                        <img src={filePreview} alt="Preview" />
                        <span  className="remove-button clearPreviewButton" onClick={handleClearFile}>
                        <ClearIcon /> 
                        </span>
                      </div>
                    )}
                </div> */}
                <form onSubmit={handleSubmit} className="formContainer">
                  <textarea
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="inputField"
                    placeholder="Type a message..."
                    disabled={isLoading}
                  />

                  {/* File Upload Icon */}
                  {/* <div>
                      <label htmlFor="file-upload">
                        <AttachFileIcon className='attachIcon' />
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        className='fileInput'
                        onChange={handleFileChange}
                        disabled={isLoading}
                      />
                    </div> */}

                  <div className="ButtonContainer">
                    <button
                      type="submit"
                      className="sendButton"
                      disabled={isLoading}
                    >
                      <SendIcon className="icon" />
                    </button>
                    <button
                      type="button"
                      onClick={handleInputClear}
                      className="sendButton"
                    >
                      <DeleteOutlineIcon className="icon2" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

      </div>

      {/* File Upload Dialog */}
      <CustomDialog
        open={dialogState.fileUpload}
        onClose={() => toggleDialog("fileUpload")}
        title="Upload File"
      >
        <CustomFileupload
          handleViewPreview={handleViewPreview}
          setSelectedFile={setSelectedFile}
          fileSupported={fileSupported}
        />
      </CustomDialog>

      {/* Dialog for File Preview */}
      <CustomDialog
        open={dialogState.filePreview}
        onClose={handleClosePreview}
        title="File Preview"
      >
        {/* Preview for CSV */}
        {selectedFile?.type === 'text/csv' && (
            <Box sx={{paddingTop: 3}} >
            <table border={1} >
              <thead>
                <tr>
                  {fileData[0] && 
                    Object.keys(fileData[0]).map((header, index) => (
                      <th key={index}>{header}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {fileData.map((row: any, index: number) => (
                  <tr key={index}>
                    {Object.values(row).map((val, i) => (
                      <td key={i}>{String(val)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

          </Box>
          )}

        {/* Preview for PDF */}
        {selectedFile?.type === "application/pdf" && (
          <div>
            <embed
              src={filePreview || ""}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          </div>
        )}

        {/* Preview for Excel */}
        {selectedFile?.type === 'application/vnd.ms-excel' && (
            <div>
              <table border={1}>
                <thead>
                  <tr>
                    {fileData && fileData[0] &&
                      fileData[0].map((header: string, index: number) => (
                        <th key={index}>{header}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {fileData && fileData.slice(1).map((row: any[], rowIndex: number) => (
                    <tr key={rowIndex}>
                      {row.map((val, colIndex) => (
                        <td key={colIndex}>{String(val) || '\u00A0'}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </CustomDialog>
    </>
  );
};

export default MassicChatView;
