import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ConfirmDialogOptions {
  title?: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

interface ConfirmDialogContextProps {
  showConfirmDialog: (options: ConfirmDialogOptions) => void;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextProps | undefined>(undefined);

export const useConfirmDialog = (): ConfirmDialogContextProps => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirmDialog must be used within ConfirmDialogProvider');
  }
  return context;
};

export const ConfirmDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogOptions, setDialogOptions] = useState<ConfirmDialogOptions | null>(null);

  const showConfirmDialog = (options: ConfirmDialogOptions) => {
    setDialogOptions(options);
  };

  const handleClose = () => {
    setDialogOptions(null);
    dialogOptions?.onCancel?.();
  };

  const handleConfirm = () => {
    setDialogOptions(null);
    dialogOptions?.onConfirm();
  };

  return (
    <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
      {children}
      <Dialog open={!!dialogOptions} onClose={handleClose}>
        <DialogTitle>{dialogOptions?.title || 'Confirm Action'}</DialogTitle>
        <DialogContent>
          <Typography>{dialogOptions?.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
};
