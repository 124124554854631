import './AddCampaign.scss';
import React, {
  useState,
} from "react";

import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import SMSPreviewDrawer from "./SMSPreviewDrawer";
import { useNavigate } from 'react-router-dom';

type TransformedObject = {
    Name: string;
    Link: string;
};
  
const SMS_EmailCampaign = () => {
   
    const navigate = useNavigate(); 
  
    const [campaignData, setcampaignData] = useState<TransformedObject>({
        Name: "",
        Link: ""
    });
  
    const handleBack = async () => {
        navigate(-1);
    };


    const [Draweropen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
      setDrawerOpen(newOpen);
    };

    return (
        <Box className="Add-Campaign-Container">
        <ArrowBackIcon sx={{marginBottom: '12px', color: 'rgba(0, 0, 0, 0.56)', cursor: 'pointer' }} onClick={() => handleBack()} />
        {/* Business Info Section */}
        <Box className="Campaign-info">
          <Typography variant="h5" sx={{marginTop: 3}}>New SMS/Email Campaign</Typography>
          <Typography variant="body1"  sx={{marginTop: 3, marginBottom: 2}}>Campaign Info</Typography>
            <Box sx={{display: 'flex', gap: 2, width: {xs: '100%', lg: '768px'}, flexWrap: {xs: 'wrap', lg: 'nowrap'} }} >
              
              <TextField
                label="Campaign Name"
                variant="filled"
                fullWidth
                value={campaignData?.Name}
                onChange={(e) => setcampaignData({ ...campaignData, Name: e.target.value })}
              />

              <FormControl variant="filled" sx={{minWidth: {xs: "100%",lg: '376px'}}} >
                <InputLabel id="CampaignType">Campaign Type</InputLabel>

                <Select
                  labelId="CampaignType"
                  id="CampaignType"
                  value={campaignData?.Link}
                  onChange={(e) => setcampaignData({ ...campaignData, Link: e.target.value })}
                  label="Campaign Type"
                >
                  <MenuItem value="sms/email">SMS + Email(default)</MenuItem>
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                </Select>
              </FormControl>
            </Box>
          <Typography variant="body1"  sx={{marginTop: 4}}>Campaign Setup</Typography>
          <Typography variant="body2" sx={{color: "rgba(0, 0, 0, 0.6)"}}>Participant will move through the campaign only until review request is complete</Typography>
          <Box sx={{marginTop: 2}}>
            { [...Array(2)].map((_, index) => (
              <Card sx={{padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                <Stack direction="row" spacing={2} sx={{alignItems: 'center',  color: "#0F4343", cursor: 'pointer' }}>
                  <Checkbox/>
                  <Box>
                    <Typography variant="subtitle1">SMS {index+1}</Typography>
                    <Typography variant="body2"><b>1</b> days(s) later</Typography>
                  </Box>
                </Stack>
                <Box>
                  <Stack direction="row" spacing={2} sx={{alignItems: 'center',  color: "#0F4343", cursor: 'pointer' }}>
                  <Button variant="text" color="primary" onClick={toggleDrawer(true)}>Preview</Button>
                  <EditIcon sx={{width: '24px', height: '24px' }} onClick={()=>navigate("/business/edit-sms-screen")} /> 
                  </Stack>
                </Box>
              </Card>
              
            ))}

            { [...Array(2)].map((_, index) => (
              <Card sx={{padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3}}>
                <Stack direction="row" spacing={2} sx={{alignItems: 'center',  color: "#0F4343", cursor: 'pointer' }}>
                  <Checkbox/>
                  <Box>
                    <Typography variant="subtitle1">Email {index+1}</Typography>
                    <Typography variant="body2"><b>1</b> days(s) later</Typography>
                  </Box>
                </Stack>
                <Box>
                  <Stack direction="row" spacing={2} sx={{alignItems: 'center',  color: "#0F4343", cursor: 'pointer' }}>
                  <Button variant="text" color="primary" onClick={toggleDrawer(true)}>Preview</Button>
                  <EditIcon sx={{width: '24px', height: '24px' }} onClick={()=>navigate("/business/edit-email-screen")} /> 
                  </Stack>
                </Box>
              </Card>
              
            ))}
          </Box>
          <SMSPreviewDrawer open={Draweropen} toggleDrawer={toggleDrawer} />
        </Box>
      </Box>
    );
  };
  
  export default SMS_EmailCampaign;
  