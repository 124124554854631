// Navbar.tsx

import "./Navbar.scss";
import "firebase/compat/messaging";

import { Avatar, Badge, Dropdown, Layout } from "antd";
import {
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import {  useApi, IAPIConfig } from "../../custom_hooks/useApi";

import { logout } from "../../store/authSlice";
import { useDispatch } from "react-redux";

const UserNavbar = () => {
  const { Header } = Layout;
  const [open, setOpen] = useState(false);
  const { doApi } = useApi();
  const dispatch = useDispatch();

  const handleMenuClick = async (e: any) => {
    if (e.key === "logout") {
      const logoutConfig: IAPIConfig = {
        URL: "/logout",
        method: "POST",
      };
      try {
        const response = await doApi(logoutConfig, "seedmain");
        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(logout());
      }
    }
  };


  const profileMenu = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: "Profile",
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Logout",
    },
  ];



  return (
    <Header className="navbar" id="navbar">
      <div className="navbar-icons" id="navbar-icons">
        <SettingOutlined className="settings-icon" />
        <span>
          <Badge count={0} className="notification-badge">
            {" "}
            <BellOutlined className="icon" id="notification-icon" />
          </Badge>
        </span>
        <Dropdown
          menu={{ items: profileMenu, onClick: handleMenuClick }}
          trigger={["click"]}
          onOpenChange={(flag) => setOpen(flag)}
          open={open}
          className="profile-dropdown"
        >
          <Avatar icon={<UserOutlined />} className="profile-icon" />
        </Dropdown>
      </div>
    </Header>
  );
};

export default UserNavbar;
