import React from "react";

const Google: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M45.12 24.5C45.12 22.94 44.98 21.44 44.72 20H24V28.52H35.84C35.32 31.26 33.76 33.58 31.42 35.14V40.68H38.56C42.72 36.84 45.12 31.2 45.12 24.5Z"
      fill="#4285F4"
    />
    <path
      d="M24.0001 46C29.9401 46 34.9201 44.04 38.5601 40.68L31.4201 35.14C29.4601 36.46 26.9601 37.26 24.0001 37.26C18.2801 37.26 13.4201 33.4 11.6801 28.2H4.36011V33.88C7.98011 41.06 15.4001 46 24.0001 46Z"
      fill="#34A853"
    />
    <path
      d="M11.68 28.18C11.24 26.86 10.98 25.46 10.98 24C10.98 22.54 11.24 21.14 11.68 19.82V14.14H4.36C2.86 17.1 2 20.44 2 24C2 27.56 2.86 30.9 4.36 33.86L10.06 29.42L11.68 28.18Z"
      fill="#FBBC05"
    />
    <path
      d="M24.0001 10.76C27.2401 10.76 30.1201 11.88 32.4201 14.04L38.7201 7.74C34.9001 4.18 29.9401 2 24.0001 2C15.4001 2 7.98011 6.94 4.36011 14.14L11.6801 19.82C13.4201 14.62 18.2801 10.76 24.0001 10.76Z"
      fill="#EA4335"
    />
  </svg>
);
export default Google;
