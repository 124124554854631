/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import { PlusOutlined } from "@ant-design/icons";

// Define the interfaces
interface CurDataTypeUserDefaultChannelGroup {
  "New Users": string;
  "Engaged Sessions": string;
  "Engagement rate": string;
  "Engagement sessions per user": string;
  "Average engagement time": string;
  "Event count": string;
  "Key events": string;
}

interface DataItemTypeUserDefaultChannelGroup {
  "FirstUser DefaultChannelGroup": string;
  curData: CurDataTypeUserDefaultChannelGroup;
}

interface GA4DataDisplayUserDefaultChannelGroupProps {
    data: DataItemTypeUserDefaultChannelGroup[];
  }
  
  interface TableDataTypeUserDefaultChannelGroupProps {
    key: string;
    userDefaultChannelGroup: string;
    newUsers: string;
    engagedSessions: string;
    engagementRate: string;
    engagedSessionsPerUser: string;
    averageEngagementTime: string;
    eventCount: string;
    keyEvents: string;
  }

const GA4UserDefaultChannelGroup: React.FC<GA4DataDisplayUserDefaultChannelGroupProps> = ({data}) => {
    const [searchTextUserDefault, setSearchTextUserDefault] = useState("");
    const [filteredDataUserDefault, setFilteredDataUserDefault] = useState<TableDataTypeUserDefaultChannelGroupProps[]>([]);
    const [fakeFilteredDataUserDefault, setFakeFilteredDataUserDefault] = useState<TableDataTypeUserDefaultChannelGroupProps[]>([]);
    const [pageSize, setPageSize] = useState(10);
  
    useEffect(() => {
      const transformedData = data.map((item, index) => ({
        key: (index + 1).toString(),
        userDefaultChannelGroup: item["FirstUser DefaultChannelGroup"],
        newUsers: item.curData["New Users"],
        engagedSessions: item.curData["Engaged Sessions"],
        engagementRate: parseFloat(item.curData["Engagement rate"]).toFixed(2),
        engagedSessionsPerUser: parseFloat(item.curData["Engagement sessions per user"]).toFixed(2),
        averageEngagementTime: item.curData["Average engagement time"],
        eventCount: item.curData["Event count"],
        keyEvents: parseFloat(item.curData["Key events"]).toFixed(2),
      }));
      setFilteredDataUserDefault(transformedData);
      setFakeFilteredDataUserDefault(transformedData);
    }, [data]);
  
    const handleSearch = (value: string) => {
      setSearchTextUserDefault(value);
    };
  
    useEffect(() => {
      const filtered = fakeFilteredDataUserDefault.filter((item) =>
        Object.values(item).some((field) =>
          field.toString().toLowerCase().includes(searchTextUserDefault.toLowerCase())
        )
      );
      setFilteredDataUserDefault(filtered);
    }, [searchTextUserDefault])
  
    const calculateColumnTotal = (field: keyof TableDataTypeUserDefaultChannelGroupProps): number => {
      return filteredDataUserDefault.reduce(
        (sum, item) => sum + parseFloat(item[field] || "0"),
        0
      );
    };
  
    const handlePlusClick = () => {
      console.log("plus event called");
    };
  
    const columns: ColumnsType<TableDataTypeUserDefaultChannelGroupProps> = [
      {
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>First user default channel group</p>
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => handlePlusClick()}
            />
          </div>
        ),
        dataIndex: "userDefaultChannelGroup",
        key: "userDefaultChannelGroup",
        fixed: 'left',
      },
      {
        title: (
          <div>
            New users <br /> <br /> {calculateColumnTotal("newUsers")} <br />
            <span style={{ color: "#888" }}>100% of total</span>
          </div>
        ),
        dataIndex: "newUsers",
        key: "newUsers",
        sorter: (a: any, b: any)  => a.newUsers - b.newUsers,
      },
      {
        title: (
          <div>
            Engaged sessions <br /> <br /> {calculateColumnTotal("engagedSessions")} <br />
            <span style={{ color: "#888" }}>100% of total</span>
          </div>
        ),
        dataIndex: "engagedSessions",
        key: "engagedSessions",
        sorter: (a: any, b: any)  => a.engagedSessions - b.engagedSessions,
      },
      {
        title: (
          <div>
            Engagement rate <br /> <br /> {`${(calculateColumnTotal("engagementRate") / data.length).toFixed(2)}%`} <br />
            <span style={{ color: "#888" }}> Avg 0%</span>
          </div>
        ),
        dataIndex: "engagementRate",
        key: "engagementRate",
        render: (value)=> `${value}%`,
        sorter: (a: any, b: any)  => a.engagementRate - b.engagementRate,
      },
      {
        title: (
          <div>
            Engaged sessions per user <br /> <br />{" "}
            {(calculateColumnTotal("engagedSessionsPerUser") / data.length).toFixed(2)} <br />
            <span style={{ color: "#888" }}> Avg 0%</span>
          </div>
        ),
        dataIndex: "engagedSessionsPerUser",
        key: "engagedSessionsPerUser",
        sorter: (a: any, b: any)  => a.engagedSessionsPerUser - b.engagedSessionsPerUser,
      },
      {
        title: (
          <div>
            Average engagement time <br /> <br />{" "}
            {`${Math.round(calculateColumnTotal("averageEngagementTime")/ data.length)}s`} <br />
            <span style={{ color: "#888" }}>Avg 0%</span>
          </div>
        ),
        dataIndex: "averageEngagementTime",
        key: "averageEngagementTime",
        render: (value) => `${value}s`,
        sorter: (a: any, b: any)  => a.averageEngagementTime - b.averageEngagementTime,
      },
      {
        title: (
          <div>
            Event count <br /> <br />{" "}
            {calculateColumnTotal("eventCount")} <br />
            <span style={{ color: "#888" }}>100% of total</span>
          </div>
        ),
        dataIndex: "eventCount",
        key: "eventCount",
        sorter: (a: any, b: any)  => a.eventCount - b.eventCount,
      },
      {
        title: (
          <div>
            Key events <br /> <br /> {calculateColumnTotal("keyEvents")} <br />
            <span style={{ color: "#888" }}>100% of total</span>
          </div>
        ),
        dataIndex: "keyEvents",
        key: "keyEvents",
        sorter: (a: any, b: any)  => a.keyEvents - b.keyEvents,
      },
    ];
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Input.Search
            placeholder="Search..."
            style={{ width: 300 }}
            value={searchTextUserDefault}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              value={pageSize}
              onChange={(value) => setPageSize(value)}
              style={{ width: 120, marginRight: 10 }}
            >
              <Select.Option value={10}>10 rows/page</Select.Option>
              <Select.Option value={25}>25 rows/page</Select.Option>
              <Select.Option value={50}>50 rows/page</Select.Option>
              <Select.Option value={100}>100 rows/page</Select.Option>
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={filteredDataUserDefault}
          pagination={{ pageSize, showSizeChanger: false }}
          sticky
          scroll={{ x: 1200 }}
        />
      </div>
    );
}

export default GA4UserDefaultChannelGroup