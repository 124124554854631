import "./CustomAreaCharts.scss";

import { Box, Card } from "@mui/material";
import { useEffect, useState } from "react";

import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import Label from "../../Label";

interface TrendDetail {
  Total: string;
  Diff: string;
  Trend: string;
}

interface TrendData {
  [key: string]: TrendDetail;
}

interface CustomAreaChartProps {
  key?: any;
  loading?: boolean;
  chartType?: "area" | "line" | "bar";
  colors?: { [key: string]: string }[]; // Changed to array of objects
  categories: string[];
  seriesData: { name: string; data: number[] }[];
  yAxisLabels?: [];
  title?: string;
  tooltipFormatter?: (value: any) => string;
  overViews: TrendData;
}

const CustomAreaChartComponent: React.FC<CustomAreaChartProps> = ({
  loading = false,
  chartType = "area",
  colors = [
    { CLK: "#007bff" },
    { IMP: "#6f42c1" },
    { CTR: "#00897B" },
    { POS: "#fd7e14" },
  ],
  categories = [],
  seriesData = [],
  overViews,
}) => {
  const formatYAxisValue = (value: number) => {
    if (value >= 1000000) {
      // Format in millions
      return `${(value / 1000000).toFixed(1)}M`; // Round to one decimal place
    } else if (value >= 1000) {
      // Format in thousands
      return `${(value / 1000).toFixed(1)}k`; // Round to one decimal place
    }
    return value.toString(); // For values less than 1000, return as is
  };

  const [visible, setVisible] = useState(Array(seriesData.length).fill(true));
  const [filteredSeries, setFilteredSeries] = useState<any>(seriesData);

  // Define the series names to include in yAxisConfig
  const includedSeries = [
    "CLK",
    "IMP",
    "CTR",
    "POS",
    "Interactions",
    "Sessions",
    "Conversions",
  ];

  // Filter filteredSeries to include only objects where the name is in includedSeries
  const yAxisConfig = filteredSeries
    .filter((series: { name: string; data: number[] }) =>
      includedSeries.includes(series.name)
    )
    .map((series: any, index: number) => ({
      min: 0,
      opposite: index % 2 !== 0,
      labels: {
        show: !["CTR", "POS"].includes(series.name),
        formatter: formatYAxisValue,
        style: { colors: "#8e8da4" },
      },
    }));

  // Map colors to match the index in filteredSeries
  const mappedColors = filteredSeries.map(
    (series: { name: string; data: number[] }, index: number) => {
      const colorObject = colors.find(
        (colorObj) => colorObj[series.name.toLowerCase()]
      );
      return colorObject ? Object.values(colorObject)[0] : "#ccc";
    }
  );

  const options: ApexOptions = {
    chart: {
      id: "custom-area-chart",
      type: chartType,
      height: 350,
      toolbar: { show: false },
      animations: { enabled: false },
    },
    colors: mappedColors,
    dataLabels: { enabled: false },
    stroke: { curve: "straight", width: 2 },
    xaxis: {
      type: "datetime",
      categories,
      labels: { format: "MMM dd", style: { colors: "#8e8da4" } },
    },
    yaxis: yAxisConfig,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: any, { seriesIndex, dataPointIndex, w }: any) => {
          const seriesName = w.config.series[seriesIndex].name;
          if (seriesName.toUpperCase() === "CTR") {
            return `${value.toFixed(2)}`;
          } else if (seriesName.toUpperCase() === "CONVERSIONS") {
            return `${value.toFixed(1)}`;
          } else {
            return formatYAxisValue(value);
          }
        },
      },
    },
    grid: {
      borderColor: "#e1e2e3",
      padding: { left: 10, right: 10 },
    },
    legend: { show: false },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light", 
        type: "vertical", 
        shadeIntensity: 0.5, 
        gradientToColors: mappedColors, 
        inverseColors: false, 
        opacityFrom: 1, 
        opacityTo: 0.1, 
        stops: [0, 50, 100], 
      },
    },
  };

  useEffect(() => {
    const updatedSeries = seriesData.filter((_, i) => visible[i]);
    setFilteredSeries(updatedSeries);
  }, [visible, seriesData]);

  const toggleSeries = (index: number) => {
    setVisible((prevVisible) => {
      const updatedVisible = prevVisible.map((isVisible, i) =>
        i === index ? !isVisible : isVisible
      );

      // Ensure that at least one series is always visible
      const visibleCount = updatedVisible.filter(
        (isVisible) => isVisible
      ).length;

      if (visibleCount === 0) {
        // If no series is selected, keep the clicked series selected
        updatedVisible[index] = true;
      }

      return updatedVisible;
    });
  };

  return (
    <Card
      sx={{
        padding: { xs: "10px 8px 3px 8px", sm: "10px 20px 3px 14px" },
        marginBottom: 1,
      }}
    >
      <div className="custom-legend">
        {Object.entries(overViews).map(([key, detail], index) => {
          const colorObject = colors.find(
            (colorObj) => colorObj[key.toLowerCase()]
          );
          const legendColor = colorObject
            ? Object.values(colorObject)[0]
            : "#ccc";
          return (
            <Box className="legend-item" key={key}>
              <label
                style={{
                  color: legendColor,
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <input
                  type="checkbox"
                  checked={visible[index]}
                  onChange={() => toggleSeries(index)}
                  style={{ color: legendColor }}
                />
                <span className="legend-label">
                  {key === "Clk"
                    ? "CLK"
                    : key === "Imp"
                    ? "IMP"
                    : key === "Ctr"
                    ? "CTR"
                    : key === "Pos"
                    ? "POS"
                    : key === "totalinteractions"
                    ? "Total Interactions"
                    : key}
                </span>
              </label>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <span className="legend-value">{detail?.Total}</span>
                <Label
                  increase={detail.Trend === "up"}
                  label={detail.Diff}
                  color={detail.Trend === "up" ? "success" : "error"}
                  size="12px"
                  iconsize="12px"
                />
              </Box>
            </Box>
          );
        })}
      </div>
      {filteredSeries.length > 0 && (
        <Chart
          options={options}
          series={filteredSeries}
          type={chartType}
          height={350}
          width="100%"
        />
      )}
    </Card>
  );
};

export default CustomAreaChartComponent;
