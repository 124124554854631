import "./CreateBusinessProfile.scss";

import * as Yup from "yup";

import { Button, Input } from "antd";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Formik,
  Form as FormikForm,
} from "formik";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import  {useApi, IAPIConfig } from "../../../custom_hooks/useApi";
import { useDispatch, useSelector } from "react-redux";

import CustomToast from "../../../utils/cutomToast/CustomToast";
import { companyProfile } from "../../../store/companyProfileSlice";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  description: Yup.string().required("Company Description is required"),
  website: Yup.string().url("Invalid URL").required("Website is required"),
  productsServices: Yup.array()
    .of(Yup.string().required("Product is required").nullable())
    .min(1, "At least one product is required"),
  locationType: Yup.string().required("Company Location is required"),
  locations: Yup.array()
    .of(Yup.string().required("Location is required").nullable())
    .min(1, "At least one location is required"),
  customerPersonas: Yup.array()
    .of(
      Yup.object().shape({
        personName: Yup.string().required("Persona Name is required"),
        personDescription: Yup.string().required(
          "Persona Description is required"
        ),
      })
    )
    .min(1, "At least one customer person is required"),
  sellingPoints: Yup.array()
    .of(Yup.string().required("Unique Selling Points are required").nullable())
    .min(1, "At least one unique selling points is required"),
  businessObjective: Yup.string().required("Business Objective is required"),
  competitors: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        website: Yup.string()
          .url("Invalid URL")
          .required("Competitor Website is required")
      }),
    )
    .min(1, "At least one competitor website URL is required"),
});

interface CreateFormPageProps {
  update: boolean;
  editable: boolean
}

type TransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  uniqueId: string;
};

const ZCreateBusinessProfile: React.FC<CreateFormPageProps> = ({ update = false, editable = false }) => {
  const [formType, setFormType] = useState("general");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { formData } = useSelector((state: any) => state.companyProfileDetails);
  const [selectedProfile, setSelectedProfile] = useState<TransformedObject>(formData);
  const { doApi } = useApi();
  const { sendPushNotifications } = useSendPushNotifications();

  const handleFormTypeChange = (type: string) => {
    setFormType(type);
  };

  useEffect(() =>{
     setSelectedProfile(formData);
  }, [formData]);


  const handleSave = (values:TransformedObject) => {
    try {
      setLoading(true);
      console.log(JSON.stringify(values));
      dispatch(companyProfile(values));
      CustomToast("Your changes have been saved.", "success");
    } catch (error) {
      CustomToast("There was an error while saving.", "error");
    } finally {
      setLoading(false);
    }
  };

  const AddingForm = (arrayHelpers: any, index: any, values: any) => (
    <div style={{ display: "flex", columnGap: "1rem", marginTop: "0.50rem" }}>
      <Button
        onClick={() => arrayHelpers.remove(index)}
        disabled={values.length === 1}
        className="remove-product-button"
      >
        <MinusCircleOutlined />
      </Button>
      <Button
        type="primary"
        onClick={() => arrayHelpers.push("")}
        className="add-product-button"
      >
        <PlusCircleOutlined />
      </Button>
    </div>
  );

 const handleConfirm = async (values:TransformedObject, resetForm: any) => {
  try {
    let companyProfileConfig: IAPIConfig;

    if (!update) {
      companyProfileConfig = {
        URL: "/Profile/CreateBusinessProfile",
        method: "POST",
        payLoad: values,
      };
    } else {
      companyProfileConfig = {
        URL: "/Profile/UpdateBusinessProfile",
        method: "POST",
        payLoad: values,
      };
    }
    const res: any = await doApi(companyProfileConfig, "seedcore");
    if (res.status === 200) {
      sendPushNotifications({
         title: values.name,
         body: `Business Profile ${update ? "updated" : "created"} successfully`
      });
    } else {
      CustomToast(`Business Profile not ${update ? "updated" : "created"} successfully`, "error");
    }
  } catch (error: any) {
    CustomToast(error.message || "An error occurred", "error");
  } finally {
    resetForm();
    dispatch(companyProfile({}));
  }
};


  return (
    <div className="form-container">
      { !update && !editable && <div className="title" id="form-title">
        Please create the company profile here!
      </div>
     }
      <div className="form-toggle" id="form-toggle">
        <button
          type="button"
          id="general-button"
          className={
            formType === "general" ? "link-button active" : "link-button"
          }
          onClick={() => handleFormTypeChange("general")}
        >
          General
        </button>
        /
        <button
          type="button"
          id="strategic-button"
          className={
            formType === "strategic" ? "link-button active" : "link-button"
          }
          onClick={() => handleFormTypeChange("strategic")}
        >
          Strategic
        </button>
      </div>
      <Formik
       initialValues={{
          uniqueId: selectedProfile.uniqueId || "",
          name: selectedProfile.name || "",
          description: selectedProfile.description || "",
          website: selectedProfile.website || "",
          productsServices: Array.isArray(selectedProfile.productsServices)
            ? selectedProfile.productsServices
            : [""],
          locationType: selectedProfile.locationType || "",
          locations: Array.isArray(selectedProfile.locations)
            ? selectedProfile.locations
            : [""],
          customerPersonas: Array.isArray(selectedProfile.customerPersonas)
            ? selectedProfile.customerPersonas.map((item: any) => ({
                personName: item.personName || "",
                personDescription: item.personDescription || "",
              }))
            : [
                {
                  personName: "",
                  personDescription: "",
                },
              ],
          sellingPoints: Array.isArray(selectedProfile.sellingPoints)
            ? selectedProfile.sellingPoints
            : [""],
          businessObjective: selectedProfile.businessObjective || "",
          competitors: Array.isArray(selectedProfile.competitors)
            ? selectedProfile.competitors.map((item: any) => ({
                name: item.name || "",
                website: item.website || "",
              }))
            : [
                {
                  name: "",
                  website: "",
                },
              ],
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleConfirm(values, resetForm);
          setSubmitting(false);    
        }}
      >
        {({ handleSubmit, isSubmitting, values, isValid}) => (
          <FormikForm
            className="form-content"
            id="form-content"
            onSubmit={handleSubmit}
          >
            {formType === "general" && (
              <>
                <div className="form-item">
                  <label htmlFor="name">Company Name</label>
                  <Field
                    id="name"
                    name="name"
                    as={Input}
                    placeholder="Enter company name"
                    disabled = {editable}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="description">Company Description</label>
                  <Field
                    id="description"
                    name="description"
                    as={Input.TextArea}
                    placeholder="Enter company description"
                    disabled = {editable}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="website">Website</label>
                  <Field
                    id="website"
                    name="website"
                    as={Input}
                    placeholder="Enter website"
                    disabled = {editable}
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-item">
                  <label>Products & Services</label>
                  <FieldArray
                    name="productsServices"
                    render={(arrayHelpers) => (
                      <div>
                        {values.productsServices.map(
                          (product: any, index: any) => (
                            <div
                              key={index}
                              className="form-item"
                              style={{ marginLeft: "0.80rem" }}
                            >
                              <Field
                                id={`productsServices.${index}`}
                                name={`productsServices.${index}`}
                                as={Input}
                                placeholder="Enter product and services"
                                disabled = {editable}
                              />
                              <ErrorMessage
                                name={`productsServices.${index}`}
                                component="div"
                                className="error-message"
                              />
                              {AddingForm(
                                arrayHelpers,
                                index,
                                values.productsServices
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="locationType">Company Location</label>
                  <Field
                    id="locationType"
                    name="locationType"
                    as="select"
                    placeholder="Select company location"
                    disabled = {editable}
                  >
                    <option value="">--Select Location--</option>
                    <option value="Local">Local</option>
                    <option value="Regional">Regional</option>
                    <option value="National">National</option>
                    <option value="Global">Global</option>
                  </Field>
                  <ErrorMessage
                    name="locationType"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-item">
                  <label>Locations</label>
                  <FieldArray
                    name="locations"
                    render={(arrayHelpers) => (
                      <div>
                        {values.locations.map((location: any, index: any) => (
                          <div
                            key={index}
                            className="form-item"
                            style={{ marginLeft: "0.80rem" }}
                          >
                            <Field
                              id={`locations.${index}`}
                              name={`locations.${index}`}
                              as={Input}
                              disabled={editable}
                              placeholder="Enter location"
                            />
                            <ErrorMessage
                              name={`locations.${index}`}
                              component="div"
                              className="error-message"
                            />
                            {AddingForm(arrayHelpers, index, values.locations)}
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
              </>
            )}
            {formType === "strategic" && (
              <>
                <div className="form-item">
                  <label>Customer Persona</label>
                  <FieldArray
                    name="customerPersonas"
                    render={(arrayHelpers) => (
                      <div>
                        {values.customerPersonas.map(
                          (customer: any, index: any) => (
                            <div
                              key={index}
                              className="form-item"
                              style={{ marginLeft: "0.80rem" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "0.60rem",
                                }}
                              >
                                <Field
                                  id={`customerPersonas.${index}.personName`}
                                  name={`customerPersonas.${index}.personName`}
                                  type="text"
                                  placeholder="Enter persona name"
                                  as={Input}
                                  disabled = {editable}
                                />
                                <ErrorMessage
                                  name={`customerPersonas.${index}.personName`}
                                  component="div"
                                  className="error-message"
                                />
                                <Field
                                  id={`customerPersonas.${index}.personDescription`}
                                  name={`customerPersonas.${index}.personDescription`}
                                  type="text"
                                  placeholder="Enter persona description"
                                  as={Input}
                                  disabled = {editable}
                                />
                                <ErrorMessage
                                  name={`customerPersonas.${index}.personDescription`}
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              {AddingForm(
                                arrayHelpers,
                                index,
                                values.customerPersonas
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="form-item">
                  <label>Unique Selling Point (s)</label>
                  <FieldArray
                    name="sellingPoints"
                    render={(arrayHelpers) => (
                      <div>
                        {values.sellingPoints.map((us: any, index: any) => (
                          <div
                            key={index}
                            className="form-item"
                            style={{ marginLeft: "0.80rem" }}
                          >
                            <Field
                              id={`sellingPoints.${index}`}
                              name={`sellingPoints.${index}`}
                              as={Input.TextArea}
                              placeholder="Enter unique selling point"
                              disabled = {editable}
                            />
                            <ErrorMessage
                              name={`sellingPoints.${index}`}
                              component="div"
                              className="error-message"
                            />
                            {AddingForm(
                              arrayHelpers,
                              index,
                              values.sellingPoints
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="businessObjective">Business Objective</label>
                  <Field
                    id="businessObjective"
                    name="businessObjective"
                    as={Input.TextArea}
                    placeholder="Enter business objective"
                    disabled = {editable}
                  />
                  <ErrorMessage
                    name="businessObjective"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-item">
                  <label>Competitor Website (s)</label>
                  <FieldArray
                    name="competitors"
                    render={(arrayHelpers) => (
                      <div>
                        {values.competitors.map((us: any, index: any) => (
                          <div
                            key={index}
                            className="form-item"
                            style={{ marginLeft: "0.80rem" }}
                          >
                            <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "0.60rem",
                                }}
                              >
                            <Field
                              id={`competitors.${index}.name`}
                              name={`competitors.${index}.name`}
                              type="text"
                              placeholder="Enter name"
                              as={Input}
                              disabled = {editable}
                            />
                            <ErrorMessage
                              name={`competitors.${index}.name`}
                              component="div"
                              className="error-message"
                            />
                            <Field
                              id={`competitors.${index}.website`}
                              name={`competitors.${index}.website`}
                              type="text"
                              placeholder="Enter website"
                              as={Input}
                              disabled = {editable}
                            />
                            <ErrorMessage
                              name={`competitors.${index}.website`}
                              component="div"
                              className="error-message"
                            />
                            </div>
                            {AddingForm(
                              arrayHelpers,
                              index,
                              values.competitors
                            )}
                            
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
              </>
            )}
           {!editable && 
             <div className="button-group">
             {!update && <Button
                type="primary"
                onClick={() => handleSave(values)}
                disabled={isSubmitting}
                loading={loading}
                className="save-button"
              >
                Save
              </Button>} 
              {<Button
                type="primary"
                htmlType="submit"
                disabled={!isValid || isSubmitting}
                loading={loading}
                className="confirm-button"
              >
               {!update ? 'Confirm' : 'Update'} 
              </Button>}
            </div>}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default ZCreateBusinessProfile;
