import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const formData = {};
  return { formData};
};

const userProfileSlice = createSlice({
  name: "userProfileDetails",
  initialState: getInitialState(),
  reducers: {
    userProfile: (state, action) => {
      state.formData = action.payload;
    }
  }
});

export const { userProfile } = userProfileSlice.actions;
export const userProfileReducer = userProfileSlice.reducer;
