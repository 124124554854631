import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import CustomToast from "../cutomToast/CustomToast";
import { Modal } from "antd";
import { logout } from "../../store/authSlice";
import { urlConstants } from "../../components/constants/globalConstants";
import { useDispatch } from "react-redux";

interface GlobalContextProps {
  isOnline: boolean;
  lastActivity: number;
  resetSessionTimeout: () => void;
  excludeFromTimeout: (componentName: string) => void;
  includeInTimeout: (componentName: string) => void;
}

const GlobalContext = createContext<GlobalContextProps>({
  isOnline: true,
  lastActivity: Date.now(),
  resetSessionTimeout: () => {},
  excludeFromTimeout: () => {},
  includeInTimeout: () => {},
});

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [excludedComponents, setExcludedComponents] = useState<string[]>([]);
  const dispatch = useDispatch();

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      CustomToast("Network connected!", "networkConnected");
    } else {
      CustomToast("Network disconnected!", "networkDisconnected");
    }
  };

  const resetSessionTimeout = useCallback(() => {
    setLastActivity(Date.now());
  }, []);

  const excludeFromTimeout = (componentName: string) => {
    setExcludedComponents((prev) => [...prev, componentName]);
  };

  const includeInTimeout = (componentName: string) => {
    setExcludedComponents((prev) =>
      prev.filter((name) => name !== componentName)
    );
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
      const sessionTimeout = setInterval(() => {
        if (Date.now() - lastActivity > urlConstants.SessionTimeout) {
          if (excludedComponents.length === 0 ) {
            Modal.warning({
              title: "Session Timeout",
              content: "Your session has timed out due to inactivity.",
              onOk() {
                dispatch(logout());
              },
            });
          }
        }
      }, urlConstants.SessionTimeout);

      return () => {
        clearInterval(sessionTimeout);
      };
  }, [lastActivity, excludedComponents, dispatch]);

  useEffect(() => {
    const handleUserActivity = () => {
      resetSessionTimeout();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [resetSessionTimeout]);

  return (
    <GlobalContext.Provider
      value={{
        isOnline,
        lastActivity,
        resetSessionTimeout,
        excludeFromTimeout,
        includeInTimeout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
