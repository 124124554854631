/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import { PlusOutlined } from "@ant-design/icons";

// Define the interfaces
interface CurDataType {
  Sessions: string;
  Users: string;
  "New users": string;
  "Average engagement time per session": string;
  "Key events": string;
  "Total revenue": string;
  "Session key event rate": string;
}

interface DataItemType {
  "Landing page": string;
  curData: CurDataType;
}

interface GA4DataDisplayProps {
  data: DataItemType[];
}

interface TableDataType {
  key: string;
  landingPage: string;
  sessions: string;
  users: string;
  newUsers: string;
  avgEngagementTime: string;
  keyEvents: string;
  totalRevenue: string;
  sessionKeyEventRate: string;
}

const GA4DataDisplay: React.FC<GA4DataDisplayProps> = ({ data }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<TableDataType[]>([]);
  const [fakeFilteredData, setFakeFilteredData] = useState<TableDataType[]>([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const transformedData = data.map((item, index) => ({
      key: (index + 1).toString(),
      landingPage: item["Landing page"],
      sessions: item.curData.Sessions,
      users: item.curData.Users,
      newUsers: item.curData["New users"],
      avgEngagementTime: item.curData["Average engagement time per session"],
      keyEvents: item.curData["Key events"],
      totalRevenue: item.curData["Total revenue"],
      sessionKeyEventRate: item.curData["Session key event rate"],
    }));
    setFilteredData(transformedData);
    setFakeFilteredData(transformedData);
  }, [data]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    const filtered = fakeFilteredData.filter((item) =>
      Object.values(item).some((field) =>
        field.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchText]);

  const calculateColumnTotal = (field: keyof TableDataType): number => {
    return filteredData.reduce(
      (sum, item) => sum + parseFloat(item[field] || "0"),
      0
    );
  };

  const handlePlusClick = () => {
    console.log("plus event called");
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Landing page</p>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handlePlusClick()}
          />
        </div>
      ),
      dataIndex: "landingPage",
      key: "landingPage",
      fixed: "left",
    },
    {
      title: (
        <div>
          Sessions <br /> <br /> {calculateColumnTotal("sessions")} <br />
          <span style={{ color: "#888" }}>100% of total</span>
        </div>
      ),
      dataIndex: "sessions",
      key: "sessions",
      sorter: (a: any, b: any) => a.sessions - b.sessions,
    },
    {
      title: (
        <div>
          Users <br /> <br /> {calculateColumnTotal("users")} <br />
          <span style={{ color: "#888" }}>100% of total</span>
        </div>
      ),
      dataIndex: "users",
      key: "users",
      sorter: (a: any, b: any) => a.users - b.users,
    },
    {
      title: (
        <div>
          New users <br /> <br /> {calculateColumnTotal("newUsers")} <br />
          <span style={{ color: "#888" }}>100% of total</span>
        </div>
      ),
      dataIndex: "newUsers",
      key: "newUsers",
      sorter: (a: any, b: any) => a.newUsers - b.newUsers,
    },
    {
      title: (
        <div>
          Average engagement time per session <br /> <br />{" "}
          {`${Math.round(
            calculateColumnTotal("avgEngagementTime") / data.length
          )}s`}{" "}
          <br />
          <span style={{ color: "#888" }}>Avg 0%</span>
        </div>
      ),
      dataIndex: "avgEngagementTime",
      key: "avgEngagementTime",
      render: (value) => `${value}s`,
      sorter: (a: any, b: any) => a.avgEngagementTime - b.avgEngagementTime,
    },
    {
      title: (
        <div>
          Key events <br /> <br /> {calculateColumnTotal("keyEvents")} <br />
          <span style={{ color: "#888" }}>100% of total</span>
        </div>
      ),
      dataIndex: "keyEvents",
      key: "keyEvents",
      sorter: (a: any, b: any) => a.keyEvents - b.keyEvents,
    },
    {
      title: (
        <div>
          Total revenue <br /> <br />{" "}
          {`$${calculateColumnTotal("totalRevenue")}`} <br />
          <span style={{ color: "#888" }}>100% of total</span>
        </div>
      ),
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      render: (value) => `$${value}`,
      sorter: (a: any, b: any) => a.totalRevenue - b.totalRevenue,
    },
    {
      title: (
        <div>
          Session key event rate <br /> <br />{" "}
          {`${Math.round(calculateColumnTotal("sessionKeyEventRate") / data.length)}%`}{" "}
          <br />
          <span style={{ color: "#888" }}>Avg 0%</span>
        </div>
      ),
      dataIndex: "sessionKeyEventRate",
      key: "sessionKeyEventRate",
      render: (value: any) => `${value}%`,
      sorter: (a: any, b: any) => a.sessionKeyEventRate - b.sessionKeyEventRate,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Input.Search
          placeholder="Search..."
          style={{ width: 300 }}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            value={pageSize}
            onChange={(value) => setPageSize(value)}
            style={{ width: 120, marginRight: 10 }}
          >
            <Select.Option value={10}>10 rows/page</Select.Option>
            <Select.Option value={25}>25 rows/page</Select.Option>
            <Select.Option value={50}>50 rows/page</Select.Option>
            <Select.Option value={100}>100 rows/page</Select.Option>
          </Select>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize, showSizeChanger: false }}
        sticky
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default GA4DataDisplay;
