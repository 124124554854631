import  {useApi, IAPIConfig } from "./useApi";

interface PushNotification {
  title: string;
  body: string;
}

const useSendPushNotifications = () => {
  const { doApi } = useApi();

  const sendPushNotifications = async (notification: PushNotification) => {
    if (!notification.title || !notification.body) return;

    const input = {
      title: notification.title,
      body: notification.body,
    };

    try {
      const sendNotificationConfig: IAPIConfig = {
        URL: `/send-notifications`,
        method: "POST",
        payLoad: input,
      };

      const res: any = await doApi(sendNotificationConfig, "seedmain");

      if (res.data.result) {
        console.log(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return { sendPushNotifications };
};

export default useSendPushNotifications;
