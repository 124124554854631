import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profiles: [],
  loading: false,
  error: null,
  expandedBusiness: null,
  profileDataByUniqueID: {}
};

const businessSlice = createSlice({
  name: "businessProfiles",
  initialState,
  reducers: {
    fetchBusinessProfilesSuccess(state, action) {
      state.profiles = JSON.parse(action.payload.data);
      state.loading = false;
    },
    resetBusinessProfiles(state) {
      state.profiles = []; 
      state.loading = false;
  },
    fetchBusinessProfilesFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    setExpandedBusiness(state, action) {
      state.expandedBusiness = action.payload; // Set expandedBusiness
    },
    fetchBusinessProfilesByUniqueIdSuccess(state, action) {
      state.profileDataByUniqueID = action.payload;
      state.loading = false;
    },
    deleteProductService(state, action) {
      const index = action.payload; // Get index from payload
      state.profileDataByUniqueID.ProductsServices.splice(index, 1); // Remove the product from ProductsServices
    },
    setProfileData(state, action) {
      state.profileDataByUniqueID = action.payload; 
    },
  },
});


export const { fetchBusinessProfilesSuccess, resetBusinessProfiles, fetchBusinessProfilesFailure, setExpandedBusiness, fetchBusinessProfilesByUniqueIdSuccess,deleteProductService, setProfileData  } = businessSlice.actions;
export const businessProfile =  businessSlice.reducer;
