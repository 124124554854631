import "./PaymentModalPopUp.scss";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import  {useApi, IAPIConfig } from "../../../custom_hooks/useApi";

import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import { useSelector } from "react-redux";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";

interface PaymentMethodModalProps {
  userSettingsId: number;
  open: boolean;
  onClose: () => void;
  isEditMode: boolean;
  paymentData?: {
    id: number;
    cardNumber: string;
    expiryDate: string;
    cvv: string;
    primary: boolean;
    last4: string;
    cardHolder: string;
  };
}

const PaymentModalPopUp: React.FC<PaymentMethodModalProps> = ({
  userSettingsId,
  open,
  onClose,
  isEditMode,
  paymentData,
}) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state: any) => state.auth);
  const { doApi } = useApi();
  const { sendPushNotifications } = useSendPushNotifications();

  React.useEffect(() => {
    if (isEditMode && paymentData) {
      setCardNumber(paymentData.cardNumber);
      setExpiryDate(paymentData.expiryDate);
      setCvv(paymentData.cvv);
      setIsPrimary(paymentData.primary);
    }
  }, [open, isEditMode, paymentData]);

  const handleSave = async () => {
    if(!cardNumber){
      CustomToast("Please enter card number.", "error");
      return;
    }
    if(!cvv){
      CustomToast("Please enter card cvv.", "error");
      return;
    }
    if(!expiryDate){
      CustomToast("Please enter card expiry date.", "error");
      return;
    }
    let paymentPayload = {};
    if (isEditMode) {
      paymentPayload = {
        Id: userSettingsId,
        type: "EDIT",
        paymentData: {
          id: paymentData?.id,
          cvv: cvv,
          last4: cardNumber.slice(-4),
          primary: isPrimary,
          cardHolder: paymentData?.cardHolder,
          cardNumber: cardNumber,
          expiryDate: expiryDate,
        },
      };
    } else {
      paymentPayload = {
        Id: userSettingsId,
        type: "ADD",
        paymentData: {
          cvv: cvv,
          last4: cardNumber.slice(-4),
          primary: isPrimary,
          cardHolder: userDetails.userName,
          cardNumber: cardNumber,
          expiryDate: expiryDate,
        },
      };
    }
    try {
      setLoading(true);
      const plansAndPaymentMethodsConfig: IAPIConfig = {
        URL: "/add-edit-remove-payment-method",
        method: "POST",
        payLoad: paymentPayload,
      };
      const res: any = await doApi(plansAndPaymentMethodsConfig, "seedmain");
      if (res.data.success === true) {
        sendPushNotifications({
          title: `${userDetails.userName}(**** ****${cardNumber.slice(-4)})`,
          body: res.data.message,
        });
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
    }
    onClose();
  };

  const handleCardNumberChange = (e: any) => {
    let value = e.target.value.replace(/\D/g, "");
    value = value.replace(/(\d{4})(?=\d)/g, "$1 "); 
    setCardNumber(value);
  };

  // Ensure CVV is limited to 3 or 4 digits
  const handleCvvChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 4) {
      setCvv(value);
    }
  };


  const handleExpiryDateChange = (e: any) => {
    let value = e.target.value.replace(/\D/g, ""); 
    if (value.length <= 4) {
      if (value.length > 2) {
        value = value.slice(0, 2) + "/" + value.slice(2);
      }
      setExpiryDate(value);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="modal-wrapper"
      aria-labelledby="payment-method-modal"
      aria-describedby="payment-method-modal-description"
    >
      <Box className="payment-modal">
        <Box className="payment-modal-header">
          <Typography id="payment-method-modal" variant="h6" component="h2">
            {isEditMode ? "Edit Payment Method" : "Add Payment Method"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="payment-modal-body"
        >
          {/* Card Number Field */}
          <TextField
            label="Card Number"
            variant="filled"
            size="medium"
            value={cardNumber}
            onChange={handleCardNumberChange}
            fullWidth
            inputProps={{ maxLength: 19 }} 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreditCardIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box className="payment-modal-body-card-details">
            {/* Expiry Date Field */}
            <TextField
              label="Expiration Date (MM/YY)"
              variant="filled"
              size="medium"
              value={expiryDate}
              onChange={handleExpiryDateChange}
              fullWidth
              inputProps={{ maxLength: 5 }}
            />

            {/* CVV Field */}
            <TextField
              label="Security Code (CVV)"
              variant="filled"
              size="medium"
              value={cvv}
              onChange={handleCvvChange}
              fullWidth
              inputProps={{ maxLength: 4 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CreditScoreIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <FormControlLabel
            className="payment-modal-body-checkbox"
            control={
              <Checkbox
                checked={isPrimary}
                onChange={(e) => setIsPrimary(e.target.checked)}
              />
            }
            label="Add as Primary Payment Method"
          />

          <Box className="payment-modal-body-controls">
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSave}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : isEditMode ? (
                "Save Changes"
              ) : (
                "Add Payment Method"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentModalPopUp;
