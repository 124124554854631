import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const storedData = sessionStorage.getItem("userEntityType");
  const userEntityType = storedData !== "undefined" ? JSON.parse(storedData) : null;
  return { userEntityType };
};

const userEntitySlice = createSlice({
  name: "userEntityDetails",
  initialState: getInitialState(),
  reducers: {
    userEntityTypeAction: (state, action) => {
      state.userEntityType = action.payload.userEntityType;
      console.log(JSON.stringify(action.payload.userEntityType))
      sessionStorage.setItem("userEntityType", JSON.stringify(action.payload.userEntityType));
    }
  }
});

export const { userEntityTypeAction } = userEntitySlice.actions;
export const userEntityTypeReducer = userEntitySlice.reducer;
