/* eslint-disable react-hooks/exhaustive-deps */

import "./KeywordClustering.scss";

import * as React from "react";

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Paper from "@mui/material/Paper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { SelectChangeEvent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import Papa from "papaparse";

interface ReadMoreProps {
  content: string | undefined;
  maxLength?: number;
}

// func for readmore

export const ReadMore: React.FC<ReadMoreProps> = ({
  content,
  maxLength = 100,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content) return <Typography>N/A</Typography>;

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  const displayContent = isExpanded ? content : content.slice(0, maxLength);

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: 700, display: "inline" }}>
        {displayContent}
      </Typography>
      {content.length > maxLength && (
        <Button
          onClick={toggleExpansion}
          // variant="outlined"

          sx={{
            fontSize: 20,
            // fontSize: "7.95rem",
            // padding: 0,
            // minWidth: "auto",
            // background: "#686D76",
          }}
        >
          {isExpanded ? "Read Less ..." : "Read More ..."}
        </Button>
      )}
    </>
  );
};

export default function KeywordClustering() {
  interface StateOption {
    state_code: string;
    state_name: string;
  }
  interface jobRowData {
    jobId: number;
    fileName: string;
    location: string;
    country: string;
    algorithm: string;
    urlOverlap: number;
    status: string;
    downloadLink: string;
  }

  const [loading, setLoading] = React.useState(false);
  const [loadingStates, setLoadingStates] = React.useState(false);
  console.log(loadingStates);

  const [pageSerp, setPageSerp] = React.useState(0);
  const [rowsPerPageSerp, setRowsPerPageSerp] = React.useState(10);

  console.log("rowserppageserp", rowsPerPageSerp);

  const [countryCityVal, setCountryCityVal] = useState({
    country: "",
    state: "",
  });
  console.log("countrycityval", countryCityVal);
  const [fullLocationValueForApi, setFullLocationValueForApi] = useState<string>("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState<StateOption[]>([]);

  const [filteredStates, setFilteredStates] = useState<StateOption[]>([]); // Type the array as StateOption[]
  const [searchText, setSearchText] = useState<string>(""); // Type searchText as a string
  const [loadedCountForState, setLoadedCountForState] = useState<number>(10); // Initial count for loaded items
  const scrollPositionRef = React.useRef<number>(0); // Type scrollPositionRef as a number
  const listboxRef = React.useRef<HTMLUListElement | null>(null); // Type listboxRef to a possible HTMLDivElement or null

  // ? keyword clustering

  const [file, setFile] = useState<File | null>(null);
  console.log("file", file);
  const [keywords, setKeywords] = useState<string[]>([]);
  console.log("keywords", keywords);

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const [algoAndUrlOverlap, setAlgoAndUrlOverlap] = useState({
    algo: "",
    overlap: "",
  });

  const [jobs, setJobs] = useState<jobRowData[]>([]);

  console.log("algoAndUrlOverlap", algoAndUrlOverlap);

  const [stateName, setStateName] = useState<string>("");
  console.log("stateName", stateName);

  const [calculatedValue, setCalculatedValue] = useState<number>();

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      Papa.parse(uploadedFile, {
        header: true, // Assuming the CSV has a header
        complete: (results) => {
          const data = results.data as { keyword: string }[];

          // Filter and get the keywords
          const keywords = data.map((row) => row.keyword).filter(Boolean);
          const keywordsLength = keywords.length;
          console.log("keywordsLength", keywordsLength);

          // Multiply by 0.0006
          const calculatedValue = parseFloat(
            (keywordsLength * 0.0006).toFixed(3)
          );

          // Save the result to a state
          setCalculatedValue(calculatedValue);
          setLoading(false);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          setLoading(false);
        },
      });
    }
  };

  // Handle file removal
  const handleRemoveFile = () => {
    setFile(null);
    setKeywords([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input's value
    }
  };

  // ? keyword clustering

  React.useEffect(() => {
    const filtered = searchText
      ? states.filter((state) =>
          state.state_name.toLowerCase().includes(searchText.toLowerCase())
        )
      : states;

    setFilteredStates(filtered.slice(0, loadedCountForState));
  }, [loadedCountForState, searchText, states]);

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listboxNode = listboxRef.current;
    if (listboxNode) {
      const isAtBottom =
        listboxNode.scrollHeight - listboxNode.scrollTop ===
        listboxNode.clientHeight;

      if (isAtBottom && loadedCountForState < states.length && !searchText) {
        scrollPositionRef.current = listboxNode.scrollTop;

        setLoadedCountForState((prevCount) =>
          Math.min(prevCount + 10, states.length)
        );
      }
    }
  };

  React.useEffect(() => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [filteredStates]);

  console.log("countries", countries);
  console.log("countryCityVal", countryCityVal);

  // State to store selected URLs

  const [SERPErrors, setSERPErrors] = useState({
    algo: false,
    overlap: false,
    country: false,
    state: false,
    limit: false,
  });

  console.log("SERPErrors", SERPErrors);

  const [errorResText, setErrorResText] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);

  const SERPApiUrl = "https://utilities.seedinternaldev.xyz/api/run-job";

  const GetJobApiUrl = "https://utilities.seedinternaldev.xyz/api/jobs";

  const locationGetUrl =
    "https://utilities.seedinternaldev.xyz/api/iso-details";
  const stateGetUrl = `https://utilities.seedinternaldev.xyz/api/states/${countryCityVal?.country}`;

  React.useEffect(() => {
    axios.get(locationGetUrl).then((res) => {
      // console.log(res?.data?.slice(0, 10), "locRes");

      setCountries(res?.data);

      // setStates(res?.data);
    });
  }, []);
  console.log("states", states);

  React.useEffect(() => {
    if (countryCityVal?.country) {
      setLoadingStates(true);
      axios
        .get(stateGetUrl)
        .then((res) => {
          setStates(res?.data);
          setLoadingStates(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingStates(false);
        });
    }
  }, [countryCityVal?.country]);

  // const navigate = useNavigate();

  const fetchJobData = () => {
    // setLoading(true);
    axios
      .get(GetJobApiUrl)
      .then((res) => {
        setJobs(res?.data);
        console.log("jobs", res?.data);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // if (error?.response?.data?.error === "Unexpected status code") {
          //   setErrorResText(
          //     "An unexpected error occured. Please try again later"
          //   );
          // }
          // else {
          setErrorResText(err?.response?.data?.message);
          // }

          setErrorShow(true);
          setTimeout(() => {
            setErrorShow(false);
            setErrorResText(""); // Clear the error message
          }, 3000);
        }
      });
    // .finally(() => {
    //   console.log("finally");
    //   setLoading(false);
    // });
  };

  React.useEffect(() => {
    fetchJobData();
    const intervalId = setInterval(fetchJobData, 30000); // 30,000 ms = 30 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleSearchBtnSERP = () => {
    // const isKeywordValEmpty = !keywordVal?.trim();
    // const isLimitValEmpty = !limit?.trim();

    // const isScrapTypeEmpty = !scrapType?.trim();
    const isCountryEmpty = !countryCityVal?.country?.trim();
    const isStateEmpty = !countryCityVal?.state?.trim();
    const isAlgoEmpty = !algoAndUrlOverlap?.algo?.trim();
    const isUrlOverlapEmpty = !algoAndUrlOverlap?.overlap?.trim();
    const isOverlapLessthan3 =
      parseInt(algoAndUrlOverlap?.overlap) < 3 ? true : false;

    // Check if either fieldVal or scrapType is empty
    if (
      // isScrapTypeEmpty ||
      // isKeywordValEmpty ||
      // isLimitValEmpty ||
      isCountryEmpty ||
      isStateEmpty ||
      isAlgoEmpty ||
      isUrlOverlapEmpty ||
      isOverlapLessthan3
    ) {
      // setScrapTypeError(isScrapTypeEmpty);
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        // keyword: isKeywordValEmpty,
        // limit: isLimitValEmpty,
        country: isCountryEmpty,
        state: isStateEmpty,
        algo: isAlgoEmpty,
        overlap: isUrlOverlapEmpty || isOverlapLessthan3,
      }));
      return; // Exit if validation fails
    }

    setSERPErrors((prevErrors) => ({
      ...prevErrors,
      keyword: false,
      limit: false,
      country: false,
      state: false,
      algo: false,
      overlap: false,
    }));
    // setScrapTypeError(false);
    // setKeywordTableShow(true);
    const objForApi = {
      // language_code: "en",
      // keywords: keywords,
      location: fullLocationValueForApi,
      location_code: parseInt(countryCityVal?.state), // countryCityVal?.state,
      country: stateName,
      algorithm: algoAndUrlOverlap?.algo,
      urlOverlap: parseInt(algoAndUrlOverlap?.overlap),
      // limit: parseInt(limit),
      // keyword: keywordVal,
    };
    const formData = new FormData();
    formData.append("file", file as Blob); // Append the file (non-null check ensures it's valid)
    formData.append("settings", JSON.stringify(objForApi)); // Append settings

    setLoading(true);

    // if (keywordVal && limit && scrapType && scrapType === "SERP scraper") {
    axios
      .post(SERPApiUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // setSERPScrappedData(response?.data?.combinedResults);

        console.log(response, "SERPRES");
        if (response?.data?.job) {
          fetchJobData();
        }
        // setSuccessResText(response?.data?.message);
        // }

        // setSuccessAlertShow(true);
        // setTimeout(() => {
        //   setSuccessAlertShow(false);
        //   setSuccessResText(""); // Clear the error message
        // }, 3000);
      })
      .catch((error) => {
        console.log(error, "errorkeyserp");
        if (error?.response?.data?.message) {
          // if (error?.response?.data?.error === "Unexpected status code") {
          //   setErrorResText(
          //     "An unexpected error occured. Please try again later"
          //   );
          // }
          // else {
          setErrorResText(error?.response?.data?.message);
          // }

          setErrorShow(true);
          setTimeout(() => {
            setErrorShow(false);
            setErrorResText(""); // Clear the error message
          }, 3000);
        }
      })
      .finally(() => {
        console.log("finally");
        setLoading(false);
      });
    // }
  };

  // SERP Handlechange

  // const handleCheckboxChange = (event, rowUrl) => {
  //   if (event.target.checked) {
  //     // Add URL to the selected array if checked
  //     setSelectedUrls((prevSelected) => [...prevSelected, rowUrl]);
  //   } else {
  //     // Remove URL from the selected array if unchecked
  //     setSelectedUrls((prevSelected) =>
  //       prevSelected.filter((url) => url !== rowUrl)
  //     );
  //   }
  // };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setAlgoAndUrlOverlap((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setSERPErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field being updated
    }));
  };

  const handleOverlapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = parseInt(value);
    // setAlgoAndUrlOverlap((prevValue) => ({
    //   ...prevValue,
    //   [name]: value,
    // }));
    // setSERPErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: false, // Clear the error for the field being updated
    // }));

    if (name === "overlap" && numericValue < 3) {
      setAlgoAndUrlOverlap((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
      // Set error if value is less than 3
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      // Clear error if valid
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
      setAlgoAndUrlOverlap((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }
  };

  console.log(countryCityVal, "countryCityVal");

  const handleCountryCityChange = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    name: string,
    stateAndCountry: string
  ) => {
    if (reason === "clear" || value) {
      setSERPErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error for the field being updated
      }));
    }

    setCountryCityVal((prevValue) => ({
      ...prevValue,
      [name]: value || "",
    }));

    // setStateName(stateName);
    console.log(stateAndCountry, "stateNameselect");
    setFullLocationValueForApi(stateAndCountry);

    const countryName = stateAndCountry.split(",").pop()?.trim(); // Get the last part and trim spaces

    console.log(countryName, "countryName");

    // Update state with the extracted country name
    if (countryName) {
      setStateName(countryName); // Assuming you have a state for country
    }
  };

  // sitemap scraper

  // serp scraper
  const handleChangePageSerp = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSerp(newPage);
  };

  console.log(pageSerp, "pageSerp");

  const handleChangeRowsPerPageSerp = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSerp(parseInt(event.target.value));
    setPageSerp(0);
  };

  const JobsColumns = [
    { id: "1", label: "Job ID" },
    { id: "2", label: "File Name" },

    { id: "3", label: "Country" },

    { id: "4", label: "Location" },

    { id: "5", label: "Algorithm" },
    { id: "6", label: "URL Overlap" },
    { id: "6", label: "Status" },
    { id: "6", label: "Actions" },
  ];

  const handleDownload = async (fileName: string, jobId: number) => {
    try {
      const response = await axios.get(
        `https://utilities.seedinternaldev.xyz/api/download/${fileName}`,
        {
          responseType: "blob", // Ensures the response is a file blob
        }
      );

      // Create a link to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);

      // Add the jobId to the downloadedJobs state
    } catch (error: any) {
      console.log(error, "errorkeyserp");
      if (error?.response?.status === 404) {
        setErrorResText("File already downloaded"); // Set the error message
        // }

        setInfoShow(true);
        setTimeout(() => {
          setInfoShow(false);
          setErrorResText(""); // Clear the error message
        }, 3000);
      }
    }
  };

  return (
    <div
      className="webscrap-container"
      style={
        {
          // minWidth:'400px',
        }
      }
    >
      <Grid
        container
        spacing={2}
        padding={3}
        mt={-2}
        pb={3}
        display={"flex"}
        direction={"column"}
        // flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        sx={{
          backgroundColor: "#fff",
          // border: "1px solid #000",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          // background: "rgba(255, 255, 255, 0.1)", // Light translucent background
          // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)", // Shadow effect
          backdropFilter: "blur(4px)", // Blurring background
          WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
          borderRadius: "10px", // Rounded corners
          // border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
        }}
      >
        <Typography variant="h3" fontFamily={"serif"} color="black">
          Keyword Clustering
        </Typography>
        {/* keyword clustering  */}

        {!file && (
          <Box
            sx={{ mt: 3 }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"center"}
            mt={2}
          >
            <Typography variant="subtitle1" fontFamily={"serif"} color="black">
              Upload CSV file contains keywords :
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadFileIcon />}
              disabled={loading}
              sx={{ mr: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Upload CSV"
              )}
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileUpload}
                ref={fileInputRef}
              />
            </Button>
          </Box>
        )}

        {file && (
          <Grid
            container
            spacing={2}
            mt={1}
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  color: "#000",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily={"serif"}
                  color="black"
                  style={{ paddingRight: "10px" }}
                >
                  Uploaded File :
                </Typography>
                {file?.name}
                {file?.size && (
                  <span style={{ paddingLeft: "10px", color: "#001F3F" }}>
                    {""}
                    {/* ({getFileSizeInMB(file?.size)} MB) */}({file?.size}{" "}
                    bytes)
                  </span>
                )}
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={handleRemoveFile}
                  disabled={loading}
                >
                  <DeleteIcon />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        )}
        {file && (
          <Grid
            container
            spacing={2}
            mt={2}
            // ml={20}
            display={"flex"}
            // justifyContent={"center"}
          >
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={!!SERPErrors?.country}
                sx={{
                  width: "100%",
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                <Autocomplete
                  fullWidth
                  id="country-autocomplete"
                  options={countries} // Limited options initially
                  getOptionLabel={(option) => option} // Assuming `countries` is an array of strings
                  onInputChange={(event, newInputValue) => {
                    // Reset loaded count if needed when input changes
                  }}
                  onChange={(event, value, reason) =>
                    handleCountryCityChange(event, value, reason, "country", "")
                  }
                  // PaperComponent={CustomPaper} // Use Custom Paper for glossy background
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Code"
                      fullWidth
                      value={countryCityVal?.country || ""}
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        "& .MuiInputBase-input": {
                          color: "black", // Text color inside input
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "black",
                          },
                          "&:hover fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: SERPErrors?.country ? "red" : "black",
                          },
                          // backgroundColor: "rgba(255, 255, 255, 0.1)", // Glossy input background
                          // backdropFilter: "blur(10px)", // Blurred effect for glossy look
                        },
                        "& .MuiInputLabel-root": {
                          color: "black", // Label color
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "black", // Label color when focused
                        },
                      }}
                    />
                  )}
                  sx={{
                    pointerEvents: loading ? "none" : "auto",

                    cursor: loading ? "not-allowed" : "pointer",
                    "& .MuiSvgIcon-root": {
                      color: "black", // Dropdown arrow color
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "black",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "black",
                    },
                    "& .MuiAutocomplete-listbox": {
                      backgroundColor: "black", // Set background color of the options
                      color: "white", // Optional: Set text color for better readability
                    },
                  }}
                />
                {SERPErrors?.country && (
                  <FormHelperText sx={{ color: "red" }}>
                    Country Code is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={!!SERPErrors?.state}
                sx={{
                  textAlign: "center",
                  width: "100%",
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                <Autocomplete
                  fullWidth
                  id="loc-autocomplete"
                  options={filteredStates} // Limited options initially or by search
                  getOptionLabel={(option) => option?.state_name}
                  onInputChange={(event, newInputValue) => {
                    setSearchText(newInputValue);
                    setLoadedCountForState(10); // Reset loaded count on new search
                  }}
                  onChange={(event, value, reason) =>
                    handleCountryCityChange(
                      event,
                      value ? value.state_code : "",
                      reason,
                      "state",
                      value ? value.state_name : ""
                    )
                  }
                  // PaperComponent={CustomPaper} // Use Custom Paper for glossy background

                  // ? new onscroll
                  ListboxProps={{
                    ref: listboxRef, // Attach ref to Listbox
                    onScroll: handleScroll,
                  }}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      label="Location"
                      fullWidth
                      value={countryCityVal?.state || ""}
                      sx={{
                        cursor: loading ? "not-allowed" : "pointer",
                        textAlign: "center",

                        color: "black",
                        "& .MuiInputBase-input": { color: "black" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "black",
                          },
                          "&:hover fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: SERPErrors?.state ? "red" : "black",
                          },
                        },
                        "& .MuiInputLabel-root": { color: "black" },
                        "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                      }}
                    />
                  )}
                  sx={{
                    pointerEvents: loading ? "none" : "auto",
                    "& .MuiSvgIcon-root": { color: "black" },
                    "& .MuiAutocomplete-popupIndicator": { color: "black" },
                    "& .MuiAutocomplete-clearIndicator": { color: "black" },
                  }}
                />
                {SERPErrors?.state && (
                  <FormHelperText sx={{ color: "red" }}>
                    Location is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={SERPErrors?.algo}
                sx={{ cursor: loading ? "not-allowed" : "pointer" }}
              >
                <InputLabel
                  sx={{
                    color: "black", // Label color when not focused
                    "&.Mui-focused": {
                      color: "black", // Label color when focused
                    },
                  }}
                >
                  Algorithm
                </InputLabel>
                <Select
                  value={algoAndUrlOverlap?.algo}
                  label="Algorithm"
                  name="algo" // disabled={loading}
                  onChange={handleSelectChange}
                  sx={{
                    textAlign: "left",
                    pointerEvents: loading ? "none" : "auto",

                    color: "black", // Text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black", // Border color when not focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors.algo ? "red" : "black", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: SERPErrors.algo ? "red" : "black", // Border color when focused
                    },
                    "& .MuiSvgIcon-root": {
                      color: "black", // Dropdown arrow color
                    },
                  }}
                >
                  <MenuItem value={"Agglomerative"}>Agglomerative</MenuItem>
                  <MenuItem value={"Centroid"}>Centroid</MenuItem>
                </Select>
                {SERPErrors.algo && (
                  <FormHelperText sx={{ color: "red" }}>
                    Algorithm is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label={"Url Overlap"}
                variant="outlined"
                onChange={handleOverlapChange}
                value={algoAndUrlOverlap?.overlap}
                name="overlap"
                fullWidth
                error={SERPErrors.overlap} // Display error state
                helperText={
                  SERPErrors.overlap && parseInt(algoAndUrlOverlap?.overlap) < 3
                    ? "Value must be at least 3"
                    : SERPErrors.overlap
                    ? "Url Overlap is required"
                    : ""
                } // Show helper text if error exists
                sx={{
                  pointerEvents: loading ? "none" : "auto",
                  cursor: loading ? "not-allowed" : "pointer",
                  input: { color: "black" }, // Text color inside TextField
                  "& label": {
                    color: "black", // Label color when not focused
                  },
                  "& label.Mui-focused": {
                    color: "black", // Label color when focused
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Border color when not focused
                    },
                    "&:hover fieldset": {
                      borderColor: SERPErrors.overlap ? "red" : "black", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: SERPErrors.overlap ? "red" : "black", // Border color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5.2}
              display={"flex"}
              justifyContent={"center"}
            >
              {calculatedValue && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      // textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      color: "#000",
                    }}
                  >
                    Cost (Approximate) :
                    <Typography variant="h6">${calculatedValue}</Typography>
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"flex-start"}
              // ml={13}
              mt={1}
              // gap={25}
            >
              <button
                className="webscraping-button"
                onClick={handleSearchBtnSERP}
                disabled={loading}
                style={{
                  display: "flex",

                  //   justifyContent: "center",
                  alignItems: "center",
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  //   gap={1}
                >
                  {/* <SearchIcon sx={{ fontSize: 30 }} /> */}

                  {loading ? (
                    // <CircularProgress size={24} sx={{ color: "white" }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <span style={{ marginLeft: "10px" }}>
                        {/* Please wait... {countdown} seconds remaining */}
                        Please wait ...
                      </span>
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    </div>
                  ) : (
                    <>
                      Run Job
                      <PlayArrowIcon />
                    </>
                  )}
                </Typography>
              </button>
            </Grid>
          </Grid>
        )}

        {/* keyword clustering  */}
        <Paper
          sx={{
            // display: "flex",
            // justifyContent: "center",
            color: "#000",
            // width: "300px",

            mt: 5,
            // ml: -2,
            background: "#fff", // Light translucent background
            // backdropFilter: "blur(4px)", // Blurring background
            // WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
          }}

          // sx={{
          //   color: "white",
          //   width: "100%",
          //   overflow: "hidden",
          //   mt: 3,
          //   background: "rgba(255, 255, 255, 0.1)", // Light translucent background
          //   backdropFilter: "blur(4px)", // Blurring background
          //   WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
          // }}
        >
          {infoShow && (
            <>
              <Paper
                sx={{
                  padding: 1,
                  overflow: "hidden",
                  // mt: 4,
                  mb: 1,
                  // background: "rgba(255, 255, 255, 0.1)",
                  background: "#E5F6FD",
                  backdropFilter: "blur(4px)", // Blurring background
                  WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
                  border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#56869B",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <ErrorOutlineIcon sx={{ fontSize: "30px" }} /> {errorResText}
                </Typography>
              </Paper>
            </>
          )}

          <TableContainer
            sx={{
              color: "#000",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              maxHeight: "400px", // Limit height to allow vertical scrolling
              // maxWidth: "800px",
              overflowY: "auto", // Enable vertical scroll within the table
              overflowX: "auto", // Enable horizontal scroll on smaller screens
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ color: "#000" }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#686D76",
                    color: "#fff",
                  }}
                >
                  {JobsColumns?.map((column) => (
                    <TableCell
                      sx={{
                        backgroundColor: "#686D76",
                        color: "#fff",
                        textAlign: "center",
                        // borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
                        borderRight: "1px solid #fff",
                      }}
                      // key={column.id}
                    >
                      <Typography variant="h6"> {column?.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  ?.slice(
                    pageSerp * rowsPerPageSerp,
                    pageSerp * rowsPerPageSerp + rowsPerPageSerp
                  )
                  ?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          {row?.jobId}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Typography>{row?.fileName}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Typography>{row?.country}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Typography>{row?.location}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Typography>{row?.algorithm}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Typography>{row?.urlOverlap}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // fontWeight: 800,
                            borderRight: "1px solid #000",
                          }}
                        >
                          {/* <Typography>{row?.status}</Typography> */}
                          <Chip
                            color={
                              row?.status === "pending"
                                ? "warning"
                                : row?.status === "in-progress"
                                ? "secondary"
                                : row?.status === "completed"
                                ? "success"
                                : "error"
                            }
                            sx={{ color: "#fff", fontSize: "14px" }}
                            label={
                              row?.status === "pending"
                                ? "Pending"
                                : row?.status === "in-progress"
                                ? "In-Progress"
                                : row?.status === "completed"
                                ? "Completed"
                                : "Error"
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            // borderRight: "1px solid #000",
                            // maxWidth: "900px", // Set a maximum width for the cell
                            overflow: "hidden",
                            // textOverflow: "ellipsis", // Ellipsis to indicate overflow
                            // wordBreak: "break-word", // Break long words onto the next line
                            // whiteSpace: "normal", // Allow wrapping onto the next line
                          }}
                        >
                          {/* <Link
                              href={row?.downloadLink}
                              underline="always"
                              component={"a"}
                              target="_blank"
                              download={row?.downloadLink}
                              sx={{
                                color: "#000",
                                display: "block", // Ensures link uses full available width
                                maxWidth: "100%", // Ensures it respects the TableCell's maxWidth
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word",
                              }}
                            >
                              {row?.downloadLink}
                            </Link> */}
                          {row?.status === "completed" ? (
                            <button
                              className="webscraping-button"
                              onClick={() =>
                                handleDownload(
                                  `cluster_results_${row?.jobId}.csv`,
                                  row?.jobId
                                )
                              }
                              style={{
                                fontSize: 14,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 1,
                                paddingBottom: 2,
                                width: "100%",
                              }}
                              // disabled={} // Disable if already downloaded
                            >
                              {/* {downloadedJobs?.includes(row?.jobId)
                                ? "Downloaded"
                                : "Download"} */}
                              Download
                            </button>
                          ) : (
                            <button
                              className="webscraping-button"
                              onClick={() => fetchJobData()}
                              style={{
                                fontSize: 14,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 1,
                                paddingBottom: 2,
                                width: "100%",
                              }}
                            >
                              Check Current Status
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={jobs?.length}
            rowsPerPage={rowsPerPageSerp}
            page={pageSerp}
            onPageChange={handleChangePageSerp}
            onRowsPerPageChange={handleChangeRowsPerPageSerp}
            sx={{
              color: "black",
              "& .MuiTablePagination-selectIcon": {
                color: "black", // Dropdown arrow color
              },
              "& .MuiTablePagination-actions": {
                "& button": {
                  color: "black", // Color for enabled forward/backward buttons
                },
                "& .Mui-disabled": {
                  color: "gray", // Gray color for disabled previous/next button
                },
              },
            }}
          />
        </Paper>

        {errorShow && (
          <>
            <Paper
              sx={{
                padding: 1,
                overflow: "hidden",
                mt: 2,
                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.1)",
                background: "rgba(245, 34, 45, 0.55)",
                backdropFilter: "blur(4px)", // Blurring background
                WebkitBackdropFilter: "blur(4px)", // Support for webkit browsers
                border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <ErrorOutlineIcon sx={{ fontSize: "30px" }} /> {errorResText}
              </Typography>
            </Paper>
          </>
        )}
      </Grid>
    </div>
  );
}
