import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  FormControl,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  useMediaQuery
} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./ReviewEmailEditScreen.scss";
import CustomDialog from "../../components/Dialog/CustomDialog";
import { useTheme } from '@mui/system';


const ReviewEmailEditScreen: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [subjectLine, setSubjectLine] = useState(
    "Hello from Hawaii Roasters - We'd like your review!"
  );
  const [content, setContent] = useState(
    "We'd like to hear about your experience with our acai bowl at Hawaii Roasters. Please take a moment to leave a review with the link below.\n\nThank you!"
  );
  const [buttonText, setButtonText] = useState("Leave a Review");
  const [reviewLinkOption, setReviewLinkOption] = useState<string>("cl");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [timeSlots, setTimeSlots] = useState([
    { id: Date.now(), start: "10:00", end: "12:00" }
  ]);

  const handleDayChange = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([
      ...timeSlots,
      { id: Date.now(), start: "10:00", end: "12:00" }
    ]);
  };

  const handleRemoveTimeSlot = (id: number) => {
    setTimeSlots(timeSlots.filter((slot) => slot.id !== id));
  };

  const handleTimeChange = (
    id: number,
    field: "start" | "end",
    value: string
  ) => {
    setTimeSlots(
      timeSlots.map((slot) =>
        slot.id === id ? { ...slot, [field]: value } : slot
      )
    );
  };

  //   const formattedCustomerName = customerName.split(',').join(', ');

  const [mailSendingOption, setMailSendingOption] = useState("anytime");

  const mailSendingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailSendingOption(event.target.value);
  };

  const isDisabled = mailSendingOption === "anytime";

  const [dialogState, setDialogState] = useState<{
    alertDialog: boolean;
  }>({
    alertDialog: false
  });

  // Function to toggle dialogs
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName]
    }));
  };

  return (
    <Box className="review-email-edit-screen-container">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Acai Bowl follow up
          </Typography>
          <Typography variant="h5">Email 1</Typography>
        </Box>
        <Box>
          <Button sx={{ marginRight: 2 }} variant="outlined">
            Exit
          </Button>
          <Button variant="contained">Save</Button>
        </Box>
      </Stack>
      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }}>
        Email Message
      </Typography>
      <Stack
        direction={{md: "column",lg:"row"}}
        spacing={8}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="column" rowGap={1}>
            <TextField
              label="Subject Line"
              variant="filled"
              fullWidth
              value={subjectLine}
              onChange={(e) => setSubjectLine(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{ width: "24px", height: "24px", marginBottom: -2 }}
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="Content"
              variant="filled"
              fullWidth
              multiline
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{ width: "24px", height: "24px", marginBottom: -8 }}
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="Button Text"
              variant="filled"
              fullWidth
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{ width: "24px", height: "24px", marginBottom: -2 }}
                    />
                  </InputAdornment>
                )
              }}
            />
            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography variant="subtitle1" id="reviewlink">
                Review Link
              </Typography>
              <Stack direction={{xs: "column", sm:"row"}} spacing={1} sx={{ marginTop: 1 }}>
                <FormControl variant="outlined" sx={{ minWidth: {xs: "100%", sm:"240px"} }}>
                  <Select
                    value={reviewLinkOption}
                    onChange={(e) => setReviewLinkOption(e.target.value)}
                    size="small"
                  >
                    <MenuItem value="cl">Custom Link</MenuItem>
                    <MenuItem value="bl">Google Business Link</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  defaultValue={"http://wwww.autogeneratedlink.com/"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyAllIcon color="primary" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                />
              </Stack>
            </Box>
          </Stack>

          <Box className="schedule-settings">
            <Typography variant="subtitle1" id="reviewlink">
              Send Mail
            </Typography>

            <RadioGroup value={mailSendingOption} onChange={mailSendingHandler}>
              <FormControlLabel
                value="anytime"
                control={<Radio />}
                label="Anytime"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Schedule"
              />
            </RadioGroup>

            <Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Emails will only go out on selected days and times below
                </Typography>
                <ToggleButtonGroup
                  value={selectedDays}
                  exclusive
                  aria-label="Platform"
                  sx={{
                    marginTop: 1,
                    height: "40px",
                    fontWeight: "bold",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    color: "red",
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                    justifyContent: isMobile ? 'space-evenly' : 'center', // More even spacing on mobile
                    gap: isMobile ? 1 : 0,
                    padding: '4px 0', 
                  }}
                  size="small"
                  disabled={isDisabled} // Disable the days selection when "Anytime" is selected
                >
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                    (day) => (
                      <ToggleButton
                        key={day}
                        value={day}
                        selected={selectedDays.includes(day)}
                        onClick={() => handleDayChange(day)}
                        sx={{
                          textTransform: "capitalize",
                          padding: "0 8px",
                          width: "57.14px",
                          color: "rgba(0, 0, 0, 0.87)",
                          border: "1px solid rgba(0, 0, 0, 0.23)"
                        }}
                      >
                        {day}
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
              </Box>
              <Box>
                {timeSlots.map((slot) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{xs: 1, sm: 2}}
                    key={slot.id}
                    sx={{ marginTop: { xs: 3, sm: 1 } }}
                  >
                    <TextField
                      type="time"
                      value={slot.start}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "start", e.target.value)
                      }
                      disabled={isDisabled} // Disable start time field when "Anytime" is selected
                    />
                    <Box>
                      <RemoveIcon
                        sx={{ width: "8px", color: "rgba(0, 0, 0, 0.87)" }}
                      />
                    </Box>
                    <TextField
                      type="time"
                      value={slot.end}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "end", e.target.value)
                      }
                      disabled={isDisabled} // Disable end time field when "Anytime" is selected
                    />
                    <IconButton disabled={isDisabled}>
                      <DeleteIcon
                        sx={{
                          width: "1.5rem",
                          height: "1.5rem",
                          cursor: "pointer",
                          color: isDisabled
                            ? "rgba(0, 0, 0, 0.6)"
                            : "rgba(15, 67, 67, 1)"
                        }}
                        onClick={() => handleRemoveTimeSlot(slot.id)}
                      />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddTimeSlot}
                  sx={{ marginTop: 1 }}
                  disabled={isDisabled} // Disable Add button when "Anytime" is selected
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          {/* Preview Area */}
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Preview
          </Typography>

          <Stack className="phone-frame">
            <Typography variant="subtitle1" className="phone-frame-title">
              {subjectLine}
            </Typography>
            <Box className="phone-frame-content">
              <Box>
                <Typography variant="h6" className="title">
                  Hawaii Roasters
                </Typography>
                <Typography variant="body2" className="greetings">
                  Hi {"Customer Name"}
                </Typography>
                <Typography variant="body1" className="content">
                  {content}
                  <br />
                </Typography>
                <Typography variant="body2" className="greetings">
                  Thank you!
                </Typography>
              </Box>
              <Box className="submit-btn">
                <Button variant="contained" color="primary">
                  {buttonText}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>

      {/* alert dialog */}
      <CustomDialog
        open={dialogState.alertDialog}
        onClose={() => toggleDialog("alertDialog")}
        title="Upload File"
      >
        <>
          <Button variant="outlined" color="error">
            Yes, exit without saving
          </Button>
          <Button variant="contained">Never mind</Button>
        </>
      </CustomDialog>
    </Box>
  );
};

export default ReviewEmailEditScreen;
