/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import "./BusinessReviews.scss";
import "./BusinessTable.scss";

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Rating,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";
import Loader from "../../utils/loader/Loader";
import LockIcon from "@mui/icons-material/Lock";
import MenuItem from "@mui/material/MenuItem";
import Papa from "papaparse";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import StarIcon from "@mui/icons-material/Star";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";

interface Review {
  Id: number;
  ReviewId: string;
  LocationId: string;
  ReviewerDisplayName: string;
  StarRating: "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE";
  Comment: string;
  CreateTime: string;
  UpdateTime: string;
  Name: string;
  ReviewReplyComment: string;
  ReviewReplyUpdateTime: string;
  SuggestedResponse?: string;
}

const BusinessReviews = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isMobile:", isMobile);

  const navigate = useNavigate();
  const { doApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const reviewApiControl = useRef(true);
  const suggestResponse = useRef(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const [location, setLocation] = useState<string>("");
  console.log("ProfileData", profileData);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      reviewApiControl.current = true;
      suggestResponse.current = true;
    }
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (reviewApiControl.current && location !== "") {
      fetchReviews(location);
      reviewApiControl.current = false;
    }
  }, [reviewApiControl.current, location]);

  const mapStarRating = (rating: Review["StarRating"]) => {
    switch (rating) {
      case "ONE":
        return 1;
      case "TWO":
        return 2;
      case "THREE":
        return 3;
      case "FOUR":
        return 4;
      case "FIVE":
        return 5;
      default:
        return 0;
    }
  };

  const convertKeysToLowerCase = (
    obj: Record<string, any>
  ): Record<string, any> => {
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      newObj[lowerCaseKey] = obj[key];
    });
    return newObj;
  };

  const handleSeeSuggestedResponse = async () => {
    setLoading(true);
    try {
      const responses = await Promise.all(
        reviews.map(async (review) => {
          const reviewPayload = convertKeysToLowerCase(review);
          const storeReviewsConfig: IAPIConfig = {
            URL: `/Review/GenerateAssistedReplies`,
            method: "POST",
            payLoad: reviewPayload,
          };
          try {
            const res: any = await doApi(storeReviewsConfig, "seedcore");
            if (res.status === 200 && res.data.err === undefined) {
              const data = JSON.parse(res.data.data);
              return {
                success: true,
                reviewId: review.ReviewId,
                suggestedResponse: data[0],
              };
            } else {
              return {
                success: false,
                error: "No Data Found.",
                reviewId: review.ReviewId,
              };
            }
          } catch (error: any) {
            return {
              success: false,
              error: error.message,
              reviewId: review.ReviewId,
            };
          }
        })
      );

      const successfulResponses = responses.filter(
        (response) => response.success
      );
      const failedResponses = responses.filter((response) => !response.success);

      setReviews((prevReviews) => {
        return prevReviews.map((review) => {
          const response = successfulResponses.find(
            (res) => res.reviewId === review.ReviewId
          );
          if (response) {
            return {
              ...review,
              SuggestedResponse: response.suggestedResponse,
            };
          }
          return review;
        });
      });

      if (successfulResponses.length) {
        console.log(
          "Successful Responses:",
          successfulResponses.map((response) => response.suggestedResponse)
        );
      }

      if (failedResponses.length) {
        console.error(
          "Failed Responses:",
          failedResponses.map((response) => response.error)
        );
        CustomToast(failedResponses[0].error, "error");
      } else {
        CustomToast("All reviews processed successfully.", "success");
      }
    } catch (error: any) {
      console.error(error);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = () => {
    const csvTemplate = [
      ["name", "email", "phone"], // Header row
      ["John Doe", "john@example.com", "1234567890"], // Example row
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvTemplate.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    saveAs(encodedUri, "template.csv");
  };

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        try {
          const businessId = profileData.UniqueId;
         debugger;
          // Add businessId to each object in the data
          const dataWithBusinessId: any = results.data.map((item: any) => ({
            ...item,
            businessId,
          }));
          const locationId =  profileData.Locations.filter( (val: any) => val.Name === location)[0].Id;
          const customersUploadConfig: IAPIConfig = {
            URL: `/Contacts/UploadContacts?userUniqueId=${profileData.UserUniqueId}&locationId=${locationId}&locationName=${location}`,
            method: "POST",
            payLoad: dataWithBusinessId,
          };
    
          const res: any = await doApi(customersUploadConfig, "seedcore");
    
          if (res.status === 200 && res.data.err === undefined) {
            CustomToast("CSV imported successfully!", "success");
          } else {
            CustomToast(res.data.message || "CSV import failed!", "error");
          }
        } catch (error) {
          CustomToast("Failed to upload CSV. Please try again.", "error");
        }
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        CustomToast("Failed to parse CSV. Please check the file.", "error");
      },
    });    
  };

  const fetchReviews = async (locationId: string) => {
    try {
      setLoading(true);
      const storeReviewsConfig: IAPIConfig = {
        URL: `/Review/FetchReviews?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924&nameFieldInAccount=accounts/114706490255130634915&nameFieldInLocation=${locationId}`,
        method: "GET",
      };
      const res: any = await doApi(storeReviewsConfig, "seedcore");
      if (res.status === 200 && res.data.err === undefined) {
        const result = JSON.parse(res.data.data);
        setReviews(result);
      } else {
        setReviews([]);
        CustomToast("No Data Found.", "error");
      }
    } catch (error: any) {
      console.error(error);
      setReviews([]);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSuggestedResponse = (reviewId: string, value: string) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.ReviewId === reviewId
          ? { ...review, SuggestedResponse: value }
          : review
      )
    );
  };

  const handleClearSuggestedResponse = (reviewId: string) => {
    handleUpdateSuggestedResponse(reviewId, "");
  };

  const handlePostReview = async (reviewId: string) => {
    const reviewToPost = reviews.find((review) => review.ReviewId === reviewId);
    if (reviewToPost) {
      const storeReviewsConfig: IAPIConfig = {
        URL: `/Review/PostReply?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924`,
        method: "PUT",
        payLoad: {
          reviewId: reviewToPost.ReviewId,
          reply: reviewToPost.SuggestedResponse,
        },
      };
      try {
        const res: any = await doApi(storeReviewsConfig, "seedcore");
        return {
          success: res.status === 200,
          reviewId: reviewToPost.ReviewId,
        };
      } catch (error: any) {
        return { success: false, reviewId: reviewToPost.ReviewId };
      }
    }
  };

  useEffect(() => {
    if (reviews.length > 0 && suggestResponse.current) {
      handleSeeSuggestedResponse();
      suggestResponse.current = false;
    }
  }, [reviews]);
  useEffect(() => {
    if (profileData.Locations && profileData.Locations.length > 0) {
      setLocation(profileData.Locations[0].Name);
    }
  }, [profileData]);

  const [selectedRadio, setSelectedRadio] = useState("all");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  useEffect(() => {
    (selectedRadio === "manual" || selectedRadio === "above4") &&
      setIsCheckboxChecked(false);
  }, [selectedRadio]);

  const handleRadioChange = (event: any) => {
    setSelectedRadio(event.target.value);
  };

  const handleCheckboxChange = (event: any) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const discountOptions = [
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
    { value: 30, label: "30%" },
  ];

  const tabledata = [
    {
      id: 1,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: false,
    },
    {
      id: 2,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: false,
    },
    {
      id: 3,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: true,
    },
    {
      id: 4,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: true,
    },
    {
      id: 5,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: true,
    },
    {
      id: 6,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: true,
    },
    {
      id: 7,
      name: "Jane Smith",
      phone: "123-456-7890",
      email: "jane@gmail.com",
      date_created: "2024. 04. 12  - 00:00 AM",
      status: true,
    },
  ];

  const campaignTabledata = [
    {
      id: 1,
      created: "09/20/2024",
      name: "Acai Bowl follow up",
      clicks: "11",
      status: "Active",
    },
    {
      id: 2,
      created: "09/20/2024",
      name: "Campaign name goes here",
      clicks: "5",
      status: "Active",
    },
    {
      id: 3,
      created: "09/20/2024",
      name: "Campaign name goes here",
      clicks: "14",
      status: "Active",
    },
    {
      id: 4,
      created: "09/20/2024",
      name: "Campaign name goes here",
      clicks: "13",
      status: "Active",
    },
    {
      id: 5,
      created: "09/20/2024",
      name: "Campaign name goes here",
      clicks: "15",
      status: "InActive",
    },
  ];

  const [campaignOption, setCampaignOption] = React.useState("s/e");

  const handleChange = (event: React.MouseEvent<HTMLElement>, opt: string) => {
    setCampaignOption(opt);
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleCampaignTableCheckboxChange = (index: number) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index) // Remove from selected rows
          : [...prevSelected, index] // Add to selected rows
    );
  };

  const newCampaignHandler = () => {
    if (campaignOption === "s/e") {
      navigate("/business/new-sms/email-campaign");
      setLoading(true);
    } else {
      navigate("/business/new-campaign");
    }
  };

  return (
    <Box className="reviews-container">
      {/* Profile Header */}

      <Typography variant="h4">Reviews</Typography>
      {/* Profile Tabs */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap",
          columnGap: "1.5rem",
          rowGap: "1.5rem",
          overflow: "hidden",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className="reviews-tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Reviews" className="tab" />
          <Tab label="Responder Settings" className="tab" />
          <Tab label="Customers" className="tab" />
          <Tab label="Campaigns" className="tab" />
        </Tabs>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              sx={{
                color: "#6e6b6b",
                height: "40px",
                outline: "none",
                background: "#fff",
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {profileData.Locations !== undefined &&
                profileData.Locations.map((loc: any, index: number) => (
                  <MenuItem key={loc.Name} value={loc.Name}>
                    {loc.DisplayName}- {index + 1}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Tab Content */}
      <Loader spinning={loading}>
        <Box className="review-tab-content">
          {selectedTab === 0 &&
            reviews.map((review: Review, index: number) => (
              <Box sx={{ marginTop: 3 }} key={index}>
                <Card sx={{ borderRadius: 1, padding: 2 }}>
                  <Typography variant="subtitle1">{review.Comment}</Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: "center", marginTop: 1 }}
                  >
                    <Avatar
                      sx={{ width: "24px", height: "24px" }}
                      alt={review.ReviewerDisplayName}
                      src="/static/images/avatar/3.jpg"
                    />
                    <Typography variant="subtitle2">
                      {mapStarRating(review.StarRating)}
                    </Typography>
                    <Rating
                      name={`star-rating-${index}`}
                      value={mapStarRating(review.StarRating)}
                      readOnly
                      precision={0.5}
                      icon={
                        <StarIcon
                          fontSize="inherit"
                          sx={{
                            color: "#093535",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      }
                      emptyIcon={
                        <StarIcon
                          fontSize="inherit"
                          sx={{ color: "#ccc", width: "16px", height: "16px" }}
                        />
                      }
                    />
                  </Stack>
                  <Typography
                    sx={{
                      marginTop: 1,
                      color: "rgba(0,0,0,0.60)",
                      fontSize: "0.75rem",
                    }}
                    variant="body2"
                  >
                    {review.Comment}
                  </Typography>
                  <Box
                    key={review.ReviewId}
                    sx={{
                      marginTop: 3,
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      flexWrap: { xs: "wrap", sm: "nowrap" },
                    }}
                  >
                    <TextField
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          minHeight: "75px",
                        },
                      }}
                      id={`review-${review.ReviewId}`}
                      value={review.SuggestedResponse || ""}
                      label="Generated Response"
                      variant="filled"
                      multiline
                      minRows={3}
                      onChange={(e) =>
                        handleUpdateSuggestedResponse(
                          review.ReviewId,
                          e.target.value
                        )
                      }
                    />

                    <Box>
                      <IconButton
                        color="primary"
                        sx={{
                          backgroundColor: "#0F4343",
                          color: "#FFFFFF",
                          marginRight: { xs: 1 },
                        }}
                        onClick={() => handlePostReview(review.ReviewId)}
                      >
                        <DoneIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>

                      <IconButton
                        color="primary"
                        sx={{ border: "1px solid #0F4343", marginTop: "4px" }}
                        onClick={() =>
                          handleClearSuggestedResponse(review.ReviewId)
                        }
                      >
                        <CloseIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ))}

          {selectedTab === 1 && (
            <Box
              sx={{
                marginTop: 3,
                width: { xs: "100%", md: "100%", lg: "768px" },
              }}
            >
              <Typography variant="subtitle1">
                Automatically Respond to the Following Reviews
              </Typography>
              <Box
                sx={{
                  marginTop: 1,
                  padding: 2,
                  color: "#F6F8F8",
                  backgroundColor: selectedRadio === "all" ? "#fff" : "",
                  borderRadius: 1,
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedRadio}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="all"
                      control={
                        <Radio
                          sx={{
                            color: "#0F4343",
                            "&.Mui-checked": { color: "#0F4343" },
                          }}
                        />
                      }
                      label="All Reviews"
                      sx={{ color: "#000000DE" }}
                    />
                  </RadioGroup>
                </FormControl>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    bgcolor: "rgba(0, 0, 0, 0.12)",
                    height: "1px",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{ color: "gray", fontSize: "16px" }}
                      >
                        (Optional) Set an incentive discount for negative
                        reviews
                      </Typography>
                    }
                  />
                </FormGroup>

                {/* Conditionally render content based on checkbox value */}
                {isCheckboxChecked && (
                  <Box sx={{ marginTop: 1 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Select a discount amount"
                      helperText="We recommend providing an incentive for those leaving negative reviews to give you another try"
                      sx={{
                        width: { md: "100%", lg: "696px" },
                        "& .MuiInputBase-root": {
                          padding: "0",
                          maxHeight: "40px",
                        },
                        "& .MuiInputLabel-root": {
                          top: "-6px", // Adjust the label position when the field is active
                        },
                        marginLeft: "30px",
                      }}
                      InputProps={{
                        sx: {
                          padding: "0 0.75rem",
                        },
                      }}
                    >
                      {discountOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}
              </Box>

              {/* Additional Radio Button Options */}
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: selectedRadio === "above4" ? "#fff" : "",
                  borderRadius: 1,
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={selectedRadio}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="above4"
                      control={
                        <Radio
                          sx={{
                            color: "#0F4343",
                            "&.Mui-checked": { color: "#0F4343" },
                          }}
                        />
                      }
                      label="Only 4.0 and above"
                      sx={{ color: "#000000DE" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box
                sx={{
                  padding: 2,
                  backgroundColor: selectedRadio === "manual" ? "#fff" : "",
                  borderRadius: 1,
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={selectedRadio}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="manual"
                      control={
                        <Radio
                          sx={{
                            color: "#0F4343",
                            "&.Mui-checked": { color: "#0F4343" },
                          }}
                        />
                      }
                      label="None. Manually Approve Each Response"
                      sx={{ color: "#000000DE" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <Typography variant="subtitle1">
                  Contact Information for Negative Reviews
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  <TextField
                    id="filled-basic"
                    label="Enter Email Address"
                    variant="filled"
                    sx={{ width: "100%" }}
                    helperText="This information will be shared in responses to negative reviews so that customers can reach out with complaints and for resolutions."
                  />
                </Box>
              </Box>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: 3,
                  rowGap: 1,
                  marginTop: 3,
                  marginBottom: 3,
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                }}
              >
                <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.60)" }}>
                  Customer Information cannot be edited or removed once a review
                  request has been sent
                </Typography>
                <Stack direction="row" spacing={3}>
                  <Button variant="outlined" onClick={handleDownloadTemplate}>
                    Dowload CSV Template
                  </Button>
                  <label htmlFor="import-csv" style={{ margin: 4 }}>
                    <input
                      type="file"
                      id="import-csv"
                      accept=".csv"
                      style={{ display: "none" }}
                      onChange={handleImportCSV}
                    />
                    <Button variant="outlined" component="span">
                      Import CSV
                    </Button>
                  </label>
                </Stack>
              </Box>
              <TableContainer>
                <Table className="business-table-container">
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone #</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date Created</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography variant="body1" className="add-product">
                          + Add Customer
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {tabledata.map((cus: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{cus.name}</TableCell>
                        <TableCell>{cus.phone}</TableCell>
                        <TableCell>{cus.email}</TableCell>
                        <TableCell>{cus.date_created}</TableCell>
                        <TableCell>
                          {!cus.status ? (
                            <Chip
                              label={"Scheduled"}
                              sx={{
                                fontSize: "12px",
                                minHeight: "24px",
                                height: "24px",
                                bgcolor: "#0F4343",
                                color: "#fff",
                                paddingTop: "1px",
                              }}
                            />
                          ) : (
                            <Chip
                              label={"Sent"}
                              sx={{
                                fontSize: "12px",
                                minHeight: "24px",
                                height: "24px",
                                bgcolor: "#3D8E1D",
                                color: "#fff",
                                paddingTop: "1px",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton className="delete-icon">
                            {!cus.status ? (
                              <DeleteIcon
                                className="icon"
                                sx={{
                                  color: "#0F4343",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                            ) : (
                              <LockIcon
                                className="icon"
                                sx={{
                                  color: "#3D8E1D",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {selectedTab === 3 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: 3,
                  rowGap: 1,
                  marginTop: 3,
                  marginBottom: 3,
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={campaignOption}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{
                    height: "38px",
                    color: "#0F4343",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ToggleButton value="s/e">SMS/Emails</ToggleButton>
                  <ToggleButton value="qr">QR Codes</ToggleButton>
                </ToggleButtonGroup>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={newCampaignHandler}
                  >
                    New Campaign
                  </Button>
                </Stack>
              </Box>

              <TableContainer>
                {campaignOption === "s/e" && (
                  <Table className="business-table-container">
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Total CLicks</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {campaignTabledata.map((camp: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: selectedRows.includes(index)
                              ? "rgba(51, 132, 132, 0.08)"
                              : "transparent", // Change color if selected
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              sx={{ color: "#0F4343" }}
                              checked={selectedRows.includes(index)}
                              onChange={() =>
                                handleCampaignTableCheckboxChange(index)
                              }
                            />
                          </TableCell>
                          <TableCell>{camp.created}</TableCell>
                          <TableCell sx={{ width: "50%" }}>
                            {camp.name}
                          </TableCell>
                          <TableCell>{camp.clicks}</TableCell>
                          <TableCell
                            sx={{
                              color: "#0F4343",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              Manage{" "}
                              <KeyboardArrowRightIcon
                                sx={{ width: "24px", height: "24px" }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                {campaignOption === "qr" && (
                  <Table className="business-table-container">
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>QR Code</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell>Total Scans</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {campaignTabledata.map((camp: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: selectedRows.includes(index)
                              ? "rgba(51, 132, 132, 0.08)"
                              : "transparent", // Change color if selected
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              sx={{ color: "#0F4343" }}
                              checked={selectedRows.includes(index)}
                              onChange={() =>
                                handleCampaignTableCheckboxChange(index)
                              }
                            />
                          </TableCell>
                          <TableCell>{camp.created}</TableCell>
                          <TableCell
                            sx={{
                              width: "35%",
                              ...(camp.status === "InActive" && {
                                color: "rgba(0, 0, 0, 0.6)",
                              }),
                            }}
                          >
                            {camp.name}
                            {camp.status === "InActive" && (
                              <Chip
                                label={"Inactive"}
                                sx={{
                                  fontSize: "12px",
                                  minHeight: "24px",
                                  height: "1.25rem",
                                  bgcolor: "rgba(0, 0, 0, 0.12)",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  paddingTop: "1px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <img
                              src="/general/qr.png"
                              style={{ width: "40px", height: "40px" }}
                            ></img>
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" spacing={2}>
                              <LaunchIcon
                                sx={{
                                  width: "1.25rem",
                                  height: "1.25rem",
                                  color: "#0F4343",
                                }}
                              />
                              <CopyAllIcon
                                sx={{
                                  width: "1.25rem",
                                  height: "1.25rem",
                                  color: "#0F4343",
                                }}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>{camp.clicks}</TableCell>
                          <TableCell
                            sx={{
                              color: "#0F4343",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                            >
                              Email{" "}
                              <KeyboardArrowRightIcon
                                sx={{ width: "24px", height: "24px" }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <Stack
                direction="row"
                sx={{
                  marginTop: 3,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Show Inactive"
                  />
                </FormGroup>
                {campaignOption === "qr" && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "rgba(0,0,0,0.60)",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    QR Codes from integrations cannot be edited or deleted
                  </Typography>
                )}
              </Stack>
            </Box>
          )}
        </Box>
      </Loader>
    </Box>
  );
};

export default BusinessReviews;
