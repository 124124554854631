/* eslint-disable react-hooks/exhaustive-deps */

import "./GSCSpecificBusinessProfile.scss";

import {
  ArrowLeftOutlined,
  EyeOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Box, Chip, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Button, Statistic, Tabs } from "antd";
import  { IAPIConfig, useApi } from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AreaChartComponent from "../../../components/charts/AreaChartComponent";
import ClicksIcon from "../../../components/svg/ClicksIcon";
import CloseIcon from '@mui/icons-material/Close';
import CustomToast from "../../../utils/cutomToast/CustomToast";
import DataDisplay from "../../../components/datadisplay/DataDisplay";
import PositionIcon from "../../../components/svg/PositionIcon";
import SearchAndFilter from "../../../components/searchandfilter/SearchAndFilter";
import { useSelector } from "react-redux";

interface ProfileData {
  keys: string[];
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
}

interface DataType {
  keys: string[];
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
  Group: string;
  stats1: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats2: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats3: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats4: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
}

const GSCSpecificBusinessProfile: React.FC = () => {
  const [dateRange, setDateRange] = useState<string>("7 days");
  const navigate = useNavigate();
  const location = useLocation();
  const { doApi } = useApi();
  const { state } = location;
  const [showClicks, setShowClicks] = useState(true);
  const [showImpressions, setShowImpressions] = useState(true);
  const [showCTR, setShowCTR] = useState(true);
  const firstTimeForGSCSite = useRef(true);
  const [showPosition, setShowPosition] = useState(true);
  const [businessProfile, setBusinessProfile] = useState<ProfileData[]>([]);
  const [queries, setQueries] = useState<DataType[]>([]);
  const [pages, setPages] = useState<DataType[]>([]);
  const [businessProfileSettings, setBusinessProfileSettings] = useState<any>([]);
  const [counts, setCounts] = useState<{
    clicks: number;
    impressions: number;
    ctr: number;
    position: number;
  }>({ clicks: 0, impressions: 0, ctr: 0, position: 0 });
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState<string[]>(['chillis', '808']);
  const { userDetails } = useSelector((state: any) => state.auth);
  console.log(businessProfileSettings);
  useEffect(() => {
    try {
      if (firstTimeForGSCSite.current) {
        fetchSearchAnalytics();
        fetchQueriesAnalytics();
        fetchPagesAnalytics();
        fetchBusinessProfileSettings();
        firstTimeForGSCSite.current = false;
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    }
  }, [dateRange]);

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    firstTimeForGSCSite.current = true;
  };

  const handleBack = () => {
    navigate(-1);
  };

  const toggleGraph = (graph: string) => {
    switch (graph) {
      case "clicks":
        setShowClicks((prev) => {
          const newState = !prev;
          if (!newState && !showImpressions && !showCTR && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "impressions":
        setShowImpressions((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showCTR && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "ctr":
        setShowCTR((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showImpressions && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "position":
        setShowPosition((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showImpressions && !showCTR) {
            return true;
          }
          return newState;
        });
        break;
      default:
        break;
    }
  };

  const fetchSearchAnalytics = async () => {
    const fetchSearchAnalyticsConfig: IAPIConfig = {
      URL: `GSC/FetchSearchAnalytics`,
      method: "POST",
      payLoad: {
        ...state,
        Period: dateRange,
        dimensions: ["date"],
      },
    };

    const res: any = await doApi(fetchSearchAnalyticsConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData).rows;
      setBusinessProfile(parsedData);
      const noOfRecords = parsedData.length;
      const totalCounts = parsedData.reduce(
        (acc: any, item: any) => {
          acc.clicks += item.clicks;
          acc.impressions += item.impressions;
          acc.ctr += item.ctr;
          acc.position += item.position;
          return acc;
        },
        { clicks: 0, impressions: 0, ctr: 0, position: 0 }
      );

      totalCounts.ctr = totalCounts.ctr / noOfRecords;
      totalCounts.position = totalCounts.position / noOfRecords;

      setCounts(totalCounts);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  const fetchBusinessProfileSettings = async () => {
    const fetchBusinessProfileSettingsConfig: IAPIConfig = {
      URL: `Profile/GetBusinessProfileSettings?businessUniqueId=d32225e0-c728-4d4f-8802-ad7e7fb34f81&userUniqueId=${userDetails.uniqueId
      }`,
      method: "GET",
    };

    const res: any = await doApi(fetchBusinessProfileSettingsConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData).rows;
      setBusinessProfileSettings(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  const fetchQueriesAnalytics = async () => {
    const fetchQueriesAnalyticsConfig: IAPIConfig = {
      URL: `GSC/FetchSearchAnalytics`,
      method: "POST",
      payLoad: {
        ...state,
        Period: dateRange,
        dimensions: ["query"],
      },
    };

    const res: any = await doApi(fetchQueriesAnalyticsConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData).rows;
      setQueries(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  const fetchPagesAnalytics = async () => {
    const fetchPagesAnalyticsConfig: IAPIConfig = {
      URL: `GSC/FetchSearchAnalytics`,
      method: "POST",
      payLoad: {
        ...state,
        Period: dateRange,
        dimensions: ["page"],
      },
    };

    const res: any = await doApi(fetchPagesAnalyticsConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData).rows;
      setPages(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  const handleAddKeyword = () => {
    if (keyword.trim() && !keywords.includes(keyword.trim())) {
      setKeywords((prev) => [...prev, keyword.trim()]);
    }
    setKeyword('');
  };

  const handleRemoveKeyword = (removedKeyword: string) => {
    setKeywords((prev) => prev.filter((kw) => kw !== removedKeyword));
  };

  const tabs = [
    {
      key: "1",
      label: "Dashboard",
      children: (
        <div>
          <div className="webiste"> {state.website}</div>
          <div className="statContainer">
            <div className="counts">
              <div className="statItem">
                <ClicksIcon className="icon" style={{ color: "#3b83f6" }} />
                <Statistic value={
                  counts.clicks > 1000
                  ? `${(counts.clicks / 1000).toFixed(1)}K`
                  : counts.clicks.toString()
                } />
              </div>
              <div className="statItem">
                <EyeOutlined className="icon" style={{ color: "#5e35b1" }} />
                <Statistic
                  value={
                    counts.impressions > 1000
                      ? `${(counts.impressions / 1000).toFixed(1)}K`
                      : counts.impressions.toString()
                  }
                />
              </div>
              <div className="statItem">
                <PercentageOutlined
                  className="icon"
                  style={{ color: "#008a7c" }}
                />
                <Statistic value={(counts.ctr).toFixed(1)} />
              </div>
              <div className="statItem">
                <PositionIcon className="icon" style={{ color: "#e8710a" }} />
                <Statistic value={counts.position.toFixed(1)} />
              </div>
            </div>
            <SearchAndFilter
              dateRange={dateRange}
              handleDateRangeChange={handleDateRangeChange}
              showClicks={showClicks}
              showImpressions={showImpressions}
              showCTR={showCTR}
              showPosition={showPosition}
              toggleGraph={toggleGraph}
            />
          </div>
            <div className="chartContainer">
              <AreaChartComponent
                data={businessProfile}
                showClicks={showClicks}
                showImpressions={showImpressions}
                showCTR={showCTR}
                showPosition={showPosition}
              />
            </div>
            <div className="table-container">
              <DataDisplay
                data={queries}
                title="Queries"
                showClicks={showClicks}
                showImpressions={showImpressions}
                showCTR={showCTR}
                showPosition={showPosition}
                url = {state.website}
              />
              <DataDisplay
                data={pages}
                title="Pages"
                showClicks={showClicks}
                showImpressions={showImpressions}
                showCTR={showCTR}
                showPosition={showPosition}
                url = {state.website}
              />
            </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Settings",
      children: <><Typography variant="h6">Branded Keywords</Typography>
      <Typography>
        Set up branded keywords for <span style={{fontWeight: 'bold'}}>{state.website}</span> to monitor your brand's performance in search results.
      </Typography>

      {/* Chip list of keywords */}
      <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
        {keywords.map((kw) => (
          <Chip
            key={kw}
            label={kw}
            onDelete={() => handleRemoveKeyword(kw)}
            deleteIcon={<CloseIcon />}
            sx={{ fontSize: '14px' }}
          />
        ))}
      </Stack>

      {/* Input field and Add Button */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          variant="outlined"
          size="small"
          label="Enter a keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleAddKeyword();
              e.preventDefault();
            }
          }}
        />
        <IconButton
          color="primary"
          onClick={handleAddKeyword}
          disabled={!keyword.trim()}
          sx={{fontSize:'14px'}}
        >
          Add
        </IconButton>
      </Box></> ,
    },
  ];

  return (
    <div className="container">
      <Button className="back-button" onClick={handleBack}>
        <ArrowLeftOutlined /> Back
      </Button>
      <Tabs className="tabs" defaultActiveKey="1" items={tabs} />
    </div>
  );
};

export default GSCSpecificBusinessProfile;
