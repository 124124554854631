import { IAPIConfig, useApi } from "./useApi";
import { fetchBusinessProfilesByUniqueIdSuccess, fetchBusinessProfilesSuccess } from "../store/businessSlice";

import CustomToast from "../utils/cutomToast/CustomToast";
import { useDispatch } from "react-redux";
import { useState } from "react";

type TransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  UserUniqueId: string;
  uniqueId: string;
};

export const useBusinessProfileApis = () => {
  const dispatch = useDispatch();
  const {doApi} = useApi();

  const [sidebarDataLoading, setSidebarDataLoading] = useState<boolean>(false);
  const [businessProfilesDataLoading, setBusinessProfilesDataLoading] = useState<boolean>(false);
  const [businessUpdateProfilesDataLoading, setBusinessUpdateProfilesDataLoading] = useState<boolean>(false);

  const GetBusinessProfilesData = async () => {
    setSidebarDataLoading(true);
    try {
      const userDetails = JSON.parse(sessionStorage.getItem("user") || "{}");
      const fetchBusinessProfilesConfig: IAPIConfig = {
        URL: `/Profile/GetUserBusinessProfiles/?useruniqueId=${userDetails?.uniqueId}`,
        method: "GET",
      };
      const res: any = await doApi(fetchBusinessProfilesConfig, "seedcore");
      if (!res.data.err) {
        dispatch(fetchBusinessProfilesSuccess(res.data));
      } else {
        dispatch(fetchBusinessProfilesSuccess([]));
        CustomToast(res.data.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    }finally{
      setSidebarDataLoading(false);
    }
  };


  const GetBusinessProfilesDataByUniqueID = async (businessUniqueId: string) => {

    setBusinessProfilesDataLoading(true);
    try {
      const fetchBusinessProfilesConfig: IAPIConfig = {
        URL: `/Profile/GetBusinessProfile/?uniqueId=${businessUniqueId}`,
        method: "GET",
      };
      const res: any = await doApi(fetchBusinessProfilesConfig, "seedcore");
      if (!res.data.err) {

        dispatch(fetchBusinessProfilesByUniqueIdSuccess(JSON.parse(res.data.data)));

      } else {
        // CustomToast(res.data.data.message, "error");
      }
    } catch (error: any) {
      // CustomToast(error.message, "error");
    }finally{
      setBusinessProfilesDataLoading(false);

    }
  };

  const UpdateBusinessProfilesByUniqueID = async (businessUniqueId: string, profileData: TransformedObject) => {
    setBusinessUpdateProfilesDataLoading(true);
    try {
      const values : TransformedObject  =  {
        ...profileData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/Profile/UpdateBusinessProfile`,
        method: "POST",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        GetBusinessProfilesDataByUniqueID(businessUniqueId);
        GetBusinessProfilesData();
      } else {
        CustomToast(res?.response?.data?.message, "error");
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
    }finally{
      setBusinessUpdateProfilesDataLoading(false);
    }
  };

  return { GetBusinessProfilesData, GetBusinessProfilesDataByUniqueID, UpdateBusinessProfilesByUniqueID, businessProfilesDataLoading, businessUpdateProfilesDataLoading, sidebarDataLoading };
};

