import "./CSVDataUpload.scss";
 
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { MdCloudUpload, MdDelete } from "react-icons/md";
import React, { useState } from "react";
import  { useApi, IAPIConfig } from "../../../custom_hooks/useApi";

import CloseIcon from '@mui/icons-material/Close';
import CustomToast from "../../../utils/cutomToast/CustomToast";
import { useLocation } from "react-router-dom";


interface CustomFileuploadProps {
  handleViewPreview: (fileData: File | null) => void;
  setSelectedFile: (file: File | null) => void; // Accept a file or null
  selectedFile: File | null;
  fileSupported?: string;
  fileData: any;
  setFileData: (data: any) => void;
  parseCSVFile: (file: File) => void;
  toggleDialog: (dialog: any) => void;
  setValidProductServices: (value: boolean) => void;
}



const CSVDataUpload: React.FC<CustomFileuploadProps> = ({
  handleViewPreview,
  setSelectedFile,
  selectedFile,
  fileSupported,
  fileData,
  setFileData,
  parseCSVFile,
  toggleDialog,
  setValidProductServices
  // container,
  // entity,
  // itemId
}) => {
  console.log("fileData", fileData)
  const location = useLocation();
  
  const { businessData } = location.state || {};
  console.log("businessData", businessData)

  const { doApi } = useApi();
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState<string>("No selected file");
  const [image, setImage] = useState<string>("");
  // const [selectedFile, setFile] = useState<File | null>(null);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    setFileName(file.name);
    setSelectedFile(file); // Set the selected file to parent component state
    // setFile(file); // Set file in local state
    parseCSVFile(file);
    // Handle image or file preview (if PDF, create a preview URL)
    if (file.type === "application/pdf") {
      setImage("https://techterms.com/img/lg/pdf_109.png"); // Show a pdf icon for the PDF if necessary
    } else {
      setImage("https://www.pk-anexcelexpert.com/wp-content/uploads/2018/10/Excel-Icon.png");
    }
  };




  const uploadFile = async () => {

    try {
      setLoading(true);
  
        let companyProfileConfig: IAPIConfig;
       
        companyProfileConfig = {
          URL: `/Profile/ValidateProductServices?uniqueId=${businessData?.UniqueId}`,
          method: "POST",
          payLoad: fileData,
        };
        
        const res: any = await doApi(companyProfileConfig, "seedcore");

        console.log("validate data",res.data);
        
        if (res.status === 200) {
           // uploadProductData();
           setValidProductServices(true);
          handleViewPreview(selectedFile);
          toggleDialog('fileUpload');

        } else {
          CustomToast(`${res?.message ? res.message : "Something went wrong"}`, "error");
        }
      } catch (error: any) {
        CustomToast(error.message || "An error occurred", "error");

      }finally {
        setLoading(false);
      } 
  };



  const [open, setOpen] = useState(false);
  // const [selectedDoc, setSelectedDoc] = useState<String | null>(null);


  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  // const getFileExtension = (url: string) => {
  //   const ext = url.split('.').pop(); // Extract the part after the last '.'
  //   return ext ? ext.toLowerCase() : ''; // Ensure it's lowercase for consistency
  // };
  

  return (
    <>
    <div>
      {(image) ? (
        
        <div className="upload-form">
          <img src={image} className="placeholder-img" alt={fileName} />
          <button
          disabled={loading}
           className="upload-btn" 
           onClick={uploadFile}>
           {loading? "Uploading..." : "Upload"}
          </button> {/* Trigger upload */}
          <p>
            To view preview.{" "}
            <span onClick={()=>handleViewPreview(selectedFile)} className="preview-txt">
              Click Here
            </span>
          </p>
        </div>
        
      ) : (
        <form
          onClick={() =>
            document.querySelector<HTMLInputElement>(".input-field")?.click()
          }
          className="upload-form"
        >
          <input
            type="file"
            accept={fileSupported}
            className="input-field"
            hidden
            onChange={handleFile}
          />

          <>
            <MdCloudUpload className="uploadIcon" size={60} />
            <p>Browse Files to upload</p>
            <span className="example">(Eg. - {fileSupported})</span>
          </>
        </form>
      )}
      {(image ) && (
        <section className="uploaded-row">
          <span className="upload-content">
            {fileName} -
            <MdDelete
              color="#ff6f0f"
              onClick={() => {
                setFileName("No selected file");
                setSelectedFile(null); // Clear the selected file in parent state
                // setFile(null); // Clear file in local state
                setImage("");
                setFileData([]);
              }}
              style={{ cursor: "pointer" }}
            />
          </span>
        </section>
      )}
    </div>
    

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
        <DialogTitle>
          File Prev
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedFile?.type === "text/csv" && (
            <Box sx={{paddingTop: 3}} >
              <table border={1} >
                <thead>
                  <tr>
                    {fileData[0] &&
                      Object.keys(fileData[0]).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {fileData.slice(1).map((row: any, index: number) => (
                    <tr key={index}>
                      {Object.values(row).map((val, i) => (
                        <td key={i}>{String(val) || "\u00A0"}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CSVDataUpload;
