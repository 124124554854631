/* eslint-disable react-hooks/exhaustive-deps */

import "./GBPReviewList.scss";

import { Avatar, Button, Typography } from "@mui/material";
import { Card, Carousel, Input, List, Rate } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  {useApi, IAPIConfig } from "../../custom_hooks/useApi";

import CustomToast from "../../utils/cutomToast/CustomToast";
import Loader from "../../utils/loader/Loader";
import { Person2Outlined } from "@mui/icons-material";

//import { urlConstants } from "../../components/constants/globalConstants";

const { TextArea } = Input;

interface Review {
  Id: number;
  ReviewId: string;
  LocationId: string;
  ReviewerDisplayName: string;
  StarRating: "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE";
  Comment: string;
  CreateTime: string;
  UpdateTime: string;
  Name: string;
  ReviewReplyComment: string;
  ReviewReplyUpdateTime: string;
  SuggestedResponse?: string;
}

const GBPReviewList: React.FC = () => {
  const { doApi } = useApi();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [displayedReviews, setDisplayedReviews] = useState<Review[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>(
    "locations/14167346666544631840"
  );
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;
  const [loading, setLoading] = useState(true);
  const [editableReview, setEditableReview] = useState<number | null>(null);
  const reviewApiControl = useRef(true);
  const locations = [
    { id: "locations/14167346666544631840", name: "Valley Isle Motors Ltd" },
    { id: "locations/2183337265960411025", name: "Banyan Networks" },
    { id: "locations/356181335587493689", name: "Sally Morin Law" },
    { id: "locations/7646765876861574867", name: "Pesca Waikiki Beach" },
  ];

  useEffect(() => {
    if (reviewApiControl.current) {
      fetchReviews(selectedLocation);
      reviewApiControl.current = false;
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (reviews.length > 0) {
      setDisplayedReviews(reviews.slice(0, reviewsPerPage));
      setPage(1);
    }
  }, [reviews]);

  const fetchReviews = async (locationId: string) => {
    try {
      setLoading(true);
      const storeReviewsConfig: IAPIConfig = {
        URL: `/Review/FetchReviews?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924&nameFieldInAccount=accounts/114706490255130634915&nameFieldInLocation=${locationId}`,
        method: "GET",
      };
      const res: any = await doApi(storeReviewsConfig, "seedcore");
      if (res.status === 200 && res.data.err === undefined) {
        const result = JSON.parse(res.data.data);
        setReviews(result);
      } else {
        setReviews([]);
        setDisplayedReviews([]);
        CustomToast("No Data Found.", "error");
      }
    } catch (error: any) {
      console.error(error);
      setReviews([]);
      setDisplayedReviews([]);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const renderRating = (
    starRating: "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE"
  ): number => {
    const ratingMap: { [key: string]: number } = {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5,
    };
    return ratingMap[starRating] || 0;
  };

  const loadMoreReviews = () => {
    const nextPage = page + 1;
    const newDisplayedReviews = reviews.slice(0, nextPage * reviewsPerPage);
    setDisplayedReviews(newDisplayedReviews);
    setPage(nextPage);
  };

  const convertKeysToLowerCase = (
    obj: Record<string, any>
  ): Record<string, any> => {
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      newObj[lowerCaseKey] = obj[key];
    });
    return newObj;
  };

  const handleSeeSuggestedResponse = async (e: any, reviews: Review[]) => {
    setLoading(true);
    e.preventDefault();
    if (e.target.innerText === "Get suggested replies") {
      try {
        const responses = await Promise.all(
          reviews.map(async (review) => {
            const reviewPayload = convertKeysToLowerCase(review);
            //URL: `/Review/GenerateAssistedReplies?apiKey=${urlConstants.AI_API_KEY}`
            const storeReviewsConfig: IAPIConfig = {
              URL: `/Review/GenerateAssistedReplies`,
              method: "POST",
              payLoad: reviewPayload,
            };
            try {
              const res: any = await doApi(storeReviewsConfig, "seedcore");
              if (res.status === 200 && res.data.err === undefined) {
                const data = JSON.parse(res.data.data);
                return {
                  success: true,
                  reviewId: review.ReviewId,
                  suggestedResponse: data[0],
                };
              } else {
                return {
                  success: false,
                  error: "No Data Found.",
                  reviewId: review.ReviewId,
                };
              }
            } catch (error: any) {
              return {
                success: false,
                error: error.message,
                reviewId: review.ReviewId,
              };
            }
          })
        );

        const successfulResponses = responses.filter(
          (response) => response.success
        );
        const failedResponses = responses.filter(
          (response) => !response.success
        );

        setReviews((prevReviews) => {
          return prevReviews.map((review) => {
            const response = successfulResponses.find(
              (res) => res.reviewId === review.ReviewId
            );
            if (response) {
              return {
                ...review,
                SuggestedResponse: response.suggestedResponse,
              };
            }
            return review;
          });
        });

        if (successfulResponses.length) {
          console.log(
            "Successful Responses:",
            successfulResponses.map((response) => response.suggestedResponse)
          );
        }

        if (failedResponses.length) {
          console.error(
            "Failed Responses:",
            failedResponses.map((response) => response.error)
          );
          CustomToast(failedResponses[0].error, "error");
        } else {
          CustomToast("All reviews processed successfully.", "success");
        }
      } catch (error: any) {
        console.error(error);
        CustomToast(error.message, "error");
      } finally {
        setLoading(false);
      }
    } else if (e.target.innerText === "Accept & Post All Replies") {
      setLoading(true);
      try {
        const postResponses = await Promise.all(
          displayedReviews.map(async (review) => {
            if (review.SuggestedResponse) {
              const storeReviewsConfig: IAPIConfig = {
                URL: `/Review/PostReply?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924`,
                method: "PUT",
                payLoad: {
                  reviewId: review.ReviewId,
                  reply: review.SuggestedResponse,
                },
              };
              try {
                const res: any = await doApi(storeReviewsConfig, "seedcore");
                return {
                  success: res.status === 200,
                  reviewId: review.ReviewId,
                };
              } catch (error: any) {
                return { success: false, reviewId: review.ReviewId };
              }
            }
            return { success: false, reviewId: review.ReviewId };
          })
        );

        const successfulPosts = postResponses.filter(
          (response) => response.success
        );
        const failedPosts = postResponses.filter(
          (response) => !response.success
        );

        if (successfulPosts.length) {
          CustomToast("All replies posted successfully.", "success");
        }

        if (failedPosts.length) {
          CustomToast("Some replies failed to post.", "error");
        }
      } catch (error: any) {
        console.error(error);
        CustomToast(error.message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePostReply = async (review: Review) => {
    if (review.SuggestedResponse) {
      try {
        setLoading(true);
        const storeReviewsConfig: IAPIConfig = {
          URL: `/Review/PostReply?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924`,
          method: "PUT",
          payLoad: {
            key: review.ReviewId,
            value: review.SuggestedResponse,
          },
        };
        const res: any = await doApi(storeReviewsConfig, "seedcore");
        if (res.status === 200 && res.data.err === undefined) {
          CustomToast("Reply posted successfully.", "success");
        } else {
          CustomToast(res.data.err, "error");
        }
      } catch (error: any) {
        CustomToast(error.message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="review-list-container">
      <Carousel
        afterChange={(current) => {
          setSelectedLocation(locations[current].id);
          reviewApiControl.current = true;
        }}
        arrows
        infinite={false}
        className="review-carousel"
      >
        {locations.map((location) => (
          <div key={location.id} className="carousel-slide">
            <Typography variant="h5">{location.name}</Typography>
          </div>
        ))}
      </Carousel>
      <Loader spinning={loading}>
        {reviews && reviews.length > 0 && (
          <div className="card-header">
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={(e: any) =>
                handleSeeSuggestedResponse(e, displayedReviews)
              }
            >
              {reviews.some((review) => review.SuggestedResponse)
                ? "Accept & Post All Replies"
                : "Get suggested replies"}
            </Button>
          </div>
        )}
        <List
          itemLayout="vertical"
          dataSource={displayedReviews}
          renderItem={(review) => (
            <Card className="review-list-card" key={review.ReviewId}>
              <Card.Meta
                avatar={
                  <Avatar>
                    <Person2Outlined />
                  </Avatar>
                }
                title={
                  <Typography variant="h6-serif">
                    {review.ReviewerDisplayName}
                  </Typography>
                }
                description={
                  <Rate
                    disabled
                    defaultValue={renderRating(review.StarRating)}
                  />
                }
              />
              <Typography variant="body1" className="review-comment">
                {review.Comment}
              </Typography>
              {review.SuggestedResponse && (
                <div className="response-container">
                  <label htmlFor={`suggested-response-${review.Id}`}>
                    Suggested Response:
                  </label>
                  <TextArea
                    id={`suggested-response-${review.Id}`}
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={review.SuggestedResponse.trim()}
                    readOnly={editableReview !== review.Id}
                    onChange={(e) =>
                      setReviews((prevReviews) =>
                        prevReviews.map((r) =>
                          r.Id === review.Id
                            ? { ...r, SuggestedResponse: e.target.value }
                            : r
                        )
                      )
                    }
                  />
                  <div className="button-group">
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      onClick={() => setEditableReview(review.Id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      size="medium"
                      onClick={() => {
                        handlePostReply(review);
                      }}
                    >
                      Post Reply
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          )}
        />
      </Loader>
      {displayedReviews.length < reviews.length && (
        <div className="load-more-button-container">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={loadMoreReviews}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default GBPReviewList;
