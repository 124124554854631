import { IAPIConfig, useApi } from "./useApi";

import CustomToast from "../utils/cutomToast/CustomToast";
import { useState } from "react";

const useRemoveAgencyData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { doApi } = useApi();

  const removeCoreAgency = async (
    userUniqueId: string,
    onSuccess?: () => void
  ) => {
    setLoading(true);
    setError(null);
  
    const removeCoreAgencyConfig: IAPIConfig = {
      URL: `/Agency/DeleteAgencyData?userUniqueId=${userUniqueId}`,
      method: "DELETE",
    };
  
    try {
      const res: any = await doApi(removeCoreAgencyConfig, "seedcore");
  
      if (res?.data?.err === false) { // Ensure explicit validation
        if (onSuccess) onSuccess(); // Only call onSuccess on explicit success
      } else {
        const message = res?.data?.message || "Failed to remove agency data";
        CustomToast(message, "error");
      }
    } catch (err: any) {
      setError(err.message || "An unexpected error occurred");
      CustomToast(err.message || "An unexpected error occurred", "error");
    } finally {
      setLoading(false);
    }
  }; 

  return { removeCoreAgency, loading, error };
};

export default useRemoveAgencyData;
