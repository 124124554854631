// theme.ts

import '@mui/material/styles/createPalette';

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    "h5-serif": React.CSSProperties;
    "h6-serif": React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    "h5-serif"?: React.CSSProperties;
    "h6-serif": React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    "h5-serif": true;
    "h6-serif": true;
  }
}

declare module '@mui/material/styles/createPalette' {
  // Extend the SimplePaletteColorOptions interface
  interface SimplePaletteColorOptions {
    _states?: {
      hover?: string;
      selected?: string;
      focus?: string;
      focusVisible?: string;
      outlinedBorder?: string;
    };
  }
  interface PaletteColor {
    _states?: {
      hover?: string;
      selected?: string;
      focus?: string;
      focusVisible?: string;
      outlinedBorder?: string;
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    surface?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      warm?: string;
    };
    _components?: {
      switch?: {
        knobFillEnabled?: string;
        slideFill?: string;
      };
    };
  }

  interface Palette {
    surface?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      warm?: string;
    };
    _components?: {
      switch?: {
        knobFillEnabled?: string;
        slideFill?: string;
      };
    };
  }
}


declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    white_states?: {
      main: string;
      focusVisible: string;
    };
  }

  interface PaletteOptions {
    common?: Partial<CommonColors>;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    _states?: {
      focusVisible: string;
    };
  }
}


let theme = createTheme({
    "colorSchemes": {
      "light": {
        "palette": {
          "primary": {
            "main": "#0F4343",
            "dark": "#093535",
            "light": "#338484",
            "contrastText": "#FFFFFF",
            "_states": {
              "hover": "rgba(51, 132, 132, 0.04)",
              "selected": "rgba(51, 132, 132, 0.08)",
              "focus": "rgba(51, 132, 132, 0.12)",
              "focusVisible": "rgba(51, 132, 132, 0.3)",
              "outlinedBorder": "rgba(51, 132, 132, 0.5)"
            }
          },
          "secondary": {
            "main": "#5B44CA",
            "dark": "#4936AB",
            "light": "#8B7CDA",
            "contrastText": "#FFFFFF",
            "_states": {
              "hover": "rgba(91, 68, 202, 0.04)",
              "selected": "rgba(91, 68, 202, 0.08)",
              "focus": "rgba(91, 68, 202, 0.12)",
              "focusVisible": "rgba(91, 68, 202, 0.3)",
              "outlinedBorder": "rgba(91, 68, 202, 0.5)"
            },
          },
          "error": {
            "contrastText": "#FFFFFF",
            "main": "#D32F2F",
            "dark": "#C62828",
            "_states": {
              "outlinedBorder": "rgba(211, 47, 47, 0.5)",
              "focusVisible": "rgba(211, 47, 47, 0.3)",
              "hover": "rgba(211, 47, 47, 0.04)"
            }
          },
          "warning": {
            "contrastText": "#FFFFFF",
            "main": "#EF6C00",
            "dark": "#E65100",
            "_states": {
              "outlinedBorder": "rgba(239, 108, 0, 0.5)",
              "focusVisible": "rgba(239, 108, 0, 0.3)",
              "hover": "rgba(239, 108, 0, 0.04)"
            }
          },
          "info": {
            "main": "#0266D1",
            "dark": "#013F9D",
            "light": "#0380F4",
            "contrastText": "#FFFFFF",
            "_states": {
              "outlinedBorder": "rgba(2, 136, 209, 0.5)",
              "hover": "rgba(2, 136, 209, 0.04)"
            }
          },
          "success": {
            "main": "#3D8E1D",
            "dark": "#276A0F",
            "light": "#60C833",
            "contrastText": "#FFFFFF",
            "_states": {
              "outlinedBorder": "rgba(46, 125, 50, 0.5)",
              "focusVisible": "rgba(46, 125, 50, 0.3)",
              "hover": "rgba(46, 125, 50, 0.04)"
            }
          },
          "surface": {
            "primary": "#ECEFF1",
            "secondary": "#F6F8F8",
            "tertiary": "#E2E6E9",
            "warm": "#EBECE9"
          },
          "action": {
            "disabled": "rgba(0, 0, 0, 0.38)",
            "disabledBackground": "rgba(0, 0, 0, 0.12)",
            "hover": "rgba(0, 0, 0, 0.04)"
          },
          "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "_states": {
              "focusVisible": "rgba(0, 0, 0, 0.3)"
            }
          },
          "common": {
            "white_states": {
              "main": "#FFFFFF",
              "focusVisible": "rgba(255, 255, 255, 0.3)"
            }
          },
          "_components": {
            "switch": {
              "knobFillEnabled": "#FAFAFA",
              "slideFill": "#000000"
            }
          }
        }
      },
      "dark": {
        "palette": {
          "primary": {
            "main": "#78BABA",
            "dark": "#338484",
            "light": "#E4F1F1",
            "contrastText": "rgba(0, 0, 0, 0.87)",
            "_states": {
              "hover": "rgba(120, 186, 186, 0.08)",
              "selected": "rgba(120, 186, 186, 0.16)",
              "focus": "rgba(120, 186, 186, 0.12)",
              "focusVisible": "rgba(120, 186, 186, 0.3)",
              "outlinedBorder": "rgba(120, 186, 186, 0.5)"
            }
          },
          "secondary": {
            "main": "#AB9FE5",
            "dark": "#7360D2",
            "light": "#E8E5FB",
            "_states": {
              "hover": "rgba(171, 159, 229, 0.08)",
              "selected": "rgba(171, 159, 229, 0.16)",
              "focus": "rgba(171, 159, 229, 0.12)",
              "focusVisible": "rgba(171, 159, 229, 0.3)",
              "outlinedBorder": "rgba(171, 159, 229, 0.5)"
            },
            "contrastText": "rgba(0, 0, 0, 0.87)"
          },
          "error": {
            "contrastText": "#FFFFFF",
            "main": "#F44336",
            "dark": "#D32F2F",
            "_states": {
              "outlinedBorder": "rgba(244, 67, 54, 0.5)",
              "focusVisible": "rgba(244, 67, 54, 0.3)",
              "hover": "rgba(244, 67, 54, 0.08)"
            }
          },
          "warning": {
            "contrastText": "rgba(0, 0, 0, 0.87)",
            "main": "#FFA726",
            "dark": "#F57C00",
            "_states": {
              "outlinedBorder": "rgba(255, 167, 38, 0.5)",
              "focusVisible": "rgba(255, 167, 38, 0.3)",
              "hover": "rgba(255, 167, 38, 0.08)"
            }
          },
          "info": {
            "main": "#2993F6",
            "dark": "#0266D1",
            "light": "#4FA6F7",
            "contrastText": "rgba(0, 0, 0, 0.87)",
            "_states": {
              "outlinedBorder": "rgba(41, 182, 246, 0.5)",
              "hover": "rgba(41, 182, 246, 0.08)"
            }
          },
          "success": {
            "main": "#75D150",
            "dark": "#48A125",
            "light": "#8DD96F",
            "contrastText": "rgba(0, 0, 0, 0.87)",
            "_states": {
              "outlinedBorder": "rgba(102, 187, 106, 0.5)",
              "focusVisible": "rgba(102, 187, 106, 0.3)",
              "hover": "rgba(102, 187, 106, 0.08)"
            }
          },
          "surface": {
            "primary": "#263238",
            "secondary": "#1C2429",
            "tertiary": "#303E45",
            "warm": "#471F1F"
          },
          "action": {
            "disabled": "rgba(255, 255, 255, 0.38)",
            "disabledBackground": "rgba(255, 255, 255, 0.12)",
            "hover": "rgba(255, 255, 255, 0.08)"
          },
          "text": {
            "primary": "#FFFFFF",
            "_states": {
              "focusVisible": "rgba(255, 255, 255, 0.3)"
            }
          },
          "common": {
            "white_states": {
              "main": "#FFFFFF",
              "focusVisible": "rgba(255, 255, 255, 0.3)"
            }
          },
          "_components": {
            "switch": {
              "knobFillEnabled": "#E0E0E0",
              "slideFill": "rgba(255, 255, 255, 0.38)"
            }
          }
        }
      }
    },
    "shape": {
      "borderRadius": 8
    },
    "shadows": [
      'none', // 0
      '0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1)', // 1
      '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.2)', // 2
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2)', // 3
      '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.2)', // 4
      '0px 6px 12px rgba(0, 0, 0, 0.1), 0px 6px 16px rgba(0, 0, 0, 0.2)', // 5
      '0px 8px 16px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.2)', // 6
      '0px 12px 24px rgba(0, 0, 0, 0.1), 0px 12px 32px rgba(0, 0, 0, 0.2)', // 7
      '0px 16px 32px rgba(0, 0, 0, 0.1), 0px 16px 40px rgba(0, 0, 0, 0.2)', // 8
      '0px 24px 48px rgba(0, 0, 0, 0.1), 0px 24px 56px rgba(0, 0, 0, 0.2)', // 9
      '0px 40px 80px rgba(0, 0, 0, 0.1), 0px 40px 100px rgba(0, 0, 0, 0.2)', // 10
      '0px 56px 112px rgba(0, 0, 0, 0.1), 0px 56px 140px rgba(0, 0, 0, 0.2)', // 11
      '0px 72px 144px rgba(0, 0, 0, 0.1), 0px 72px 168px rgba(0, 0, 0, 0.2)', // 12
      '0px 88px 176px rgba(0, 0, 0, 0.1), 0px 88px 196px rgba(0, 0, 0, 0.2)', // 13
      '0px 104px 208px rgba(0, 0, 0, 0.1), 0px 104px 224px rgba(0, 0, 0, 0.2)', // 14
      '0px 120px 240px rgba(0, 0, 0, 0.1), 0px 120px 252px rgba(0, 0, 0, 0.2)', // 15
      '0px 136px 272px rgba(0, 0, 0, 0.1), 0px 136px 280px rgba(0, 0, 0, 0.2)', // 16
      '0px 152px 304px rgba(0, 0, 0, 0.1), 0px 152px 308px rgba(0, 0, 0, 0.2)', // 17
      '0px 168px 336px rgba(0, 0, 0, 0.1), 0px 168px 336px rgba(0, 0, 0, 0.2)', // 18
      '0px 184px 368px rgba(0, 0, 0, 0.1), 0px 184px 368px rgba(0, 0, 0, 0.2)', // 19
      '0px 200px 400px rgba(0, 0, 0, 0.1), 0px 200px 400px rgba(0, 0, 0, 0.2)', // 20
      '0px 216px 432px rgba(0, 0, 0, 0.1), 0px 216px 432px rgba(0, 0, 0, 0.2)', // 21
      '0px 232px 464px rgba(0, 0, 0, 0.1), 0px 232px 464px rgba(0, 0, 0, 0.2)', // 22
      '0px 248px 496px rgba(0, 0, 0, 0.1), 0px 248px 496px rgba(0, 0, 0, 0.2)', // 23
      '0px 264px 528px rgba(0, 0, 0, 0.1), 0px 264px 528px rgba(0, 0, 0, 0.2)', // 24
    ],
    "typography": {
      "h1": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 500
      },
      "h2": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 600
      },
      "h3": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 600
      },
      "h4": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 600
      },
      "h5": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 600
      },
      "h6": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 600
      },
      "h5-serif": {
        "fontSize": "1.5rem",
        "fontFamily": "IBM Plex Serif, Helvetica, Arial, sans-serif",
        "fontStyle": "normal",
        "fontWeight": 600,
        "lineHeight": "133.4%",
        "textTransform": "none",
        "textDecorationLine": "none"
      },
      "h6-serif": {
        "fontSize": "1.25rem",
        "fontFamily": "IBM Plex Serif, Helvetica, Arial, sans-serif",
        "fontStyle": "normal",
        "fontWeight": 600,
        "lineHeight": "160%",
        "textTransform": "none",
        "textDecorationLine": "none"
      },
      "body1": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif"
      },
      "body2": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif"
      },
      "subtitle1": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif",
        "fontWeight": 500
      },
      "subtitle2": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif"
      },
      "overline": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif"
      },
      "caption": {
        "fontFamily": "IBM Plex Sans, Helvetica, Arial, sans-serif"
      }
    }
  });

  theme = createTheme(theme, {
    "components": {
      "MuiButton": {
        "styleOverrides": {
          "root": {
            "&.MuiButton-sizeLarge.MuiButton-containedPrimary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.primary.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedInherit": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": "#F5F5F5"
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedInherit (white)": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#F5F5F5"
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedSecondary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background": theme.palette.secondary.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.secondary.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedError": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedWarning": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.contrastText,
                "background":theme.palette.warning.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedInfo": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-containedSuccess": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.dark
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main,
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.primary.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.dark
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": "#F5F5F5"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#F5F5F5"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.dark
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.dark
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.contrastText,
                "background":theme.palette.warning.dark
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.dark
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-containedSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.primary.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.primary.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.contrastText,
                "background": theme.palette.primary.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": "#F5F5F5"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#E0E0E0",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background":  theme.palette.common.white_states?.main,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": "#F5F5F5"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.secondary.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.secondary.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.contrastText,
                "background": theme.palette.secondary.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.error.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.contrastText,
                "background": theme.palette.error.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.contrastText,
                "background": theme.palette.warning.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.warning.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.contrastText,
                "background":theme.palette.warning.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.info.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.contrastText,
                "background": theme.palette.info.dark
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-containedSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.main,
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "opacity": "0.3",
                  "background": theme.palette.success.contrastText,
                  "padding": "0",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": theme.palette.action.disabledBackground
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.contrastText,
                "background": theme.palette.success.dark
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedPrimary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none"
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none",
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": theme.palette.primary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedInherit": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedInherit (white)": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedSecondary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": theme.palette.secondary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedError": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none"
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": theme.palette.error._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedWarning": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": theme.palette.warning._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedInfo": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": theme.palette.info._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-outlinedSuccess": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": theme.palette.success._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none"
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": theme.palette.primary._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": theme.palette.action.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": theme.palette.action.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none"
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": theme.palette.secondary._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": theme.palette.error._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": theme.palette.warning._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": theme.palette.info._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-outlinedSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": theme.palette.success._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "border": `1px solid ${theme.palette.primary._states?.outlinedBorder}`,
                "background": theme.palette.primary._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "border": `1px solid ${theme.palette.text.primary}`,
                "background": theme.palette.action.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "border": `1px solid ${theme.palette.common.white_states?.main}`,
                "background": theme.palette.action.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "border": `1px solid ${theme.palette.secondary._states?.outlinedBorder}`,
                "background": theme.palette.secondary._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "border": `1px solid ${theme.palette.error._states?.outlinedBorder}`,
                "background": theme.palette.error._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "border": `1px solid ${theme.palette.warning._states?.outlinedBorder}`,
                "background": theme.palette.warning._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "border": `1px solid ${theme.palette.info._states?.outlinedBorder}`,
                "background": theme.palette.info._states?.hover
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-outlinedSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "37.549px",
                  "height": "38.571px",
                  "borderRadius": "100px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "border": `1px solid ${theme.palette.action.disabledBackground}`,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "border": `1px solid ${theme.palette.success._states?.outlinedBorder}`,
                "background": theme.palette.success._states?.hover
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none",
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none",
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "background": theme.palette.primary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none",
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "background": theme.palette.secondary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "background": theme.palette.error._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "background": theme.palette.warning._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "background": theme.palette.info._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeMedium.MuiButton-textSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.875rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "24px /* 171.429% */",
              "letterSpacing": "0.4px",
              "textTransform": "none",
              "padding": "6px 12px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "61px",
                  "height": "36px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "44.912px",
                  "height": "46.286px",
                  "borderRadius": "100px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "background": theme.palette.success._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textPrimary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none",
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "background": theme.palette.primary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textInherit": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textInherit (white)": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textSecondary": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "background": theme.palette.secondary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textError": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "background": theme.palette.error._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textWarning": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "background": theme.palette.warning._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textInfo": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "background": theme.palette.info._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeLarge.MuiButton-textSuccess": {
              "textAlign": "center",
              "fontFeatureSettings": "'liga' off, 'clig' off",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.9375rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "26px /* 173.333% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "8px 16px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "72px",
                  "height": "42px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "53.011px",
                  "height": "54px",
                  "borderRadius": "100px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "background": theme.palette.success._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textPrimary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.primary.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.primary.main,
                "background": theme.palette.primary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textInherit": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.text._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.text.primary,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.text.primary,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textInherit (white)": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.common.white_states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color":  theme.palette.common.white_states?.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color":  theme.palette.common.white_states?.main,
                "background": theme.palette.action.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textSecondary": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.secondary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.secondary.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.secondary.main,
                "background": theme.palette.secondary._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textError": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.error._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.error.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.error.main,
                "background": theme.palette.error._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textWarning": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.warning._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.warning.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.warning.main,
                "background": theme.palette.warning._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textInfo": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.primary._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.info.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.info.main,
                "background": theme.palette.info._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            },
            "&.MuiButton-sizeSmall.MuiButton-textSuccess": {
              "textAlign": "center",
              "fontFamily": "\"IBM Plex Sans\", Helvetica, Arial, sans-serif",
              "fontSize": "0.8125rem",
              "fontStyle": "normal",
              "fontWeight": "500",
              "lineHeight": "22px /* 169.231% */",
              "letterSpacing": "0.46px",
              "textTransform": "none",
              "padding": "4px 8px",
              "borderRadius": theme.shape.borderRadius,
              "opacity": "1",
              "boxShadow": "none",
              "&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none",
                "& .MuiTouchRipple-root": {
                  "width": "51px",
                  "height": "30px",
                  "background": theme.palette.success._states?.focusVisible,
                  "padding": "0",
                  "opacity": "1",
                  "borderRadius": "0",
                  "boxShadow": "none"
                }
              },
              "&:not(.Mui-disabled).Mui-focusVisible:not(:hover)": {
                "color": theme.palette.success.main,
                "background": "none"
              },
              "&:not(.Mui-disabled):not(.Mui-focusVisible):hover": {
                "color": theme.palette.success.main,
                "background": theme.palette.success._states?.hover
              },
              "&.Mui-disabled:not(.Mui-focusVisible):not(:hover)": {
                "color": theme.palette.action.disabled,
                "background": "none"
              }
            }
          }
        }
      },
      "MuiSwitch": {
        "styleOverrides": {
          "root": {
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorDefault)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background":  theme.palette.common.white_states?.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorPrimary)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.primary.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.primary.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorError)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.error.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.error.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorWarning)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.warning.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.warning.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorInfo)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.info.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.info.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSuccess)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.success.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.success.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSecondary)": {
              "& .MuiSwitch-switchBase": {
                "padding": "9px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(20px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette.secondary.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette.secondary.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "58px",
                "height": "38px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "12px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "20px",
                    "height": "20px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "34px",
                    "height": "14px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorDefault)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background":  theme.palette.common.white_states?.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorPrimary)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.primary.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.primary.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorError)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.error.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.error.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorWarning)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.warning.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.warning.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorInfo)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.info.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.info.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSuccess)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.success.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.success.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            },
            "&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSecondary)": {
              "& .MuiSwitch-switchBase": {
                "padding": "4px",
                "borderRadius": "100px",
                "background": "none",
                "opacity": "1",
                "boxShadow": "none"
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)"
                }
              },
              "&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(16px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette.secondary.main,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette.secondary.main,
                    "padding": "0",
                    "opacity": "0.5",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)": {
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)"
                }
              },
              "&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))": {
                "width": "40px",
                "height": "24px",
                "transform": "translateX(0px) translateY(0px)",
                "padding": "7px",
                "background": "none",
                "opacity": "1",
                "borderRadius": "0",
                "boxShadow": "none",
                "& .MuiSwitch-switchBase": {
                  "transform": "translateX(0px) translateY(0px)",
                  "& .MuiSwitch-thumb": {
                    "width": "16px",
                    "height": "16px",
                    "background": theme.palette._components?.switch?.knobFillEnabled,
                    "boxShadow": theme.shadows[1],
                    "padding": "0",
                    "opacity": "1",
                    "borderRadius": "50%"
                  },
                  "& + .MuiSwitch-track": {
                    "width": "26px",
                    "height": "10px",
                    "borderRadius": "100px",
                    "background": theme.palette._components?.switch?.slideFill,
                    "padding": "0",
                    "opacity": "0.38",
                    "boxShadow": "none",
                    "transform": "translateX(0px) translateY(0px)"
                  }
                }
              }
            }
          }
        }
      }
    }
  });

export default theme;
