/* eslint-disable react-hooks/exhaustive-deps */

import "./SettingsBusinessProfile.scss";

import { Box, Button, Checkbox, Typography } from "@mui/material";
import { IAPIConfig, useApi } from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ConnectBusinessModalPopUp from "./ConnectBusinessModalPopUp";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import { login } from "../../../store/authSlice";
import { urlConstants } from "../../../components/constants/globalConstants";
import { useBusinessProfileApis } from "../../../custom_hooks/useBusinessProfileApis";
import { v4 as uuidv4 } from "uuid";

const SignInGoogle = () => {
  const [isBusinessConnect, setBusinessConnect] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { GetBusinessProfilesData } = useBusinessProfileApis();
  const dispatch = useDispatch();
  const location = useLocation();
  const { doApi } = useApi();
  const { userDetails } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [business, setBusiness] = useState<any>({
    matched: [],
    recommendedBusiness: [],
    partiallyMatched: [],
    unmatched: [],
  });
  const code = searchParams.get("code");
  const authId = searchParams.get("authId") || "";
  const authCodeCon = useRef(true);
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [agencyDetails, setAgencyDetails] = useState({
    authCode: "",
    authId: "",
    agencyId: "",
  });

  // Store auth code on initial load
  useEffect(() => {
    if (code && authCodeCon.current && authId === "") {
      storeAuthCode();
      authCodeCon.current = false;
    }
  }, [code]);

  const storeAuthCode = async () => {
    try {
      setLoading(true);
      const inputPayload = {
        name: userDetails.name,
        email: userDetails.email,
        userUniqueId: userDetails.uniqueId,
        authCode: code,
        redirectUri: `${urlConstants.ENV_URL}/signin-google`,
        webSite: userDetails.website,
      };
      const storeAuthConfig: IAPIConfig = {
        URL: `/Auth/StoreAuthForCustomer`,
        method: "POST",
        payLoad: inputPayload,
      };
      const res: any = await doApi(storeAuthConfig, "seedcore");
      if (!res.data.err) {
        CustomToast("Google connect Authentication Successful.", "success");
        const parsedData = JSON.parse(res.data.data);
        setAgencyDetails({
          authCode: code || "",
          authId: parsedData.authId || "",
          agencyId: parsedData.agencyId || "",
        });
      } else {
        CustomToast(res.data.message, "error");
      }
      setBusinessConnect(true);
    } finally {
      setLoading(false);
    }
  };

  // Fetch businesses
  const fetchBusiness = async () => {
    try {
      setLoading(true);
      const fetchBusinessConfig: IAPIConfig = {
        URL: `/Location/FetchBusinesses?userUniqueId=${userDetails.uniqueId}`,
        method: "GET",
      };
      const res: any = await doApi(fetchBusinessConfig, "seedcore");
      if (res.status === 200) {
        const parseData = JSON.parse(res.data.data);
        setAccountName(parseData[0].accountName);
        const parseLocations = parseData[0].locations;
        setBusiness({
          matched: JSON.parse(parseLocations?.matchedBusinesses || "[]"),
          recommendedBusiness: JSON.parse(
            parseLocations?.recommendedBusiness || "[]"
          ),
          partiallyMatched: JSON.parse(
            parseLocations?.partialMatchedBusinesses || "[]"
          ),
          unmatched: JSON.parse(parseLocations?.notMatchedBusinesses || "[]"),
        });
      } else {
        setBusiness({
          matched: [],
          partiallyMatched: [],
          recommendedBusiness: [],
          unmatched: [],
        });
        CustomToast("Failed to get businesses", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authId !== "") {
      setBusinessConnect(true);
    }
  }, [authId]);

  useEffect(() => {
    if (isBusinessConnect) {
      fetchBusiness();
    }
  }, [isBusinessConnect]);

  const handleCheckboxChange = (businessItem: any) => {
    setSelectedBusinesses((prevSelected) => {
      const isSelected = prevSelected.some(
        (b) => b.WebsiteUri === businessItem.WebsiteUri
      );
      if (isSelected) {
        return prevSelected.filter(
          (b) => b.WebsiteUri !== businessItem.WebsiteUri
        );
      } else {
        return [...prevSelected, businessItem];
      }
    });
  };

  const handleSelectAll = () => {
    const allBusinesses = [
      ...business.matched,
      ...business.recommendedBusiness,
      ...business.partiallyMatched,
      ...business.unmatched,
    ];
    if (selectAll) {
      setSelectedBusinesses([]);
    } else {
      setSelectedBusinesses(allBusinesses);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const allBusinesses = [
      ...business.matched,
      ...business.recommendedBusiness,
      ...business.partiallyMatched,
      ...business.unmatched,
    ];
    setSelectAll(
      selectedBusinesses.length > 0 &&
        selectedBusinesses.length === allBusinesses.length
    );
  }, [selectedBusinesses, business]);

  useEffect(() => {
    if (agencyDetails.authId.trim() !== "") {
      postAgencyDetails();
    }
  }, [agencyDetails]);

  const postAgencyDetails = async () => {
    try {
      setLoading(true);

      const config: IAPIConfig = {
        URL: `/save-agency-details`,
        method: "POST",
        payLoad: agencyDetails,
      };

      const res: any = await doApi(config, "seedmain");
      if (res.data.success) {
        const authRes = {
          ...userDetails,
          agencyDetails: res.data.data,
        };
        const authData = {
          token: authRes.token,
          userDetails: authRes,
        };
        dispatch(login(authData));
        console.log("Agency details saved successfully.");
      } else {
        console.log("Agency details not saved successfully.");
      }
    } catch (error) {
      console.log("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const postSelectedBusinesses = async () => {
    try {
      setLoading(true);
    
      // Validate and map businesses
      const validBusinesses = selectedBusinesses.filter(
        (b) => b.Title && b.WebsiteUri
      );
      const payload = {
        userUniqueId: userDetails.uniqueId || "",
        accountUniqueId: authId || agencyDetails.authId,
        businesses: validBusinesses.map((b) => ({
          name: b.Title || "",
          description: "",
          website: b.WebsiteUri || "",
          displayName: "",
          locationType: "global",
          propertyId:b.propertyId,
          productsServices: [],
          locations: (b.Locations || []).map((loc: any) => ({
            id: uuidv4(),
            displayName: b.Title || "",
            url: b.WebsiteUri || "",
            name: loc.Name || "",
            accountName: accountName,
            address1: "",
            address2: "",
            landMark: "",
            city: "",
            zipCode: "",
            region: "",
            country: "",
          })),
          customerPersonas: null,
          sellingPoints: null,
          businessObjective: "",
          competitors: null,
          uniqueId: "",
          userUniqueId: userDetails.uniqueId || "",
        })),
      };

      console.log("Payload for selected businesses:", payload);

      const config: IAPIConfig = {
        URL: `/Profile/CreateAgencyBusinesses`,
        method: "POST",
        payLoad: payload,
      };

      const res: any = await doApi(config, "seedcore");
      if (res.status === 200) {
        CustomToast("Businesses connected successfully", "success");
        setBusinessConnect(false);
        GetBusinessProfilesData();
        setSelectedBusinesses([]); // Clear selected businesses
        navigate("/home");
      } else {
        CustomToast("Failed to connect businesses", "error");
      }
    } catch (error) {
      CustomToast("An error occurred. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Loader spinning={loading}>
      <ConnectBusinessModalPopUp
        open={isBusinessConnect}
        onClose={() => {
          setBusinessConnect(false);
          navigate("/home");
        }}
        title=""
      >
        <Box className="businessConnect-container">
          <Typography variant="h5" className="businessConnect-title">
            Select the businesses you would like to connect
          </Typography>

          <Box className="business-content">
            {/* Matched Businesses */}
            {business.matched.length > 0 && (
              <Typography variant="h6" className="group-title">
                Matched Businesses
              </Typography>
            )}
            {business.matched.length > 0 && (
              <Box className="businessGroup">
                {business.matched.map((b: any, index: number) => (
                  <Box key={index} className="checkboxContainer">
                    <Checkbox
                      checked={selectedBusinesses.some(
                        (selected) => selected.WebsiteUri === b.WebsiteUri
                      )}
                      onChange={() => handleCheckboxChange(b)}
                      color="primary"
                    />
                    <Box sx={{display:'flex', flexDirection: 'column', rowGap: '0.8rem'}}>
                    <Typography variant="h5" className="businessName">{b.Title}</Typography>
                    <Typography variant="body1" className="businessName">{b.WebsiteUri}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

              {/* Recommended Businesses */}
              {business.recommendedBusiness.length > 0 && (
              <Typography variant="h6" className="group-title">
                Recommended Businesses
              </Typography>
            )}
            {business.recommendedBusiness.length > 0 && (
              <Box className="businessGroup">
                {business.recommendedBusiness.map((b: any, index: number) => (
                  <Box key={index} className="checkboxContainer">
                    <Checkbox
                      checked={selectedBusinesses.some(
                        (selected) => selected.WebsiteUri === b.WebsiteUri
                      )}
                      onChange={() => handleCheckboxChange(b)}
                      color="primary"
                    />
                    <Box sx={{display:'flex', flexDirection: 'column', rowGap: '0.8rem'}}>
                    <Typography variant="h5" className="businessName">{b.Title}</Typography>
                    <Typography variant="body1" className="businessName">{b.WebsiteUri}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {/* Partially Matched Businesses */}
            {business.partiallyMatched.length > 0 && (
              <Typography variant="h6" className="group-title">
                Partially Matched Businesses
              </Typography>
            )}
            {business.partiallyMatched.length > 0 && (
              <Box className="businessGroup">
                {business.partiallyMatched.map((b: any, index: number) => (
                  <Box key={index} className="checkboxContainer">
                    <Checkbox
                      checked={selectedBusinesses.some(
                        (selected) => selected.WebsiteUri === b.WebsiteUri
                      )}
                      onChange={() => handleCheckboxChange(b)}
                      color="primary"
                    />
                    <Typography className="businessName">{b.WebsiteUri}</Typography>
                  </Box>
                ))}
              </Box>
            )}
            {business.unmatched.length > 0 && (
              <Typography variant="h6" className="group-title">
                Unmatched Businesses
              </Typography>
            )}
            {/* Unmatched Businesses */}
            {business.unmatched.length > 0 && (
              <Box className="businessGroup">
                {business.unmatched.map((b: any, index: number) => (
                  <Box key={index} className="checkboxContainer">
                    <Checkbox
                      checked={selectedBusinesses.some(
                        (selected) => selected.WebsiteUri === b.WebsiteUri
                      )}
                      onChange={() => handleCheckboxChange(b)}
                      color="primary"
                    />
                    <Typography className="businessName">
                      {b.WebsiteUri}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box className="footer-actions">
            <Box className="selectAll">
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                color="primary"
              />
              <Typography>
                Select All (
                {business.matched.length +
                 business.recommendedBusiness.length +
                 business.partiallyMatched.length +
                 business.unmatched.length}
                )
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedBusinesses.length === 0}
              onClick={postSelectedBusinesses}
            >
              Continue{" "}
              {selectedBusinesses.length !== 0 &&
                `(${selectedBusinesses.length})`}
            </Button>
          </Box>
        </Box>
      </ConnectBusinessModalPopUp>
    </Loader>
  );
};

export default SignInGoogle;
