import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const formData = {};
  return { formData};
};

const companyProfileSlice = createSlice({
  name: "companyProfileDetails",
  initialState: getInitialState(),
  reducers: {
    companyProfile: (state, action) => {
      console.log('Updating companyProfile with:', action.payload);
      state.formData = action.payload;
    }
  }
});

export const { companyProfile } = companyProfileSlice.actions;
export const companyProfileReducer = companyProfileSlice.reducer;
