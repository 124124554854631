import React from 'react';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';

interface ChipProps {
  label: React.ReactNode;
  increase: boolean;
  color: 'success' | 'error';
  size?: string;
  iconsize?: string;
}

const Label: React.FC<ChipProps> = ({ label, increase, color, size, iconsize }) => {
  const chipStyle = {
    flexDirection: 'row',
    padding: '1px 4px',
    borderRadius: '6px',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: '500',
    letterSpacing: 0.15,
    fontSize: size ? size : '16px',
    color: color === 'success' ? '#3D8E1D' : '#CD3535',
    backgroundColor: color === 'success' ? 'rgba(46, 125, 50, 0.08)' : 'rgba(211, 47, 47, 0.08)'
  } as React.CSSProperties;

  return <span style={chipStyle}>
    {
    increase ? 
    <NorthEastIcon sx={{fontSize: `${iconsize ? iconsize : '14px'}`}} /> 
    : 
    <NorthWestIcon sx={{fontSize: `${iconsize ? iconsize : '14px'}`, transform: 'rotateX(180deg) rotateY(180deg)', }} />} {`${label}`
    }
    </span>;
};

export default Label;
