import "./ViewUsers.scss";

import { Button, ConfigProvider, Modal, Space, Table, Tag, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  ClockCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import  {IAPIConfig, useApi} from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";

import CreateUser from "../create/CreateUser";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import { useConfirmDialog } from "../../../components/Dialog/ConfirmDialogContext";
import { useDispatch } from "react-redux";
import useRemoveAgencyData from "../../../custom_hooks/removeAgencyData";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";
import { userProfile } from "../../../store/userProfileSlice";

// import { userEntityTypeAction } from "../../../store/userEntityType";
// import { urlConstants } from "../../../components/constants/globalConstants";
//import { SettingsOutlined } from "@mui/icons-material";


type UserDetails = {
  UserName: string;
  Email: string;
  FirstName: string;
  LastName: string;
  // MobileNo: string;
  RoleId: string;
  RoleName: string;
  // Password: string;
  // ConfirmPassword: string;
  // AssociatedBusiness: { name: string; uniqueId: string }[];
  Id: Number;
  IsActive: boolean;
  UniqueId: string,
  Entity: string
};

const ViewUsers: React.FC = () => {
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstTime = useRef(true);
  const dispatch = useDispatch();
  const { removeCoreAgency } = useRemoveAgencyData();
  const { showConfirmDialog } = useConfirmDialog();

  const handleRemoveAgencyDetails = (IsActive: boolean, userID: Number, name: string, userUniqueId: string) => {
    showConfirmDialog({
      title: "Confirmation",
      message: 'Are you sure you want to delete this Agency user?',
      onConfirm: () => {
        if(IsActive){
          removeCoreAgency(userUniqueId, () => {
            removerUser(userID, name);
         });
        }else{
          removerUser(userID, name);
        }
      },
      onCancel: () => {
        console.log('Delete action cancelled!');
      },
    });
  };

  const { sendPushNotifications } = useSendPushNotifications();
  const showEditModal = (
    e: React.MouseEvent<HTMLButtonElement>,
    user: UserDetails,
    edit: boolean,
    update: boolean
  ) => {
    e.preventDefault();
    console.log("User: ", user);
    console.log("Edit: ", edit, "Update: ", update);
    dispatch(userProfile(user));
    setIsModalVisible(true);
    setEditable(edit);
    setUpdate(update);
    console.log("Modal visibility: ", isModalVisible);
  };

  const { doApi } = useApi();

  const handleCancel = () => {
    setIsModalVisible(false);
    if (update) {
      firstTime.current = true;
    }
    dispatch(userProfile({}));
  };

  const getUserProfiles = async () => {
    setLoading(true);
    try {
      const fetchUserProfilesConfig: IAPIConfig = {
        URL: `/get-user-profiles`,
        method: "GET"
      };
      const res: any = await doApi(fetchUserProfilesConfig, "seedmain");
      if (res.data.success) {
          setUsers(res.data.data);
      } else {
        setUsers([]);
        CustomToast(res.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    } 
  };

  useEffect(() => {
    if (firstTime.current) {
       getUserProfiles();
      firstTime.current = false;
    }
  });

  // const handleOAuth2Redirect = (record: UserDetails) => {
  //   const userEntityType = { UniqueId: record.UniqueId, Entity: record.Entity };
  //   dispatch(userEntityTypeAction({userEntityType:userEntityType}));
  //   window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly
  //   &access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${urlConstants.ENV_URL}/gsc-login&flowName=GeneralOAuthFlow&client_id=137948605480-56kn6b8a8ti77d95qu3usknlanq05fsj.apps.googleusercontent.com`;
  // };

  
  const removerUser = async (userID: Number, name: string) => {
    try {
      setLoading(true);
      const removeUserConfig: IAPIConfig = {
        URL: `/remove-user-profile?id=${userID}`,
        method: "GET",
      };
      const res: any = await doApi(removeUserConfig, "seedmain");
      if (res.data.success === true) {
        sendPushNotifications({
          title: `${name}`,
          body: res.data.message,
        });
           getUserProfiles();
      } else {
         CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
       CustomToast(err.message, "error");
    } finally {
       setLoading(false);
    }
  };
  
  const columns = [
    { title: "Name", dataIndex: "UserName", key: "UserName" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    { title: "Entity", dataIndex: "Entity", key: "Entity" },
    {
      title: "Status",
      dataIndex: "IsActive",
      key: "IsActive",
      render: (isActive: boolean) => (
        <Tag
          icon={isActive ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
          color={isActive ? "success" : "warning"}
          style={{display:'flex', justifyContent:'center', alignContent:'baseline', gap:'0.5rem', padding: '0.25rem'}}
        >
          {isActive ? "Active" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: UserDetails) => (
        <Space size="middle" className="action-buttons">
          <Tooltip title="View User">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, true, false)
              }
            />
          </Tooltip>
          {/* <Tooltip title="Login">
            <Button
              type="primary"
              icon={<UserOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>{
                 e.preventDefault();
                 handleOAuth2Redirect(record)
                 //const data = { uniqueId: record.uniqueId, name: record.name };
                 //navigate("/business/business-login", {state: data});
                 //navigate("/business/super-admin-login", {state: data} )
                }
              }
            />
          </Tooltip> */}
          
         <Tooltip title="Edit User">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, false, true)
              }
            />
          </Tooltip>
          
          <Tooltip title="Delete Profile">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveAgencyDetails(record.IsActive, record.Id, record.UserName, record.UniqueId)}
            />
          </Tooltip>         
          {/* <Tooltip title="Settings">
            <Button
              type="primary"
              icon={<SettingsOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                console.log("Settings")
              }
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  const theme = {
    token: {
      colorPrimary: "#003366",
    },
  };

  return (
    <Loader spinning={loading}>
      <div className="view-page-user">
        <h2>User Profiles</h2>
        <div className="ant-table-wrapper">
          <Table
            columns={columns}
            dataSource={users.map((user) => ({
              ...user,
              key: user.Id,
            }))}
            pagination={{ pageSize: 10 }}
          />
        </div>
        <ConfigProvider theme={theme}>
          <div className="edit-user">
            <Modal
              title={update ? "Edit User" : "View User"}
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CreateUser update={update} editable={editable} />
              </div>
            </Modal>
          </div>
        </ConfigProvider>
      </div>
    </Loader>
  );
};

export default ViewUsers;
