/* eslint-disable jsx-a11y/alt-text */

import './AddCampaign.scss';
import React, {
  useState,
} from "react";
import {
    Box,
    Button,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DrawerComponent from "./DrawerComponent";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';

type TransformedObject = {
    Name: string;
    Link: string;
};
  
const ReviewsAddCampaign = () => {
   
    const navigate = useNavigate(); 
  
    const [campaignData, setcampaignData] = useState<TransformedObject>({
        Name: "",
        Link: ""
    });
  
    const handleBack = async () => {
        navigate(-1);
    };


    const [Draweropen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
      setDrawerOpen(newOpen);
    };

    return (
        <Box className="Add-Campaign-Container">
        <ArrowBackIcon sx={{marginBottom: '12px', color: 'rgba(0, 0, 0, 0.56)', cursor: 'pointer' }} onClick={() => handleBack()} />
        {/* Business Info Section */}
        <Box className="Campaign-info">
          <Typography variant="h5" sx={{marginTop: 3}}>New QR Code Campaign</Typography>
          <Typography variant="body1"  sx={{marginTop: 3, marginBottom: 2}}>Campaign Info</Typography>
            <Box sx={{width: {xs: "100%",lg:'768px'}}} >
              {/* Campaign Name */}
              <TextField
                label="Campaign Name"
                variant="filled"
                fullWidth
                value={campaignData?.Name}
                onChange={(e) => setcampaignData({ ...campaignData, Name: e.target.value })}
                sx={{ marginBottom: 2 }} // Add some space between fields
              />

     
            {/* Business Website */}
            <TextField
                label="Review Link"
                helperText="Link where you want customers to leave reviews"
                variant="filled"
                fullWidth
                value={campaignData?.Link}
                onChange={(e) => setcampaignData({ ...campaignData, Link: e.target.value })}
              />
            </Box>

            <Box sx={{marginTop: 4}}>
                {true ?
                <Box>
                 <img src="/general/qr-lg.png" style={{width: '160px', height: '160px'}} ></img>
                 <Stack direction="row" spacing={2} sx={{marginTop: 2}} >
                     <Button variant="outlined" onClick={toggleDrawer(true)} >Send Email</Button>
                     <IconButton aria-label="download" >
                         <GetAppIcon sx={{width: '24px', height: '24px', color: '#0F4343'}} />
                     </IconButton>
                 </Stack>
                </Box>
                :
                <Button variant="contained" >Generate QR Code</Button>
                 }
            </Box>
           
            <DrawerComponent open={Draweropen} toggleDrawer={toggleDrawer} />
        </Box>
      </Box>
    );
  };
  
  export default ReviewsAddCampaign;
  