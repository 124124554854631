import { IAPIConfig, useApi } from "./useApi";
import { fetchGA4Data, fetchLocalPresence, fetchSearchAnalytics, fetchWinnableData } from "../store/businessDashboardSlice";

import CustomToast from "../utils/cutomToast/CustomToast";
import { useDispatch } from "react-redux";

export const useBusinessDashboardApis = () => {
  const dispatch = useDispatch();
  const {doApi} = useApi();



  const FetchSearchAnalytics = async (payloadData: any) => {
    try {
      const values: any = { ...payloadData };
  
      const updateBusinessConfig: IAPIConfig = {
        URL: `/GSC/FetchSearchAnalytics`,
        method: "POST",
        payLoad: values,
      };
  
      const res: any = await doApi(updateBusinessConfig, "seedcore");
  
      if (res.status === 200) {
        const finalData = res.data;
        console.log('finalData.data:', finalData.data);
  
        // Initialize variables for normalized data
        let date = null;
        let page = null;
        let query = null;
        let queryclusters = null;
        let contentgroups = null;
  
        if (Array.isArray(finalData.data)) {
          finalData.data.forEach((item: any) => {
            switch (Object.keys(item)[0]) {
              case 'date':
                date = item.date ?? null;
                break;
              case 'page':
                page = item.page ?? null;
                break;
              case 'query':
                query = item.query ?? null;
                break;
              case 'queryclusters':
                queryclusters = item.queryclusters ?? null;
                break;
              case 'contentgroups':
                contentgroups = item.contentgroups ?? null;
                break;
              default:
                break;
            }
          });
        }
  
        const normalizedData = {
          date,
          page,
          query,
          cards: finalData.cards ?? null,
          trendData: finalData.trenddata ?? null,
          queryclusters,
          contentgroups,
        };
  
        dispatch(fetchSearchAnalytics(normalizedData));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchSearchAnalytics([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchSearchAnalytics([]));
    }
  };
  

  const FetchGA4Data = async (payloadData: any) => {
  
    try {
      const values : any  =  {
        ...payloadData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/GA4/FetchGA4SitesData`,
        method: "POST",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("landingpagedata",res.data);
        
        dispatch(fetchGA4Data(res.data));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchGA4Data([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchGA4Data([]));
    }
  };

   const FetchLocalPresence = async (payloadData: any) => {
  
    try {
      const values : any  =  {
        ...payloadData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/Review/FetchLocalPresence?uniqueId=${values?.uniqueId}&period=${values?.period}&location=${values?.location}`,
        method: "GET",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("landingpagedata",res.data);
        
        dispatch(fetchLocalPresence(res.data));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchLocalPresence([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchLocalPresence([]));
    }finally{
      
    }
  };

  const FetchWinnableData = async (payloadData: any) => { 
    try {
      const values : any  =  {
        ...payloadData
      }
      const updateBusinessConfig: IAPIConfig = {
        URL: `/GSC/FetchOpportunities`,
        method: "POST",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("winnableData",res.data);
        
        dispatch(fetchWinnableData(res.data));
      } else {
        CustomToast(res?.data?.message, "error");
        dispatch(fetchWinnableData({}));
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
      dispatch(fetchWinnableData({}));
    }
  };
  

  return { FetchSearchAnalytics, FetchGA4Data, FetchLocalPresence, FetchWinnableData };
};

