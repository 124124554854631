import React from 'react';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import './PaginationComponent.scss';

interface PaginationComponentProps {
  count: number; // Total number of pages
  page: number; // Current page
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void; // Callback for page change
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({ count, page, onChange }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} pt={1}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        variant="outlined"
      />
    </Box>
  );
};

export default PaginationComponent;
