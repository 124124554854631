import "./BusinessModalPopUp.scss";

import {
  Box,
  Modal,
  Typography
} from "@mui/material";
import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface BusinessConfirmationModalPropsValues {
  open: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode; // Allows any valid React node as children
  description?: string; // Add an optional description prop
  width?: string;
}

const BusinessConfirmationModalProps: React.FC<BusinessConfirmationModalPropsValues> = ({ open, onClose, title, description, width, children })  => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="modal-wrapper"
      aria-labelledby="business-method-modal"
      aria-describedby="business-method-modal-description"
    >
      <Box className="business-modal" style={{display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', alignItems: 'center'}}>
        
       
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
          <CheckCircleIcon sx={{ color: '#3D8E1D', fontSize: '2rem' }} />
          <Typography id="business-method-modal" variant="h6" component="h2">
            Business Account Added
          </Typography>
        </Box>

        <Box className="business-modal-line"></Box>
        <Box  style={{ width: `${ width ? width : 'auto'}`, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default BusinessConfirmationModalProps;
