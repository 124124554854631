import "./ReviewEmailEditScreen.scss";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery 
} from "@mui/material";
import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AlertCustomDialog from "../../components/Dialog/AlertCustomDialog";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTheme } from '@mui/system';

const ReviewSMSEditScreen: React.FC = () => {   
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [subjectLine, setSubjectLine] = useState("This is default message");
  const [reviewLink, setReviewLink] = useState<string>("");
  const [reviewLinkOption, setReviewLinkOption] = useState<string>("cl");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [mailSendingOption, setMailSendingOption] = useState("anytime");
  const [timeSlots, setTimeSlots] = useState([
    { id: Date.now(), start: "10:00", end: "12:00" }
  ]);


  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);



  const handleDayChange = (day: string) => {
    setHasUnsavedChanges(true);
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleAddTimeSlot = () => {
    setHasUnsavedChanges(true);
    setTimeSlots([
      ...timeSlots,
      { id: Date.now(), start: "10:00", end: "12:00" }
    ]);
  };

  const handleRemoveTimeSlot = (id: number) => {
    setTimeSlots(timeSlots.filter((slot) => slot.id !== id));
  };

  const handleTimeChange = (
    id: number,
    field: "start" | "end",
    value: string
  ) => {
    setHasUnsavedChanges(true);
    setTimeSlots(
      timeSlots.map((slot) =>
        slot.id === id ? { ...slot, [field]: value } : slot
      )
    );
  };

  //   const formattedCustomerName = customerName.split(',').join(', ');


  const mailSendingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailSendingOption(event.target.value);
    setHasUnsavedChanges(true);
  };

  const isDisabled = mailSendingOption === "anytime";



  const [dialogState, setDialogState] = useState<{
    alertDialog: boolean;
  }>({
    alertDialog: false
  });

  // Function to toggle dialogs
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName]
    }));
  };

  const handleExitClick = () => {
    if (hasUnsavedChanges) {
      toggleDialog("alertDialog");
    } 
  };

  return (
    <Box className="review-email-edit-screen-container">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Acai Bowl follow up
          </Typography>
          <Typography variant="h5">SMS 1</Typography>
        </Box>
        <Box>
          <Button sx={{ marginRight: 2 }} variant="outlined" color={hasUnsavedChanges ? "error" : undefined} onClick={handleExitClick} >
            {hasUnsavedChanges ? "Exit Without Saving" : "Exit"}
          </Button>
          <Button variant="contained">Save</Button>
        </Box>
      </Stack>
      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }}>
        Email Message
      </Typography>
      <Stack
        direction={{md: "column",lg:"row"}}
        spacing={8}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="column" rowGap={1}>
            <TextField
              label="Subject Line"
              variant="filled"
              fullWidth
              value={subjectLine}
              onChange={(e) => {setSubjectLine(e.target.value);  setHasUnsavedChanges(true)}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{ width: "24px", height: "24px", marginBottom: -2 }}
                    />
                  </InputAdornment>
                )
              }}
            />

            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography variant="subtitle1" id="reviewlink">
                Review Link
              </Typography>
              <Stack direction={{xs: "column", sm:"row"}} spacing={1} sx={{ marginTop: 1 }}>
                <FormControl variant="outlined" sx={{ minWidth: {xs: "100%", sm:"240px"} }}>
                  <Select
                    value={reviewLinkOption}
                    onChange={(e) => {setReviewLinkOption(e.target.value); setHasUnsavedChanges(true) }}
                    size="small"
                  >
                    <MenuItem value="cl">Custom Link</MenuItem>
                    <MenuItem value="bl">Google Business Link</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  onChange={(e) => {setReviewLink(e.target.value); setHasUnsavedChanges(true)}}
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  value={reviewLink}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyAllIcon color="primary" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ color: "rgba(0, 0, 0, 0.38)",  }}
                />
              </Stack>
            </Box>
          </Stack>

          <Box className="schedule-settings">
            <Typography variant="subtitle1" id="reviewlink">
              Send Mail
            </Typography>

            <RadioGroup value={mailSendingOption} onChange={mailSendingHandler}>
              <FormControlLabel
                value="anytime"
                control={<Radio />}
                label="Anytime"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Schedule"
              />
            </RadioGroup>

            <Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Emails will only go out on selected days and times below
                </Typography>
                <ToggleButtonGroup
                  value={selectedDays}
                  exclusive
                  aria-label="Platform"
                  sx={{
                    marginTop: 1,
                    height: "40px",
                    minHeight: "40px",
                    fontWeight: "bold",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    color: "red",
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                    justifyContent: isMobile ? 'space-evenly' : 'center', // More even spacing on mobile
                    gap: isMobile ? 1 : 0,
                    padding: '4px 0', 
                  }}
                  size="small"
                  disabled={isDisabled} // Disable the days selection when "Anytime" is selected
                >
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                    (day) => (
                      <ToggleButton
                        key={day}
                        value={day}
                        selected={selectedDays.includes(day)}
                        onClick={() => handleDayChange(day)}
                        sx={{
                          textTransform: "capitalize",
                          padding: "0 8px",
                          width: "57.14px",
                          color: "rgba(0, 0, 0, 0.87)",
                          border: "1px solid rgba(0, 0, 0, 0.23)"
                        }}
                      >
                        {day}
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
              </Box>
              <Box>
                {timeSlots.map((slot) => (
                  <Stack
                    direction={ "row"}
                    alignItems="center"
                    spacing={{xs: 1, sm: 2}}
                    key={slot.id}
                    sx={{ marginTop: { xs: 3, sm: 1 } }}
                  >
                    <TextField
                      type="time"
                      value={slot.start}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "start", e.target.value)
                      }
                      disabled={isDisabled} // Disable start time field when "Anytime" is selected
                    />
                    <Box>
                      <RemoveIcon
                        sx={{ width: "8px", color: "rgba(0, 0, 0, 0.87)" }}
                      />
                    </Box>
                    <TextField
                      type="time"
                      value={slot.end}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "end", e.target.value)
                      }
                      disabled={isDisabled} // Disable end time field when "Anytime" is selected
                    />
                    <IconButton disabled={isDisabled}>
                      <DeleteIcon
                        sx={{
                          width: "1.5rem",
                          height: "1.5rem",
                          cursor: "pointer",
                          color: isDisabled
                            ? "rgba(0, 0, 0, 0.6)"
                            : "rgba(15, 67, 67, 1)"
                        }}
                        onClick={() => handleRemoveTimeSlot(slot.id)}
                      />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddTimeSlot}
                  sx={{ marginTop: 1 }}
                  disabled={isDisabled} // Disable Add button when "Anytime" is selected
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          {/* Preview Area */}
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Preview
          </Typography>
          <Stack className="phone-frame">
            <Box
              className="sms-preview"
              sx={{
                marginTop: 1.5,
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Box className="sms-message">
                <Typography variant="body2">{subjectLine}</Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  {reviewLink ? (
                    <Box component="span">{reviewLink}</Box>
                  ) : (
                    <span>Link</span>
                  )}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>

       {/* alert dialog */}
       <AlertCustomDialog
        open={dialogState.alertDialog}
        onClose={() => toggleDialog("alertDialog")}
        title="Are you sure you want to exit without saving?"
        minWidth="600px"
      >
          <Box className="line" sx={{ width: "100%",
            height: "0.0625rem",
            opacity: "0.2",
            background: "var(--Schemes-On-Secondary, red)"
            }}>
          </Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="outlined" color="error">
            Yes, exit without saving
          </Button>
          <Button variant="contained" onClick={() => toggleDialog("alertDialog")}>Never mind</Button>
        </Stack>
      </AlertCustomDialog>
    </Box>
  );
};

export default ReviewSMSEditScreen;
