import './SearchAndFilter.scss';

import {
  EyeOutlined,
  PercentageOutlined
} from '@ant-design/icons';

import ClicksIcon from '../svg/ClicksIcon';
import PositionIcon from '../svg/PositionIcon';
import React from 'react';
import {Select} from 'antd';
import { Tooltip } from 'antd';

const { Option } = Select;

interface SearchAndFilterProps {
  dateRange: string;
  handleDateRangeChange: (value: string) => void;
  showClicks: boolean;
  showImpressions: boolean;
  showCTR: boolean;
  showPosition: boolean;
  toggleGraph: (graph: string) => void;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  dateRange,
  handleDateRangeChange,
  showClicks,
  showImpressions,
  showCTR,
  showPosition,
  toggleGraph,
}) => {
  return (
      <div className="graph-toggles">
        <div className="icons">
          <Tooltip title="Show Clicks">
            <ClicksIcon
              className={`toggle-icon ${showClicks ? "active" : ""}`}
              onClick={() => toggleGraph("clicks")}
              style={{ color: "#3b83f6" }}
            />
          </Tooltip>
          <Tooltip title="Show Impressions">
            <EyeOutlined
              className={`toggle-icon ${showImpressions ? "active" : ""}`}
              onClick={() => toggleGraph("impressions")}
              style={{ color: "#5e35b1" }}
            />
          </Tooltip>
          <Tooltip title="Show CTR">
            <PercentageOutlined
              className={`toggle-icon ${showCTR ? "active" : ""}`}
              onClick={() => toggleGraph("ctr")}
              style={{ color: "#008a7c" }}
            />
          </Tooltip>
          <Tooltip title="Show Position">
            <PositionIcon
              className={`toggle-icon ${showPosition ? "active" : ""}`}
              onClick={() => toggleGraph("position")}
              style={{ color: "#e8710a" }}
            /> 
          </Tooltip>
        </div>
        <div className="date">
          <Select
            style={{ width: 200, marginBottom: 16 }}
            value={dateRange}
            onChange={handleDateRangeChange}
          >
            <Option value="7 days">7 days</Option>
            <Option value="14 days">14 days</Option>
            <Option value="28 days">28 days</Option>
            <Option value="3 months">3 months</Option>
            <Option value="6 months">6 months</Option>
            <Option value="8 months">8 months</Option>
            <Option value="12 months">12 months</Option>
            <Option value="16 months">16 months</Option>
          </Select>
        </div>
      </div>
  );
};

export default SearchAndFilter;
