import  {useApi, IAPIConfig } from "./useApi";

type OriginalObject = {
  Id: number;
  Name: string;
  Description: string;
  Website: string;
  LocationType: string;
  ProductsServices: string[];
  Locations: string[];
  CustomerPersonas: { PersonName: string; PersonDescription: string }[] | null;
  SellingPoints: string[] | null;
  BusinessObjective: string | null;
  Competitors: { Name: string; Website: string }[] | null;
  UniqueId: string;
  CreatedDate: string;
  CreatedTime: string;
  CreatedDateTime: string;
  UpdatedDate: string;
  UpdatedTime: string;
  UpdatedDateTime: string;
  IsActive: boolean;
};

type TransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  uniqueId: string;
};

const CommonApis = () => {
  const { doApi } = useApi();

  const transformObject = (obj: OriginalObject): TransformedObject => {
    return {
      name: obj.Name,
      description: obj.Description,
      website: obj.Website,
      productsServices: obj.ProductsServices,
      locationType: obj.LocationType,
      locations: obj.Locations,
      customerPersonas: obj.CustomerPersonas
        ? obj.CustomerPersonas.map((persona) => ({
            personName: persona.PersonName,
            personDescription: persona.PersonDescription,
          }))
        : null,
      sellingPoints: obj.SellingPoints,
      businessObjective: obj.BusinessObjective,
      competitors: obj.Competitors
        ? obj.Competitors.map((competitor) => ({
            name: competitor.Name,
            website: competitor.Website,
          }))
        : null,
      uniqueId: obj.UniqueId,
    };
  };

  const GetBusinessProfiles = async () => {
    try {
      const companySelectProfileConfig: IAPIConfig = {
        URL: "/Profile/GetBusinessProfiles",
        method: "GET",
      };
      const res: any = await doApi(companySelectProfileConfig, "seedcore");
      if (res.status === 200 && res.data.data.length > 0) {
        const originalArray: OriginalObject[] = JSON.parse(res.data.data);
        const transformedArray: TransformedObject[] =
          originalArray.map(transformObject);
        return transformedArray;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Failed to fetch customer profiles:", error);
      return [];
    }
  };

  return { GetBusinessProfiles };
};

export default CommonApis;
