/* eslint-disable react-hooks/exhaustive-deps */

import "./AnalyticsChart.scss";

import {
  ArrowRightOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Menu, Modal, Popover, Row } from "antd";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import  { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { format, parse } from "date-fns";

import CustomToast from "../../utils/cutomToast/CustomToast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface DataEntry {
  [key: string]: string;
}

interface DataItem {
  curDate: string;
  preDate: string;
  curData: {
    Users: string;
    "Key events": string;
    "New users": string;
    Sessions: string;
  };
  preData: {
    Users: string;
    "Key events": string;
    "New users": string;
    Sessions: string;
  };
}
interface UserEntityType {
  UniqueId: string;
}

interface RootState {
  userEntityDetails: {
    userEntityType: UserEntityType;
  };
}

interface AnalyticsChartProps {
  name: string;
  date: string;
  items: any;
  data: DataItem[];
}

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({
  name,
  data,
  date,
  items,
}) => {
  const [activeMetric, setActiveMetric] = useState({
    key: "Users",
    value: "users",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [values, setValues] = useState(data);
  const [chartData, setChartData] = useState<any>([]);
  const [metrics, setMetrices] = useState([
    { key: "Users", value: "users" },
    { key: "Key events", value: "keyEvents" },
    { key: "New users", value: "newUsers" },
    { key: "Sessions", value: "sessions" },
  ]);
  const [metricesValues, setMetricesValues] = useState([
    {
      name: "Sessions",
    },
    {
      name: "Users",
    },
    {
      name: "New users",
    },
    {
      name: "Key events",
    },
  ]);
  const { doApi } = useApi();
  const firstTime = useRef(false);
  const navigate = useNavigate();
  const { userEntityType } = useSelector(
    (state: RootState) => state.userEntityDetails
  );

  const formatXAxis = (tickItem: string) => {
    const parsedDate = parse(tickItem, "yyyyMMdd", new Date());
    return format(parsedDate, "MMM d");
  };

  const handleMetricClick = (metric: any) => {
    setActiveMetric(metric);
  };

  const extractDomainName = (url: string) => {
    try {
      const { hostname } = new URL(url);
      const domain = hostname.replace(/^www\./, "");
      const domainName = domain.split(".")[0];
      return domainName;
    } catch (error) {
      console.error("Invalid URL:", url);
      return url;
    }
  };

  const handleNavigate = (profileName: string) => {
    const domainName = extractDomainName(profileName);
    const data = {
      uniqueId: userEntityType.UniqueId,
      website: profileName,
      origin: "ui",
    };
    navigate(`/ga4/${domainName}`, { state: data });
  };

  const fetchSpecificDetailsForSpecificMetrices = async (value: any) => {
    const fetchLandingPageConfig: IAPIConfig = {
      URL: `GA4/FetchGA4AllSitesData`,
      method: "POST",
      payLoad: {
        uniqueId: userEntityType.UniqueId,
        website: name,
        origin: "ui",
        period: date,
        dimensions: [{ name: "date" }],
        metrics: value,
        Mode: "changemetric",
      },
    };

    const res: any = await doApi(fetchLandingPageConfig, "seedcore");
    if (res.status === 200) {
      if (res.data.data.length > 0) {
        const rawData = res.data.data[0].stats;
        const parsedData = JSON.parse(rawData);
        setValues(parsedData);
      }
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  useEffect(() => {
    if (firstTime.current) {
      fetchSpecificDetailsForSpecificMetrices(metricesValues);
      firstTime.current = true;
    }
  }, [metricesValues]);

  useEffect(() => {
        fetchSpecificDetailsForSpecificMetrices(metricesValues);
  }, [date]);

  const handleGA4Subitems = (keyItem: string, value: string) => {
    const activeMetricProcessed = activeMetric.key && activeMetric.key
      .replace(/\s+/g, "")
      .toLowerCase();

    const resIndex = metricesValues.findIndex((item) => {
      const processedItemName = item.name.replace(/\s+/g, "").toLowerCase();
      return processedItemName === activeMetricProcessed;
    });
    const metricIndex = metrics.findIndex((item) => {
      const processedItemValue = item.key.replace(/\s+/g, "").toLowerCase();
      return processedItemValue === activeMetricProcessed;
    });
    const updatefinalRes = [...metricesValues];
    const updatedMetrics = [...metrics];

    if (metricIndex !== -1) {
      updatedMetrics[metricIndex] = { key: keyItem, value: value };
    }

    if (resIndex !== -1) {
      updatefinalRes[resIndex] = { name: keyItem };
    }
    firstTime.current = true;
    setActiveMetric({ key: keyItem, value: value });
    setMetricesValues(updatefinalRes);
    setMetrices(updatedMetrics);
    setIsModalVisible(!isModalVisible);
  };

  const formatNumber = (num: number) => {
    if (num >= 1000) return (num / 1000).toFixed(1) + "K";
    return num.toString();
  };

  const calculatePercentageChange = (current: string, previous: string) => {
    const currentValue = parseInt(current, 10);
    const previousValue = parseInt(previous, 10);
    const change = ((currentValue - previousValue) / previousValue) * 100;
    return change.toFixed(2);
  };

  const renderArrow = (percentageChange: string, metric: any) => {
    if (percentageChange === "NaN") {
      return <span>No change</span>;
    }
    const change = parseFloat(percentageChange);
    const isActiveMetric =
    activeMetric.value && activeMetric.value.toLowerCase() === metric.value && metric.value.toLowerCase();

    if (change > 0) {
      return (
        <span style={{ color: isActiveMetric ? "white" : "green" }}>
          ↑ {percentageChange}%
        </span>
      );
    } else if (change < 0) {
      return (
        <span style={{ color: isActiveMetric ? "white" : "red" }}>
          ↓ {-percentageChange}%
        </span>
      );
    } else {
      return <span>{percentageChange}%</span>;
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      const chartData = values.map(({ curDate, preDate, curData, preData }) => {
        const dataEntry: Record<string, any> = { date: curDate, preDate };

        metrics.forEach(({ key, value }) => {
          dataEntry[value] = parseInt((curData as DataEntry)[key], 10);
          dataEntry[`pre${value && value.charAt(0).toUpperCase() + value && value.slice(1)}`] =
            parseInt((preData as DataEntry)[key], 10);
        });

        return dataEntry;
      });

      setChartData(chartData);
    }
  }, [values, metrics]);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const formatDate = (dateString: string) => {
    const date = parse(dateString, "yyyyMMdd", new Date());
    return format(date, "EEE d MMM");
  };

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || payload.length === 0) return null;

    const currentData = payload[0].payload;
    const metricKey =
    activeMetric.value && activeMetric.value.charAt(0).toUpperCase() + activeMetric.value && activeMetric.value.slice(1);
    const currentValue = currentData[activeMetric.value];
    const previousValue = currentData[`pre${metricKey}`];
    const percentageChange = calculatePercentageChange(
      currentValue.toString(),
      previousValue.toString()
    );

    const formattedPercentageChange = parseFloat(percentageChange).toFixed(2);

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          border: "1px solid #cccccc",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        <p>{`${formatDate(currentData.date)} vs ${formatDate(
          currentData.preDate
        )}`}</p>
        <p
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{activeMetric.key}</span>
          <span>{currentValue}</span>
          <span
            style={{
              color: parseFloat(percentageChange) > 0 ? "green" : "red",
            }}
          >
            {parseFloat(percentageChange) > 0
              ? `↑ ${formattedPercentageChange}%`
              : `↓ ${-formattedPercentageChange}%`}
          </span>
        </p>
      </div>
    );
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          display: "flex",
          columnGap: "1rem",
        }}
      >
        {payload.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <svg width="14" height="14" style={{ marginRight: 8 }}>
              <line
                x1="0"
                y1="7"
                x2="14"
                y2="7"
                stroke={entry.color}
                strokeWidth="2"
                strokeDasharray={entry.payload.strokeDasharray || "none"}
              />
            </svg>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const renderMenu = (itemList: string[]) => {
    const menuItems = itemList.map((item, index) => {
      const [label, value] = item.split(",");
      return {
        key: index.toString(),
        label: label.trim(),
        onClick: (e: any) => {
          e.domEvent.stopPropagation();
          handleGA4Subitems(label.trim(), value.trim());
        },
      };
    });

    return <Menu items={menuItems} />;
  };

  return (
    <Card className="analytics-chart-card" key={name}>
      <h2 className="title">{name}</h2>
      <Row gutter={[16, 16]} className="stats-row">
        {metrics.map((metric, index) => (
          <Col span={6} key={index}>
            <Button
              type={
                metric.value &&
                activeMetric.value &&
                activeMetric.value.toLowerCase() === metric.value.toLowerCase()
                  ? "primary"
                  : "default"
              }
              onClick={(e: any) => {
                e.stopPropagation();
                handleMetricClick({key:metric.key, value: metric.value});
              }}
              className="metric-button"
            >
              {isModalVisible && (
                <Modal
                  title=""
                  open={isModalVisible}
                  onCancel={handleCloseModal}
                  footer={null}
                  style={{ top: 40 }}
                  width={400}
                >
                  <div style={{ padding: "2.5rem" }}>
                    {Object.keys(items).map((key, index) => (
                      <Popover
                        content={renderMenu(items[key])}
                        trigger="click"
                        placement="rightTop"
                        key={index}
                      >
                        <Button
                          type="text"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {key} <RightOutlined size={16} />
                        </Button>
                      </Popover>
                    ))}
                  </div>
                </Modal>
              )}

              <div className="metric-title">
                {metric.key}{" "}
                <DownOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModalVisible(true);
                    setActiveMetric({ key: metric.key, value: metric.value });
                  }}
                />
              </div>
              <div className="metric-value">
                {formatNumber(
                  values.reduce(
                    (sum, item: any) =>
                      sum + parseInt(item.curData[metric.key], 10),
                    0
                  )
                )}
              </div>
              <div className="metric-percentage">
                {renderArrow(
                  calculatePercentageChange(
                    values
                      .reduce(
                        (sum, item: any) =>
                          sum + parseInt(item.curData[metric.key], 10),
                        0
                      )
                      .toString(),
                    values
                      .reduce(
                        (sum, item: any) =>
                          sum + parseInt(item.preData[metric.key], 10),
                        0
                      )
                      .toString()
                  ),
                  metric
                )}
              </div>
            </Button>
          </Col>
        ))}
      </Row>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <XAxis dataKey="date" tickFormatter={formatXAxis} />
          <YAxis orientation="right" />
          <CartesianGrid stroke="#eee" />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          <Line
            type="linear"
            dataKey={activeMetric.value}
            stroke="#0056b3"
            dot={false}
            name={`Last ${date}`}
          />
          <Line
            type="linear"
            dataKey={`pre${
              activeMetric.value && activeMetric.value.charAt(0).toUpperCase() +
              activeMetric.value && activeMetric.value.slice(1)
            }`}
            stroke="#0056b3"
            strokeDasharray="3 4 5 2"
            dot={false}
            name="Preceding period"
          />
        </LineChart>
      </ResponsiveContainer>

      <div className="view-report-button">
        <Button onClick={() => handleNavigate(name)}>
          View reports snapshot <ArrowRightOutlined />
        </Button>
      </div>
    </Card>
  );
};

export default AnalyticsChart;
