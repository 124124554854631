import "./ChartComponent.scss";

import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";
import { format } from "date-fns";

interface AreaChartComponentProps {
  data: {
    keys: string[];
    clicks: number;
    impressions: number;
    ctr: number;
    position: number;
  }[];
  showClicks: boolean;
  showImpressions: boolean;
  showCTR: boolean;
  showPosition: boolean;
}

const AreaChartComponent: React.FC<AreaChartComponentProps> = ({
  data,
  showClicks,
  showImpressions,
  showCTR,
  showPosition,
}) => {
  const formatXAxis = (tickItem: string) => {
    return format(new Date(tickItem), "MMM d");
  };

  const formatYAxisImpressions = (tickItem: number) => {
    return tickItem >= 1000
      ? `${(tickItem / 1000).toFixed(1)}k`
      : tickItem.toString();
  };

  return (
    <div className="chart-area-container">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="clicks" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3b83f6" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#3b83f6" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="impressions" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#5e35b1" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#5e35b1" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="ctr" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#008a7c" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#008a7c" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="position" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#e8710a" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#e8710a" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="keys" tickFormatter={formatXAxis} />
          <YAxis
            dataKey="clicks"
            yAxisId="left"
            orientation="left"
            width={40}
          />
          <YAxis
            dataKey="impressions"
            yAxisId="right"
            orientation="right"
            tickFormatter={formatYAxisImpressions}
            width={60}
          />
          {showCTR && (
            <YAxis
              dataKey="ctr"
              yAxisId="ctr"
              orientation="left"
              axisLine={false}
              tick={false}
              width={0}
            />
          )}
          {showPosition && (
            <YAxis
              dataKey="position"
              yAxisId="position"
              orientation="right"
              axisLine={false}
              tick={false}
              width={0}
            />
          )}
          <Tooltip />
          {showClicks && (
            <Area
              type="linear"
              dataKey="clicks"
              stroke="#3b83f6"
              fill="url(#clicks)"
              yAxisId="left"
            />
          )}
          {showImpressions && (
            <Area
              type="linear"
              dataKey="impressions"
              stroke="#5e35b1"
              fill="url(#impressions)"
              yAxisId="right"
            />
          )}
          {showCTR && (
            <Area
              type="linear"
              dataKey="ctr"
              stroke="#008a7c"
              fill="url(#ctr)"
              yAxisId="ctr"
            />
          )}
          {showPosition && (
            <Area
              type="linear"
              dataKey="position"
              stroke="#e8710a"
              fill="url(#position)"
              yAxisId="right"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartComponent;
