import { Navigate, useLocation } from 'react-router-dom'

import React from 'react'
import { useSelector } from 'react-redux';

const UnProtectedRoutes = ({ children }: any) => {
  const {isAuthenticated} = useSelector((state:any) => state.auth);
  const location = useLocation().pathname;
  return isAuthenticated === null || isAuthenticated === undefined || parseInt(isAuthenticated) === 0 ? (
    children
  ) : (
    <Navigate to={"/business/settings-business-profile"} state={{ from: location }} replace />
  );
};

export default UnProtectedRoutes