import "./ViewBusinessProfiles.scss";

import { Button, ConfigProvider, Modal, Space, Table, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useApi, IAPIConfig } from "../../../custom_hooks/useApi";

import CommonApis from "../../../custom_hooks/CommonApis";
import CreateFormPage from "../createform/CreateBusinessProfile";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import { SettingsOutlined } from "@mui/icons-material";
import { companyProfile } from "../../../store/companyProfileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";

type TransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  uniqueId: string;
};

const ViewBusinessProfiles: React.FC = () => {
  const [businessProfiles, setBusinessProfiles] = useState<TransformedObject[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstTime = useRef(true);
  const { GetBusinessProfiles } = CommonApis();
  const { sendPushNotifications } = useSendPushNotifications();
  const navigate = useNavigate();

  const showEditModal = (
    e: React.MouseEvent<HTMLButtonElement>,
    customer: TransformedObject,
    edit: boolean,
    update: boolean
  ) => {
    e.preventDefault();
    console.log("Customer: ", customer);
    console.log("Edit: ", edit, "Update: ", update);
    dispatch(companyProfile(customer));
    setIsModalVisible(true);
    setEditable(edit);
    setUpdate(update);
    console.log("Modal visibility: ", isModalVisible);
  };

  const { doApi } = useApi();

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(companyProfile({}));
    if (update) {
      firstTime.current = true;
    }
  };

  const getBusinessProfiles = async () => {
    setLoading(true);
    try {
      const res: any = await GetBusinessProfiles();
      if (res.length > 0) {
        setBusinessProfiles(res);
      } else {
        CustomToast("Failed to fetch customer profiles.", "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstTime.current) {
      getBusinessProfiles();
      firstTime.current = false;
    }
  });

  const handleDelete = async (key: string, name: string) => {
    try {
      const companyRemoveProfileConfig: IAPIConfig = {
        URL: `/Profile/RemoveBusinessProfile?uniqueId=${key}`,
        method: "POST",
        payLoad: {},
      };
      const res: any = await doApi(companyRemoveProfileConfig, "seedcore");
      if (res.status === 200) {
        firstTime.current = true;
        sendPushNotifications({
          title: name,
          body: `Business Profile deleted successfully`,
        });
        getBusinessProfiles();
      } else {
        CustomToast("Failed to delete business profile.", "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Website", dataIndex: "website", key: "website" },
    {
      title: "Products & Services",
      dataIndex: "productsServices",
      key: "productsServices",
      render: (productsServices: string[]) => (
        <span>{productsServices.join(", ")}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: TransformedObject) => (
        <Space size="middle" className="action-buttons">
          <Tooltip title="View Profile">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, true, false)
              }
            />
          </Tooltip>
          <Tooltip title="Edit Profile">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, false, true)
              }
            />
          </Tooltip>
          <Tooltip title="Delete Profile">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.uniqueId, record.name)}
            />
          </Tooltip>
          <Tooltip title="Login">
            <Button
              type="primary"
              icon={<UserOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                const data = { uniqueId: record.uniqueId, name: record.name };
                navigate("/business/business-login", { state: data });
              }}
            />
          </Tooltip>
          <Tooltip title="Settings">
            <Button
              type="primary"
              icon={<SettingsOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                console.log("Settings")
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const theme = {
    token: {
      colorPrimary: "#003366",
    },
  };

  return (
    <Loader spinning={loading}>
      <div className="view-page">
        <h2>Business Profiles</h2>
        <div className="ant-table-wrapper">
          <Table
            columns={columns}
            dataSource={businessProfiles.map((business) => ({
              ...business,
              key: business.uniqueId,
            }))}
            pagination={{ pageSize: 5 }}
          />
        </div>
        <ConfigProvider theme={theme}>
          <div className="edit-customer">
            <Modal
              title={update ? "Edit Customer" : "View Customer"}
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CreateFormPage update={update} editable={editable} />
              </div>
            </Modal>
          </div>
        </ConfigProvider>
      </div>
    </Loader>
  );
};

export default ViewBusinessProfiles;
