import './StatsCards.scss';

import { Box, Card, CardContent, Typography } from '@mui/material';

import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';

interface StatsCardProps {
  title: string;
  description: any;
  warning: boolean
}

const StatsCard2: React.FC<StatsCardProps> = ({ title, description, warning }) => {
  
  return (
    <Card className="stats-card">
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }} >
            {
             warning ?
             <Box  sx={{ display: 'flex', alignItems: 'center',gap: 0.5, color: 'red' }}>
                <WarningIcon sx={{ fontSize: 16, marginTop: '-15px' }}  />
                <Typography variant="h5" sx={{ fontSize: 16 }} > {title}</Typography>
             </Box>
             :
             <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'green' }}>
             <ThumbUpIcon sx={{ fontSize: 16, marginTop: '-15px' }} />
             <Typography variant="h5" sx={{ fontSize: 16 }} >
               {title}
             </Typography>
           </Box>
           
            }
        </Box>
        
        {description}
      </CardContent>
    </Card>
  );
};

export default StatsCard2;
