/* eslint-disable react-hooks/exhaustive-deps */

import "./GoogleAnalaytics.scss";

import React, { useEffect, useRef, useState } from "react";
import  {useApi, IAPIConfig } from "../../custom_hooks/useApi";

import AnalyticsChart from "../../components/charts/AnalyticsChart";
import CustomToast from "../../utils/cutomToast/CustomToast";
import Loader from "../../utils/loader/Loader";
import { Select } from "antd";
import { useSelector } from "react-redux";

const { Option } = Select;

interface BusinessProfile {
  url: string;
  stats: [
    {
      curDate: string;
      preDate: string;
      curData: {
        Users: string;
        "Key events": string;
        "New users": string;
        Sessions: string;
      };
      preData: {
        Users: string;
        "Key events": string;
        "New users": string;
        Sessions: string;
      };
    }
  ];
}

interface UserEntityType {
  UniqueId: string;
}

interface RootState {
  userEntityDetails: {
    userEntityType: UserEntityType;
  };
}

const GoogleAnalytics: React.FC = () => {
  const { doApi } = useApi();
  const firstTimeForGA4Sites = useRef(true);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<string>("7 days");
  const [businessProfiles, setBusinessProfiles] = useState<BusinessProfile[]>(
    []
  );
  const [values, SetValues] = useState([]);

  const { userEntityType } = useSelector(
    (state: RootState) => state.userEntityDetails
  );

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    firstTimeForGA4Sites.current = true;
  };

  const fetchAllSitesGA4Analytics = async (
    uniqueId: string,
    dateRange: string
  ) => {
    setLoading(true);
    const input = {
      origin: "ui",
      uniqueId,
      period: dateRange,
      dimensions: [{ name: "date" }],
      metrics: [
        {
          name: "Users",
        },
        {
          name: "Key events",
        },
        {
          name: "New users",
        },
        {
          name: "Sessions",
        },
      ],
    };
    const fetchAllSitesConfig: IAPIConfig = {
      URL: `/GA4/FetchGA4AllSitesData`,
      method: "POST",
      payLoad: input,
    };
    const res: any = await doApi(fetchAllSitesConfig, "seedcore");
    if (res.status === 200) {
      SetValues(JSON.parse(res.data.items));
      const rawData = res.data.data;
      const parsedData = parseData(rawData);
      setBusinessProfiles(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
    setLoading(false);
  };

  const parseData = (data: any[]): BusinessProfile[] => {
    return data.map((item) => {
      const stats = JSON.parse(item.stats);
      return {
        url: item.url,
        stats,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
  }, [loading]);

  useEffect(() => {
    if (userEntityType.UniqueId && firstTimeForGA4Sites.current) {
      fetchAllSitesGA4Analytics(userEntityType.UniqueId, dateRange);
      firstTimeForGA4Sites.current = false;
    }
  }, [dateRange, userEntityType.UniqueId]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="date-ga4">
        <Select
          style={{ width: 200, marginBottom: 16 }}
          value={dateRange}
          onChange={handleDateRangeChange}
        >
          <Option value="7 days">7 days</Option>
          <Option value="14 days">14 days</Option>
          <Option value="28 days">28 days</Option>
          <Option value="3 months">3 months</Option>
          <Option value="6 months">6 months</Option>
          <Option value="8 months">8 months</Option>
          <Option value="12 months">12 months</Option>
          <Option value="16 months">16 months</Option>
        </Select>
      </div>
      <Loader spinning={loading}>
        <div className="charts-container-ga4">
          {businessProfiles.map((profile, index) => (
            <div key={index}>
              <AnalyticsChart
                name={profile.url}
                data={profile.stats}
                date={dateRange}
                items={values}
              />
            </div>
          ))}
        </div>
      </Loader>
    </div>
  );
};

export default GoogleAnalytics;
