import "./ModalPopUpReNew.scss";

import { Box, Button, IconButton, Modal, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface CommonModalReNewProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message1: string;
  message2: string;
  message3: string;
  message4: string;
  reNewLabel: string;
  exitLabel?: string;
  onReNewAction: () => void;
  onExit?: () => void;
}

const ModalPopUpReNew: React.FC<CommonModalReNewProps> = ({
  open,
  onClose,
  title,
  message1,
  message2,
  message3,
  message4,
  reNewLabel,
  exitLabel,
  onReNewAction,
  onExit,
}) => {
  return (
    <Modal open={open} onClose={onClose} className="modal-wrapper">
      <Box className="modal-renew">
        <Box className="modal-renew-header">
          <Typography variant="h6">{title}</Typography>
          <IconButton className="close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="modal-renew-content">
          <Typography variant="body1" className="text">
            {message1}
          </Typography>
          <Typography variant="subtitle1" className="text">
            {message2}
          </Typography>
          <Typography variant="subtitle1" className="text">
            {message3}
          </Typography>
          <Typography variant="subtitle1" className="text">
            {message4}
          </Typography>
        </Box>

        <Box className="modal-renew-actions">
          {exitLabel && onExit && (
            <Button
              onClick={onExit}
              color="primary"
              size="large"
              variant="outlined"
            >
              {exitLabel}
            </Button>
          )}
          <Button
            onClick={onReNewAction}
            color="success"
            variant="contained"
            size="large"
          >
            {reNewLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPopUpReNew;
