import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchConsoleData: {},
  searchConsoleDate: {},
  searchConsolePage: {},
  searchConsoleQuery: {},
  searchConsoleCards: {},
  searchConsoleTrendData: {},
  searchConsoleContentGroups: {},
  searchConsoleQueryClusters: {},
  landingPageData: {},
  localPresence: {},
  winnableData: {},
  loading: false,
  error: null,
};

const businessSlice = createSlice({
  name: "businessProfiles",
  initialState,
  reducers: {
    
    fetchSearchAnalytics(state, action) {
      console.log("fetchSearchAnalytics", action.payload);
    
      // Map payload into state properties
      state.searchConsoleData = action.payload;
      state.searchConsoleDate = action.payload.date;
      state.searchConsolePage = action.payload.page;
      state.searchConsoleQuery = action.payload.query;
      state.searchConsoleCards = action.payload.cards;
      state.searchConsoleTrendData = action.payload.trendData;
      state.searchConsoleQueryClusters = action.payload.queryclusters;
      state.searchConsoleContentGroups = action.payload.contentgroups
      state.loading = false;
    },
    
      

    fetchGA4Data(state, action) {
      console.log("fetchGA4Data", action.payload);
      state.landingPageData = action.payload;
      state.loading = false;
    },
    fetchLocalPresence(state, action) {
      console.log("fetchLocalPresence", action.payload);
      state.localPresence = action.payload;
      state.loading = false;
    },
    fetchWinnableData(state, action) {
      console.log("fetchLocalPresence", action.payload);
      state.winnableData = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchSearchAnalytics, fetchGA4Data, fetchLocalPresence, fetchWinnableData } =
  businessSlice.actions;
export const businessDashboardReducer = businessSlice.reducer;
