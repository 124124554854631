/* eslint-disable react-hooks/exhaustive-deps */

import "./DataDisplay.scss";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownloadOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import { Button, Modal, Spin, Table, Tooltip, Typography } from "antd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import React, { useEffect, useMemo, useState } from "react";

import { saveAs } from "file-saver";

const { Title } = Typography;

interface DataType {
  keys: string[];
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
  Group: string;
  stats1: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats2: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats3: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
  stats4: {
    Item: string;
    Tag: string;
    Trend: string;
    Previous: string;
    Percentage: string;
    Diff: string;
  };
}

interface DataDisplayProps {
  data: DataType[];
  title: string;
  showClicks: boolean;
  showImpressions: boolean;
  showCTR: boolean;
  showPosition: boolean;
  url: string;
}

const DataDisplay: React.FC<DataDisplayProps> = ({
  data,
  title,
  showClicks,
  showImpressions,
  showCTR,
  showPosition,
  url,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeLink, setActiveLink] = useState("Popular");
  const [columnsReady, setColumnsReady] = useState(false);
  const [loading, setLoading] = useState(true);

  
  const extractDomainName = (url: string) => {
   if(url !== undefined){
    try {
      const { hostname } = new URL(url);
      const domain = hostname.replace(/^www\./, "");
      const domainName = domain;
      return domainName;
    } catch (error) {
      console.error("Invalid URL:", url);
      return url;
    }
   }
  };
  const finalUrl = extractDomainName(url);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [loading]);

  useEffect(() => {
    if (data.length > 0) {
      setColumnsReady(true);
    }
  }, [data]);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };

  const exportCSV = (data: DataType[], filename: string) => {
    const csvRows = [
      [`${title}`, "Clicks", "Impressions", "CTR", "Position"],
      ...data.map((row) => [
        row.keys.join(", "),
        row.clicks,
        row.impressions,
        (row.ctr).toFixed(1) + "%",
        row.position.toFixed(1),
      ]),
    ];
    const csvContent = csvRows.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${finalUrl}_${title.toLowerCase()}_${activeLink.toLowerCase()}.csv`);
  };

  const formatToK = (val: number) => {
    return val >= 1000 ? `${(val / 1000).toFixed(1)}k` : val.toString();
  };

  const renderTrend = (trend: string, percentage: string) => {
    if (!percentage) {
      return null;
    }
    const isUp = trend === "up";
    const color = isUp ? "green" : "red";
    const Icon = isUp ? ArrowUpOutlined : ArrowDownOutlined;
    return (
      <span style={{ color }}>
        <Icon /> {percentage}
      </span>
    );
  };

  const filterData = (data: DataType[]) => {
    if (activeLink === "Popular") {
      return data;
    } else if (activeLink === "Growing") {
      return data.filter((item) => item.Group === activeLink);
    } else {
      return data.filter((item) => item.Group === activeLink);
    }
  };

  // Memoize columns
  const columns = useMemo(
    () => [
      {
        title: (
          <span style={{ color: "black" }}>
            {title}
            <span style={{ marginLeft: "20px" }}>
              <span
                onClick={() => handleLinkClick("Popular")}
                style={{ color: activeLink === "Popular" ? "#000" : "#999" }}
              >
                Popular
              </span>
              {" | "}
              <span
                onClick={() => handleLinkClick("Growing")}
                style={{ color: activeLink === "Growing" ? "#000" : "#999" }}
              >
                Growing
              </span>
              {" | "}
              <span
                onClick={() => handleLinkClick("Decaying")}
                style={{ color: activeLink === "Decaying" ? "#000" : "#999" }}
              >
                Decaying
              </span>
            </span>
          </span>
        ),
        dataIndex: "keys",
        key: "keys",
        render: (keys: string[]) => keys.join(", "),
      },
      ...(showClicks
        ? [
            {
              title: <span style={{ color: "#3b83f6" }}>Clicks</span>,
              dataIndex: "clicks",
              key: "clicks",
              render: (clicks: number, record: DataType) => {
                const stats = record.stats1 || {
                  Previous: 0,
                  Diff: 0,
                  Trend: "neutral",
                  Percentage: 0,
                };
                return (
                  <span style={{ color: "#3b83f6" }}>
                    {clicks}{" "}
                    <Tooltip
                      title={
                        <>
                          {stats.Previous} previous period
                          <br />
                          {stats.Trend === "up"
                            ? `+${stats.Diff}`
                            : `${stats.Diff}`}{" "}
                          clicks
                        </>
                      }
                    >
                      {renderTrend(stats.Trend, stats.Percentage)}
                    </Tooltip>
                  </span>
                );
              },
            },
          ]
        : []),
      ...(showImpressions
        ? [
            {
              title: <span style={{ color: "#5e35b1" }}>Impressions</span>,
              dataIndex: "impressions",
              key: "impressions",
              render: (impressions: number, record: DataType) => {
                const stats = record.stats2 || {
                  Previous: 0,
                  Diff: 0,
                  Trend: "neutral",
                  Percentage: 0,
                };
                return (
                  <span style={{ color: "#5e35b1" }}>
                    {formatToK(impressions)}{" "}
                    <Tooltip
                      title={
                        <>
                          {stats.Previous} previous period
                          <br />
                          {stats.Trend === "up"
                            ? `+${stats.Diff}`
                            : `${stats.Diff}`}{" "}
                          impressions
                        </>
                      }
                    >
                      {renderTrend(stats.Trend, stats.Percentage)}
                    </Tooltip>
                  </span>
                );
              },
            },
          ]
        : []),
      ...(showCTR
        ? [
            {
              title: <span style={{ color: "#008a7c" }}>CTR</span>,
              dataIndex: "ctr",
              key: "ctr",
              render: (ctr: number, record: DataType) => {
                const stats = record.stats3 || {
                  Previous: 0,
                  Diff: 0,
                  Trend: "neutral",
                  Percentage: 0,
                };
                return (
                  <span style={{ color: "#008a7c" }}>
                    {ctr + "%"}{" "}
                    <Tooltip
                      title={
                        <>
                          {(parseFloat(stats.Previous) * 100).toFixed(0)}%
                          previous period
                          <br />
                          {stats.Trend === "up"
                            ? `+${(parseFloat(stats.Diff) * 100).toFixed(1)}`
                            : `${(parseFloat(stats.Diff) * 100).toFixed(
                                1
                              )}`}{" "}
                          CTR
                        </>
                      }
                    >
                      {renderTrend(stats.Trend, stats.Percentage)}
                    </Tooltip>
                  </span>
                );
              },
            },
          ]
        : []),
      ...(showPosition
        ? [
            {
              title: <span style={{ color: "#e8710a" }}>Position</span>,
              dataIndex: "position",
              key: "position",
              render: (position: number, record: DataType) => {
                const stats = record.stats4 || {
                  Previous: 0,
                  Diff: 0,
                  Trend: "neutral",
                  Percentage: 0,
                };
                return (
                  <span style={{ color: "#e8710a" }}>
                    {position}{" "}
                    <Tooltip
                      title={
                        <>
                          {parseFloat(stats.Previous).toFixed(1)} previous
                          period
                          <br />
                          {stats.Trend === "up"
                            ? `+${stats.Diff}`
                            : `${stats.Diff}`}{" "}
                          position
                        </>
                      }
                    >
                      {renderTrend(stats.Trend, stats.Percentage)}
                    </Tooltip>
                  </span>
                );
              },
            },
          ]
        : []),
    ],
    [activeLink, title, showClicks, showImpressions, showCTR, showPosition]
  );

  const columnsExpand = useMemo(
    () => [
      {
        title: (
          <span style={{ color: "black" }}>
            {title}
            <span style={{ marginLeft: "20px" }}>
              <span
                onClick={() => handleLinkClick("Popular")}
                style={{ color: activeLink === "Popular" ? "#000" : "#999" }}
              >
                Popular
              </span>
              {" | "}
              <span
                onClick={() => handleLinkClick("Growing")}
                style={{ color: activeLink === "Growing" ? "#000" : "#999" }}
              >
                Growing
              </span>
              {" | "}
              <span
                onClick={() => handleLinkClick("Decaying")}
                style={{ color: activeLink === "Decaying" ? "#000" : "#999" }}
              >
                Decaying
              </span>
            </span>
          </span>
        ),
        dataIndex: "keys",
        key: "keys",
        render: (keys: string[]) => keys.join(", "),
      },
      {
        title: <span style={{ color: "#3b83f6" }}>Clicks</span>,
        dataIndex: "clicks",
        key: "clicks",
        render: (clicks: number, record: DataType) => {
          const stats = record.stats1 || {
            Previous: 0,
            Diff: 0,
            Trend: "neutral",
            Percentage: 0,
          };
          return (
            <span style={{ color: "#3b83f6" }}>
              {clicks}{" "}
              <Tooltip
                title={
                  <>
                    {stats.Previous} previous period
                    <br />
                    {stats.Trend === "up"
                      ? `+${stats.Diff}`
                      : `${stats.Diff}`}{" "}
                    clicks
                  </>
                }
              >
                {renderTrend(stats.Trend, stats.Percentage)}
              </Tooltip>
            </span>
          );
        },
      },

      {
        title: <span style={{ color: "#5e35b1" }}>Impressions</span>,
        dataIndex: "impressions",
        key: "impressions",
        render: (impressions: number, record: DataType) => {
          const stats = record.stats2 || {
            Previous: 0,
            Diff: 0,
            Trend: "neutral",
            Percentage: 0,
          };
          return (
            <span style={{ color: "#5e35b1" }}>
              {formatToK(impressions)}{" "}
              <Tooltip
                title={
                  <>
                    {stats.Previous} previous period
                    <br />
                    {stats.Trend === "up"
                      ? `+${stats.Diff}`
                      : `${stats.Diff}`}{" "}
                    impressions
                  </>
                }
              >
                {renderTrend(stats.Trend, stats.Percentage)}
              </Tooltip>
            </span>
          );
        },
      },

      {
        title: <span style={{ color: "#008a7c" }}>CTR</span>,
        dataIndex: "ctr",
        key: "ctr",
        render: (ctr: number, record: DataType) => {
          const stats = record.stats3 || {
            Previous: 0,
            Diff: 0,
            Trend: "neutral",
            Percentage: 0,
          };
          return (
            <span style={{ color: "#008a7c" }}>
              {ctr + "%"}{" "}
              <Tooltip
                title={
                  <>
                    {(parseFloat(stats.Previous) * 100).toFixed(0)}% previous
                    period
                    <br />
                    {stats.Trend === "up"
                      ? `+${(parseFloat(stats.Diff) * 100).toFixed(1)}`
                      : `${(parseFloat(stats.Diff) * 100).toFixed(1)}`}{" "}
                    CTR
                  </>
                }
              >
                {renderTrend(stats.Trend, stats.Percentage)}
              </Tooltip>
            </span>
          );
        },
      },

      {
        title: <span style={{ color: "#e8710a" }}>Position</span>,
        dataIndex: "position",
        key: "position",
        render: (position: number, record: DataType) => {
          const stats = record.stats4 || {
            Previous: 0,
            Diff: 0,
            Trend: "neutral",
            Percentage: 0,
          };
          return (
            <span style={{ color: "#e8710a" }}>
              {position}{" "}
              <Tooltip
                title={
                  <>
                    {parseFloat(stats.Previous).toFixed(1)} previous period
                    <br />
                    {stats.Trend === "up"
                      ? `+${stats.Diff}`
                      : `${stats.Diff}`}{" "}
                    position
                  </>
                }
              >
                {renderTrend(stats.Trend, stats.Percentage)}
              </Tooltip>
            </span>
          );
        },
      },
    ],
    [activeLink, title]
  );

  const filteredData = useMemo(
    () =>
      filterData(data).map((item, index) => ({
        ...item,
        key: item.keys.join("-") || index,
      })),
    [data, activeLink]
  );

  const showModal = () => {
    setLoading(true);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <Title level={4}>{title}</Title>
      <TransitionGroup>
        <CSSTransition key={activeLink} classNames="fade" timeout={300}>
          {columnsReady ? (
            <Table
              columns={columns}
              key="key"
              dataSource={filteredData.slice(0, 10)}
              pagination={false}
              onChange={() => setColumnsReady(true)}
            />
          ) : (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
      <div style={{ margin: "10px 24px 0px 0px", float: "right" }}>
        <Button type="primary" icon={<ExpandOutlined />} onClick={showModal} />
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => exportCSV(filteredData, "data")}
          style={{ marginLeft: 16 }}
        />
      </div>

      <Modal
        title={title}
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        width={800}
        style={{ maxHeight: 600, overflowY: "scroll" }}
      >
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columnsExpand}
            dataSource={filteredData}
            key="key"
            pagination={false}
            onChange={() => setColumnsReady(true)}
            scroll={{ y: 600 }}
          />
        )}
      </Modal>
    </div>
  );
};

export default DataDisplay;
