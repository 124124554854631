import './ErrorPage.scss'

import { Link } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'

const UserErrorPage = () => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);
  return (
    <div>
      <div className='error-container'>
        <h2>404 Page Not Found</h2>
        <h5>Oh no! Seems like you tried accessing the page that doesn't exists or in under construction</h5>
        <p>Go back <Link to={isAuthenticated ? "/sigin-google" : "/"}>{isAuthenticated ? "Home" : "Login"}</Link></p>
      </div>
    </div>
  )
}

export default UserErrorPage