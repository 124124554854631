import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import './SMSPreviewDrawer.scss';

type DrawerComponentProps = {open: boolean, toggleDrawer: any};


const SMSPreviewDrawer: React.FC <DrawerComponentProps>  =({open, toggleDrawer}) => {

 
  const DrawerList = (
    <Box sx={{minWidth: '304px', padding: "24px 32px", bgcolor: "#F6F8F8"}}  >
      <Stack direction="row" justifyContent="flex-end">
        <CloseIcon sx={{width: '24px', height: '24px', cursor: 'pointer', color: "#0F4343"}} onClick={toggleDrawer(false)} />
      </Stack>
      <Box className="sms-preview">
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>SMS 1</Typography>
        <Typography variant="subtitle1" >Preview</Typography>

        <Box className="phone-frame" sx={{marginTop: 1}}>
          <Typography variant='body2' className="sms-message">This is a default SMS message!</Typography>
        </Box>

      </Box>
      <Stack direction="row" justifyContent={"space-between"} sx={{marginTop: 3}}>
        <Stack direction="row" spacing={1}>
          <IconButton sx={{border: '1px solid rgba(51, 132, 132, 0.5)'}}  >
            <ArrowBackIcon sx={{width: '1.5rem', height: '1.5rem', color: "rgba(51, 132, 132, 0.5)"}}  />
          </IconButton>
          <IconButton sx={{border: '1px solid rgba(15, 67, 67, 1)'}}>
            <ArrowForwardIcon sx={{width: '1.5rem', height: '1.5rem', color: "rgba(15, 67, 67, 1)"}}/>
          </IconButton>
        </Stack>
        <Box>
          <IconButton sx={{border: '1px solid #0F4343'}} >
            <EditIcon sx={{width: '1.5rem', height: '1.5rem', color: "rgba(15, 67, 67, 1)"}} />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );

  return (
      <Drawer anchor="right" open={open} >
        {DrawerList}
      </Drawer>
  );
}


export default SMSPreviewDrawer;
