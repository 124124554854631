import "./BusinessModalPopUp.scss";

import {
  Box,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BusinessModalProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  children: React.ReactNode; // Allows any valid React node as children
  description?: string; // Add an optional description prop
  width?: string;
}

const BusinessModalPopUp: React.FC<BusinessModalProps> = ({ open, onClose, title, description, width, children })  => {

  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="modal-wrapper"
      aria-labelledby="business-method-modal"
      aria-describedby="business-method-modal-description"
    >
      <Box className="business-modal">
        
        <Box className="business-modal-header">
          <Typography id="business-method-modal" variant="h6" component="h2">
            {title}
             {/* Add description below the title */}
              {description && (
                <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 400, fontSize: '14px' }}>
                  {description}
                </Typography>
              )}
          </Typography>
          <IconButton onClick={()=>navigate("/home")}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="business-modal-line"></Box>
        <Box  style={{ width: `${ width ? width : 'auto'}`, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default BusinessModalPopUp;
