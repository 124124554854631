import React from 'react';
import { Box, Typography } from '@mui/material'


const BusinessCampaign = () => {

  return (
    <Box>  
      <Typography variant="h5">Business Campaign</Typography>  
    </Box>
  );
};

export default BusinessCampaign;
