import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const isAuthenticated = sessionStorage.getItem("auth") === "1" ? 1 : 0;
  const tokenFromStorage = sessionStorage.getItem("token");
  const token = tokenFromStorage ? tokenFromStorage.replaceAll('"', '') : null; 
  const userDetails = JSON.parse(sessionStorage.getItem("user")) || null;
  const storedData = sessionStorage.getItem("businessDetails");
  const businessData = storedData !== "undefined" ? JSON.parse(storedData) : null;
  return { isAuthenticated, token, userDetails, businessData };
};

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = 1;
      state.token = action.payload.token;
      state.userDetails = action.payload.userDetails;
      sessionStorage.setItem("auth", "1");
      sessionStorage.setItem("token", action.payload.token);
      sessionStorage.setItem("user", JSON.stringify(action.payload.userDetails));
      console.log(action.payload.businessData);
      sessionStorage.setItem("businessDetails", JSON.stringify(action.payload.businessData));
    },
    logout: (state) => {
      state.isAuthenticated = 0;
      state.token = null;
      state.userDetails = null;
      sessionStorage.setItem("auth", "0");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("businessDetails");
    }
  }
});

export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
