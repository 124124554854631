/* eslint-disable jsx-a11y/alt-text */

import * as React from 'react';

import { Button, Stack, TextField, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

type DrawerComponentProps = {open: boolean, toggleDrawer: any};

type TransformedObject = {
  Name: string;
  Link: string;
};


const DrawerComponent: React.FC <DrawerComponentProps>  =({open, toggleDrawer}) => {

  const [campaignOption, setCampaignOption] = React.useState('print');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    opt: string,
  ) => {
    setCampaignOption(opt);
  };

  const [campaignData, setcampaignData] = React.useState<TransformedObject>({
    Name: "",
    Link: ""
});
 
  const DrawerList = (
    <Box sx={{minWidth: '400px', padding: "24px 32px", bgcolor: "#F6F8F8"}}  >
      <Stack direction="row" justifyContent="flex-end">
        <CloseIcon sx={{width: '24px', height: '24px', cursor: 'pointer', color: "#0F4343"}} onClick={toggleDrawer(false)} />
      </Stack>
      <Typography sx={{marginTop: 2, marginBottom: 2}} variant='h6' >Email QR Code</Typography>
      <Box>
        <ToggleButtonGroup
          color="primary"
          value={campaignOption}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ height: "38px", color: "#0F4343", fontWeight: 'bold', fontSize: '13px'}}
        >
          <ToggleButton sx={{textTransform: 'capitalize'}} value="print">Print Ready</ToggleButton>
          <ToggleButton sx={{textTransform: 'capitalize'}} value="qr">Code Only</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{marginTop: 2, marginBottom: 2}} >
        {
        campaignOption ==="qr" ?
        <img src="/general/qr-lg.png" style={{width: '160px', height: '160px', marginTop: 4}} ></img>
        :
        <Stack direction="column" sx={{border: '2px solid rgba(0, 0, 0, 0.5)', borderStyle: 'dashed', padding: '3 4', width: '224px', height: '292px', bgcolor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}> 
          <Typography variant='subtitle2' >Scan this QR Code to<br/> leave a review!</Typography>
          <img src="/general/qr-lg.png" style={{width: '160px', height: '160px', marginTop: 4}} ></img>
        </Stack>
        }
      </Box>
      <Box>
        {
          campaignOption ==="print" &&
          <TextField
              label="Print Copy "
              variant="filled"
              fullWidth
              value={campaignData?.Link}
              onChange={(e) => setcampaignData({ ...campaignData, Link: e.target.value })}
              sx={{width: '100%',
                '& .MuiInputBase-root': {
                  maxHeight: '56px',
                }, }}
            />
        }
        <Box sx={{marginTop: 2, marginBottom: 2}} >
          <Typography variant='subtitle1' >Message</Typography>
          <TextField
            sx={{marginTop: 1}}
            label="Message"
            variant="filled"
            multiline
            rows={3}
            fullWidth
            value={campaignData?.Link}
            onChange={(e) => setcampaignData({ ...campaignData, Link: e.target.value })}
          />
        </Box>
        <Box sx={{marginTop: 2, marginBottom: 2}} >
          <Typography variant='subtitle1' >Recipients</Typography>
          <TextField
            sx={{marginTop: 1}}
            label="Client Email"
            variant="filled"
            fullWidth
            value={campaignData?.Link}
            onChange={(e) => setcampaignData({ ...campaignData, Link: e.target.value })}
          />
          <Button sx={{marginTop: 1}} variant="outlined" startIcon={<AddIcon/>}>Add</Button>
        </Box>

      </Box>
    </Box>
  );

  return (
      <Drawer anchor="right" open={open} >
        {DrawerList}
      </Drawer>
  );
}


export default DrawerComponent;
