/* eslint-disable react-hooks/exhaustive-deps */

import "./TableComponent2.scss";

import {
  Box,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomDialog from "../Dialog/CustomDialog";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Label from "../../components/Label";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

interface Metric {
  Value: string;
  Diff: string;
  Trend: "up" | "down";
}
interface DataType {
  group: string;
  landing_page: string;
  sessions: Metric;
  engagement: Metric;
  bounce: Metric;
  key_events: Metric;
  conversions: Metric;
}

type TableProps = {
  title: string;
  data: DataType[];
  colSpan: number;
  message?: string;
  linkText: string;
  onLinkClick?: () => void;
};

const TableComponent2: React.FC<TableProps> = ({
  title,
  data,
  colSpan,
  message = "",
  linkText,
  onLinkClick,
}) => {
  const [pageSite, setPageSite] = React.useState(0);
  const [rowsPerPageSite, setRowsPerPageSite] = React.useState(5);

  const handleChangePageSite = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSite(newPage);
  };
  const handleChangeRowsPerPageSite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSite(parseInt(event.target.value));
    setPageSite(0);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [activeLink, setActiveLink] = useState("Popular"); // Main component filter
  const [activeLinkDialog, setActiveLinkDialog] = useState("Popular"); // Dialog filter

  const [messageText, setMessageText] = useState(message);
  const [linkMessage, setLinkMessage] = useState(linkText);
  const [activeHeader, setActiveHeader] = useState<string | null>("sessions");

  const handleHeaderClick = (header: string) => {
    setActiveHeader(header);
  };

  const convertToNumber = (value: string) => {
    if (typeof value === "string") {
      if (value.toUpperCase().includes("K")) {
        return parseFloat(value.replace("K", "")) * 1000;
      } else if (value.toUpperCase().includes("M")) {
        return parseFloat(value.replace("M", "")) * 1000000;
      }
    }
    return parseFloat(value);
  };

  const filterData = (
    data: DataType[],
    activeFilter: string,
    activeHeader: string | null
  ) => {
    let filteredData = [];

    // Filter data based on activeFilter (activeLink)
    if (activeFilter === "Popular") {
      filteredData = data;
    } else if (activeFilter === "Growing") {
      setMessageText("");
      setLinkMessage("There are no growing to show, go to Popular.");
      filteredData = data.filter((item) => item.group === activeFilter);
    } else {
      setMessageText("");
      setLinkMessage("There are no decaying to show, go to Popular.");
      filteredData = data.filter((item) => item.group === activeFilter);
    }
    if (activeHeader) {
      filteredData = filteredData.sort((a: any, b: any) => {
        const valueA = convertToNumber(a[activeHeader].Value);
        const valueB = convertToNumber(b[activeHeader].Value);

        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
        return 0;
      });
    }

    return filteredData;
  };

  // UseMemo for filtered data
  const filteredData = useMemo(
    () => filterData(data || [], activeLink, activeHeader), // Pass activeHeader here
    [data, activeLink, activeHeader] // Depend on activeLink and activeHeader
  );

  // UseMemo for filteredDialogData
  const filteredDialogData = useMemo(
    () => filterData(data || [], activeLinkDialog, activeHeader), // Pass activeHeader here
    [data, activeLinkDialog, activeHeader] // Depend on activeLinkDialog and activeHeader
  );

  const handleLinkClick = (link: string, context: "main" | "dialog") => {
    if (context === "main") {
      setActiveLink(link);
    } else if (context === "dialog") {
      setActiveLinkDialog(link);
    }
  };

  return (
    <Box className="Custom-table-wrapper2">
      <TableContainer component={Paper} sx={{ padding: "0 16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: 1,
          }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => handleLinkClick("Popular", "main")}
              sx={{
                bgcolor: activeLink === "Popular" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <EmojiEventsIcon />
            </IconButton>
            <IconButton
              onClick={() => handleLinkClick("Growing", "main")}
              sx={{
                bgcolor: activeLink === "Growing" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <TrendingUpIcon />
            </IconButton>
            <IconButton
              onClick={() => handleLinkClick("Decaying", "main")}
              sx={{
                bgcolor: activeLink === "Decaying" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <TrendingDownIcon />
            </IconButton>
          </Stack>
        </Box>
        <Table>
          <TableHead sx={{ borderBottom: "1px solid #e0e0e0" }}>
            <TableRow>
              <TableCell sx={{ minWidth: "380px !important" }}></TableCell>
              <TableCell
                className="tableCellHeader"
                onClick={() => handleHeaderClick("sessions")}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeHeader === "sessions"
                      ? "4px solid #007bff !important"
                      : "4px solid transparent !important",
                  transition: "border-color 0.3s ease !important",
                }}
              >
                SESSIONS
              </TableCell>
              <TableCell
                className="tableCellHeader"
                onClick={() => handleHeaderClick("engagement")}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeHeader === "engagement"
                      ? "4px solid #007bff !important"
                      : "4px solid transparent !important",
                  transition: "border-color 0.3s ease !important",
                }}
              >
                ENGAGEMENT
              </TableCell>
              <TableCell
                className="tableCellHeader"
                onClick={() => handleHeaderClick("bounce")}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeHeader === "bounce"
                      ? "4px solid #007bff !important"
                      : "4px solid transparent !important",
                  transition: "border-color 0.3s ease !important",
                }}
              >
                BOUNCE
              </TableCell>
              <TableCell
                className="tableCellHeader"
                onClick={() => handleHeaderClick("key_events")}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeHeader === "key_events"
                      ? "4px solid #007bff !important"
                      : "4px solid transparent !important",
                  transition: "border-color 0.3s ease !important",
                }}
              >
                KEY EVENTS
              </TableCell>
              <TableCell
                className="tableCellHeader"
                onClick={() => handleHeaderClick("conversions")}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeHeader === "conversions"
                      ? "4px solid #007bff !important"
                      : "4px solid transparent !important",
                  transition: "border-color 0.3s ease !important",
                }}
              >
                CONVERSION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.slice(0, 10).map((row, index) => (
                <TableRow key={index} sx={{ height: "40px" }}>
                  {/* Landing Page */}
                  <TableCell className="ellipsisText" sx={{ padding: 0 }}>
                    {row.landing_page}
                  </TableCell>

                  {/* Sessions */}
                  <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                    <div>{row.sessions?.Value}</div>
                    {row.sessions?.Diff !== undefined && (
                      <Label
                        increase={row.sessions?.Trend === "up"}
                        label={row.sessions?.Diff}
                        color={
                          row.sessions?.Trend === "up" ? "success" : "error"
                        }
                        size="12px"
                        iconsize="12px"
                      />
                    )}
                  </TableCell>

                  {/* Engagement Rate */}
                  <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                    <div>{row.engagement?.Value}</div>
                    {row.engagement?.Diff !== undefined && (
                      <Label
                        increase={row.engagement?.Trend === "up"}
                        label={row.engagement?.Diff}
                        color={
                          row.engagement?.Trend === "up" ? "success" : "error"
                        }
                        size="12px"
                        iconsize="12px"
                      />
                    )}
                  </TableCell>

                  {/* Bounce Rate */}
                  <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                    <div>{row.bounce?.Value}</div>
                    {row.bounce?.Diff !== undefined && (
                      <Label
                        increase={row.bounce?.Trend === "up"}
                        label={row.bounce?.Diff}
                        color={row.bounce?.Trend === "up" ? "success" : "error"}
                        size="12px"
                        iconsize="12px"
                      />
                    )}
                  </TableCell>

                  {/* Key Events */}
                  <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                    <div>{row.key_events?.Value}</div>
                    {row.key_events?.Diff !== undefined && (
                      <Label
                        increase={row.key_events?.Trend === "up"}
                        label={row.key_events?.Diff}
                        color={
                          row.key_events?.Trend === "up" ? "success" : "error"
                        }
                        size="12px"
                        iconsize="12px"
                      />
                    )}
                  </TableCell>

                  {/* Conversions */}
                  <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                    <div>{row.conversions?.Value}</div>
                    {row.conversions?.Diff !== undefined && (
                      <Label
                        increase={row.conversions?.Trend === "up"}
                        label={row.conversions?.Diff}
                        color={
                          row.conversions?.Trend === "up" ? "success" : "error"
                        }
                        size="12px"
                        iconsize="12px"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "0.8rem",
                    }}
                  >
                    {messageText}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    <span>
                      {messageText && (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={onLinkClick}
                          sx={{
                            color: "blue",
                            textDecoration: "none",
                          }}
                        >
                          Define
                        </Link>
                      )}{" "}
                      {linkMessage}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {data && data.length > 0 && (
            <IconButton onClick={() => setOpen(true)}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
      </TableContainer>

      <CustomDialog
        open={open}
        onClose={handleClose}
        width={"100%"}
        backgroundDropColor={true}
      >
        <Box className="Custom-table-wrapper2">
          <TableContainer
            sx={{ padding: "0 16px", width: "1056px", maxWidth: "1056px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "-4px",
                marginTop: "1rem",
              }}
            >
              <Typography variant="subtitle1">{title}</Typography>
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => handleLinkClick("Popular", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Popular"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <EmojiEventsIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleLinkClick("Growing", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Growing"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <TrendingUpIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleLinkClick("Decaying", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Decaying"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <TrendingDownIcon />
                </IconButton>
              </Stack>
            </Box>
            <Table>
              <TableHead sx={{ borderBottom: "1px solid #e0e0e0" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "380px !important" }}></TableCell>
                  <TableCell
                    className="tableCellHeader"
                    onClick={() => handleHeaderClick("sessions")}
                    sx={{
                      cursor: "pointer",
                      borderBottom:
                        activeHeader === "sessions"
                          ? "4px solid #007bff !important"
                          : "4px solid transparent !important",
                      transition: "border-color 0.3s ease !important",
                    }}
                  >
                    SESSIONS
                  </TableCell>
                  <TableCell
                    className="tableCellHeader"
                    onClick={() => handleHeaderClick("engagement")}
                    sx={{
                      cursor: "pointer",
                      borderBottom:
                        activeHeader === "engagement"
                          ? "4px solid #007bff !important"
                          : "4px solid transparent !important",
                      transition: "border-color 0.3s ease !important",
                    }}
                  >
                    ENGAGEMENT
                  </TableCell>
                  <TableCell
                    className="tableCellHeader"
                    onClick={() => handleHeaderClick("bounce")}
                    sx={{
                      cursor: "pointer",
                      borderBottom:
                        activeHeader === "bounce"
                          ? "4px solid #007bff !important"
                          : "4px solid transparent !important",
                      transition: "border-color 0.3s ease !important",
                    }}
                  >
                    BOUNCE
                  </TableCell>
                  <TableCell
                    className="tableCellHeader"
                    onClick={() => handleHeaderClick("key_events")}
                    sx={{
                      cursor: "pointer",
                      borderBottom:
                        activeHeader === "key_events"
                          ? "4px solid #007bff !important"
                          : "4px solid transparent !important",
                      transition: "border-color 0.3s ease !important",
                    }}
                  >
                    KEY EVENTS
                  </TableCell>
                  <TableCell
                    className="tableCellHeader"
                    onClick={() => handleHeaderClick("conversions")}
                    sx={{
                      cursor: "pointer",
                      borderBottom:
                        activeHeader === "conversions"
                          ? "4px solid #007bff !important"
                          : "4px solid transparent !important",
                      transition: "border-color 0.3s ease !important",
                    }}
                  >
                    CONVERSION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDialogData && filteredDialogData.length > 0 ? (
                  filteredDialogData
                    ?.slice(
                      pageSite * rowsPerPageSite,
                      pageSite * rowsPerPageSite + rowsPerPageSite
                    )
                    .map((row, index) => (
                      <TableRow key={index} sx={{ height: "40px" }}>
                        {/* Landing Page */}
                        <TableCell className="ellipsisText" sx={{ padding: 0 }}>
                          {row.landing_page}
                        </TableCell>

                        {/* Sessions */}
                        <TableCell
                          sx={{ maxWidth: "65px", textAlign: "center" }}
                        >
                          <div>{row.sessions?.Value}</div>
                          {row.sessions?.Diff !== undefined && (
                            <Label
                              increase={row.sessions?.Trend === "up"}
                              label={row.sessions?.Diff}
                              color={
                                row.sessions?.Trend === "up"
                                  ? "success"
                                  : "error"
                              }
                              size="12px"
                              iconsize="12px"
                            />
                          )}
                        </TableCell>

                        {/* Engagement Rate */}
                        <TableCell
                          sx={{ maxWidth: "65px", textAlign: "center" }}
                        >
                          <div>{row.engagement?.Value}</div>
                          {row.engagement?.Diff !== undefined && (
                            <Label
                              increase={row.engagement?.Trend === "up"}
                              label={row.engagement?.Diff}
                              color={
                                row.engagement?.Trend === "up"
                                  ? "success"
                                  : "error"
                              }
                              size="12px"
                              iconsize="12px"
                            />
                          )}
                        </TableCell>

                        {/* Bounce Rate */}
                        <TableCell
                          sx={{ maxWidth: "65px", textAlign: "center" }}
                        >
                          <div>{row.bounce?.Value}</div>
                          {row.bounce?.Diff !== undefined && (
                            <Label
                              increase={row.bounce?.Trend === "up"}
                              label={row.bounce?.Diff}
                              color={
                                row.bounce?.Trend === "up" ? "success" : "error"
                              }
                              size="12px"
                              iconsize="12px"
                            />
                          )}
                        </TableCell>

                        {/* Key Events */}
                        <TableCell
                          sx={{ maxWidth: "65px", textAlign: "center" }}
                        >
                          <div>{row.key_events?.Value}</div>
                          {row.key_events?.Diff !== undefined && (
                            <Label
                              increase={row.key_events?.Trend === "up"}
                              label={row.key_events?.Diff}
                              color={
                                row.key_events?.Trend === "up"
                                  ? "success"
                                  : "error"
                              }
                              size="12px"
                              iconsize="12px"
                            />
                          )}
                        </TableCell>

                        {/* Conversions */}
                        <TableCell
                          sx={{ maxWidth: "65px", textAlign: "center" }}
                        >
                          <div>{row.conversions?.Value}</div>
                          {row.conversions?.Diff !== undefined && (
                            <Label
                              increase={row.conversions?.Trend === "up"}
                              label={row.conversions?.Diff}
                              color={
                                row.conversions?.Trend === "up"
                                  ? "success"
                                  : "error"
                              }
                              size="12px"
                              iconsize="12px"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          marginBottom: "0.8rem",
                        }}
                      >
                        {messageText}
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "center" }}>
                        <span>
                          {messageText && (
                            <Link
                              component="button"
                              variant="body1"
                              onClick={onLinkClick}
                              sx={{
                                color: "blue",
                                textDecoration: "none",
                              }}
                            >
                              Define
                            </Link>
                          )}{" "}
                          {linkMessage}
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={data ? data?.length : 0}
                  rowsPerPage={rowsPerPageSite}
                  page={pageSite}
                  onPageChange={handleChangePageSite}
                  onRowsPerPageChange={handleChangeRowsPerPageSite}
                  sx={{
                    color: "black", // Text color
                    "& .MuiTablePagination-selectIcon": {
                      color: "black", // Dropdown arrow near rows per page
                    },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                          backdropFilter: "blur(18px)", // Blurred background for the dropdown
                          "& .MuiMenuItem-root": {
                            color: "black", // Text color for options
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                          },
                          "& .Mui-selected": {
                            backgroundColor:
                              "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </TableContainer>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default TableComponent2;
