/* eslint-disable react-hooks/exhaustive-deps */

import "./BusinessDashboard.scss";

import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomAreaChartComponent from "../../components/CustomCharts/AreaCharts/CustomAreaCharts";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import LazyLoad from "react-lazyload";
import Loader from "../../utils/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StatsCard from "./StatsCards";
import StatsCard2 from "./StatsCards2";
import StatsCardWithStars from "./StatsCardWithStars";
import TableComponent from "./TableComponent";
import TableComponent2 from "../../components/Tables/TableComponent2";
import TableComponent3 from "./TableComponent3";
import WebsitePerformance from "./WebsitePerformance";
import dayjs from "dayjs";
import { useBusinessDashboardApis } from "../../custom_hooks/useBusinessDashboardApis";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface Condition {
  condition: string;
  value: string;
}

interface TopicCluster {
  name: string;
  conditions: Condition[];
}

interface ContentGroup {
  name: string;
  conditions: Condition[];
}

interface BusinessProfileSettings {
  uniqueId: string;
  userUniqueId: string;
  brandedKeywords: string[];
  topicClusters: TopicCluster[];
  contentGroups: ContentGroup[];
}

// Define the interface for the card data
interface CardData {
  branded: {
    Percentage: string;
    Diff: string;
    Trend: "up" | "down";
  };
  intop10: {
    Percentage: string;
    Diff: string;
    Trend: "up" | "down";
  };
}

// Interface to define the structure of trend data
interface TrendDetail {
  Total: string; // The Total is stored as a string (e.g., "403")
  Diff: string; // The difference percentage as a string (e.g., "-25.00%")
  Trend: string; // The trend direction as a string (e.g., "down")
}

interface TrendData {
  [key: string]: TrendDetail;
}

const BusinessDashboard = () => {
  const {
    FetchSearchAnalytics,
    FetchGA4Data,
    FetchLocalPresence,
    FetchWinnableData,
  } = useBusinessDashboardApis();
  const {
    searchConsoleDate,
    searchConsolePage,
    searchConsoleQuery,
    searchConsoleCards,
    searchConsoleTrendData,
    searchConsoleQueryClusters,
    searchConsoleContentGroups,
    landingPageData,
    localPresence,
    winnableData,
  } = useSelector((state: any) => state.businessdashboardDetails);

  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const [ChartData, setChartData] = useState<
    { name: string; data: number[] }[]
  >([]);
  const [ChartKey, setChartKey] = useState<string[]>([]);
  const [cards, setCards] = useState<CardData | null>(null);
  const [queriesData, setQueriesData] = useState<any[]>([]);
  const [contentData, setContentData] = useState<any[]>([]);
  const [queryClustersData, setQueryClustersData] = useState<any[]>([]);
  const [queryContentGroupsData, setContentGroupsData] = useState<any[]>([]);
  const [landingPageDataAll, setLandingPageDataAll] = useState<any[]>([]);
  const winnableTab = useRef(false);
  const [winnableQueriesData, setWinnableQueriesData] = useState<any[]>([]);
  const [winnablePagesData, setWinnablePagesData] = useState<any[]>([]);
  const [interactions, setInteractions] = useState<{
    data: any[];
    keys: any[];
  }>({ data: [], keys: [] });
  const [localPresenceQueries, setLocalPresenceQueries] = useState<any[]>([]);
  const [reviewsData, setReviewsData] = useState<any>([
    {
      alltimereviews: { Total: 0, Percent: "0%", Trend: "down" },
      alltimerating: { Total: 0, Percent: "0%", Trend: "down" },
      reviewspast: {
        Total: 0,
        Diff: "0%",
        Trend: "down",
      },
      ratingspast: {
        Total: 0,
        Diff: "0%",
        Trend: "down",
      },
    },
  ]);
  const [landingPageContentData, setLandingPageContentData] = useState<any[]>(
    []
  );
  const [trendData, setTrendData] = useState<TrendData>({
    Clk: { Total: "0", Diff: "0%", Trend: "down" },
    Imp: { Total: "0", Diff: "0%", Trend: "down" },
    Ctr: { Total: "0", Diff: "0%", Trend: "down" },
    Pos: { Total: "0", Diff: "0%", Trend: "down" },
  });
  const [interActionTrendData, setInterActionTrendData] = useState<TrendData>({
    totalinteractions: { Total: "0", Diff: "0%", Trend: "down" },
  });

  const [loading, setLoading] = useState(true);
  const locationParams = useLocation(); // Correct variable name
  const prevUuidRef = useRef<string | null>(null);
  const getUuidFromParams = () => {
    // Retrieves 'uuid' from the URL search parameters
    const params = new URLSearchParams(locationParams.search);
    return params.get("uuid");
  };

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState<string[]>([]);

  const [clusters, setClusters] = useState<any>([]);
  const [newCluster, setNewCluster] = useState<any>(null);
  const [newCondition, setNewCondition] = useState<any>(null);
  const [filterText, setFilterText] = useState("");
  const [filterTextContent, setFilterTextContent] = useState("");

  const [contentGroups, setContentGroups] = useState<any>([]);
  const [newContentGroup, setNewContentGroup] = useState<any>(null);
  const [newContentCondition, setNewContentCondition] = useState<any>(null);
  const settingsControl = useRef(false);
  const [tabs, setTabs] = useState(["CLK", "IMP", "CTR", "POS"]);

  const [selectedTab, setSelectedTab] = useState(0);
  const { doApi } = useApi();
  const [businessProfileSettings, setBusinessProfileSettings] =
    useState<BusinessProfileSettings | null>(null);
    
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
      const updateScreenHeight = () => {
        setScreenHeight(window.innerHeight);
      };
  
      // Add event listener to update screen height on resize
      window.addEventListener("resize", updateScreenHeight);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", updateScreenHeight);
      };
    }, []);

  const filteredQueries =
    queriesData !== null && queriesData !== undefined
      ? queriesData
          .flatMap((cluster: any) => cluster.queries || [])
          .filter((query: any) =>
            query.toLowerCase().includes(filterText.toLowerCase())
          )
      : [];

  const filteredContentData =
    contentData !== null && contentData !== undefined
      ? contentData
          .flatMap((cluster: any) => cluster.queries || [])
          .filter((page: any) =>
            page.toLowerCase().includes(filterTextContent.toLowerCase())
          )
      : [];

      useEffect(() => {
        try{
          setLoading(true);
          const currentUuid = getUuidFromParams(); // Fetch the current UUID
          if (prevUuidRef.current !== currentUuid) {
            // If UUID has changed, set dashboardRef.current to true
            dashboardRef.current = true;
            prevUuidRef.current = currentUuid;
          } else {
            // Reset dashboardRef.current if UUID has not changed
            dashboardRef.current = false;
          }
        }finally{
           setLoading(false);
        }
       
      }, [locationParams.search]);

  const memoizedCTrendData = useMemo(() => {
    if (
      searchConsoleTrendData === null ||
      searchConsoleTrendData === undefined ||
      Object.keys(searchConsoleTrendData).length === 0
    ) {
      return {};
    }

    try {
      const jsonResponse = JSON.parse(searchConsoleTrendData);
      return jsonResponse;
    } catch (error) {
      console.error("Error parsing JSON in useMemo:", error);
    }
    return [];
  }, [searchConsoleTrendData]);

  useEffect(() => {
    setTrendData(memoizedCTrendData);
  }, [memoizedCTrendData]);

  const memoizedCardData = useMemo(() => {
    if (
      searchConsoleCards === undefined ||
      Object.keys(searchConsoleCards).length === 0
    ) {
      return {};
    }

    try {
      const jsonResponse = JSON.parse(searchConsoleCards);
      return jsonResponse;
    } catch (error) {
      console.error("Error parsing JSON in useMemo:", error);
    }
    return [];
  }, [searchConsoleCards]);

  useEffect(() => {
    setCards(memoizedCardData);
  }, [memoizedCardData]);

  const memoizedChartData = useMemo(() => {
    if (
      searchConsoleDate === null ||
      searchConsoleDate === undefined ||
      Object.keys(searchConsoleDate).length === 0
    ) {
      return { data: [], keys: [] };
    }

    try {
      const jsonResponse = JSON.parse(searchConsoleDate);

      if (jsonResponse?.rows && Array.isArray(jsonResponse.rows)) {
        const chartKeys = jsonResponse.rows.map(
          (row: any) => row.keys?.[0] ?? ""
        );
        const seriesData = [
          {
            name: "CLK",
            data: jsonResponse.rows.map((row: any) => row.clicks ?? 0),
          },
          {
            name: "IMP",
            data: jsonResponse.rows.map((row: any) => row.impressions ?? 0),
          },
          {
            name: "CTR",
            data: jsonResponse.rows.map((row: any) => row.ctr ?? 0),
          },
          {
            name: "POS",
            data: jsonResponse.rows.map((row: any) => row.position ?? 0),
          },
        ];

        return { data: seriesData, keys: chartKeys };
      }
    } catch (error) {
      console.error("Error parsing JSON in useMemo:", error);
    }
    return { data: [], keys: [] };
  }, [searchConsoleDate]);

  useEffect(() => {
    setChartData(memoizedChartData.data);
    setChartKey(memoizedChartData.keys);
  }, [memoizedChartData]);

  // Memoize queriesData based on `searchConsolePage`
  const contentDataMemo = useMemo(() => {
    if (
      searchConsolePage === null ||
      searchConsolePage === undefined ||
      Object.keys(searchConsolePage).length === 0
    )
      return;
    try {
      const jsonResponse = JSON.parse(searchConsolePage);
      console.log("fetchSearchAnalytics searchConsolePage", jsonResponse);

      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.stats1?.Trend === "up",
          diff: row.stats1?.Diff,
          percentage: row.stats1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.stats2?.Trend === "up",
          diff: row.stats2?.Diff,
          percentage: row.stats2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.stats3?.Trend === "up",
          diff: row.stats3?.Diff,
          percentage: row.stats3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.stats4?.Trend === "up",
          diff: row.stats4?.Diff,
          percentage: row.stats4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in queriesDataMemo:", error);
    }
    return [];
  }, [searchConsolePage]);

  useEffect(() => {
    setContentData(contentDataMemo);
  }, [contentDataMemo]);

  // Memoize queriesData based on `queryclusters`
  const queryClustersDataMemo = useMemo(() => {
    console.log("searchConsoleQueryClusters", searchConsoleQueryClusters);

    if (
      searchConsoleQueryClusters === null ||
      searchConsoleQueryClusters === undefined ||
      Object.keys(searchConsoleQueryClusters).length === 0
    )
      return;
    try {
      const jsonResponse = JSON.parse(searchConsoleQueryClusters);
      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.status1?.Trend === "up",
          diff: row.status1?.Diff,
          percentage: row.status1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.status2?.Trend === "up",
          diff: row.status2?.Diff,
          percentage: row.status2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.status3?.Trend === "up",
          diff: row.status3?.Diff,
          percentage: row.status3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.status4?.Trend === "up",
          diff: row.status4?.Diff,
          percentage: row.status4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in queryClustersDataMemo:", error);
    }
    return [];
  }, [searchConsoleQueryClusters]);

  useEffect(() => {
    setQueryClustersData(queryClustersDataMemo);
  }, [queryClustersDataMemo]);

  // Memoize queriesData based on `searchConsoleContentGroups`
  const searchConsoleContentGroupsDataMemo = useMemo(() => {
    if (
      searchConsoleContentGroups === null ||
      searchConsoleContentGroups === undefined ||
      Object.keys(searchConsoleContentGroups).length === 0
    )
      return;
    try {
      const jsonResponse = JSON.parse(searchConsoleContentGroups);
      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.status1?.Trend === "up",
          diff: row.status1?.Diff,
          percentage: row.status1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.status2?.Trend === "up",
          diff: row.status2?.Diff,
          percentage: row.status2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.status3?.Trend === "up",
          diff: row.status3?.Diff,
          percentage: row.status3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.status4?.Trend === "up",
          diff: row.status4?.Diff,
          percentage: row.status4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in contentGroupsDataMemo:", error);
    }
    return [];
  }, [searchConsoleContentGroups]);

  useEffect(() => {
    setContentGroupsData(searchConsoleContentGroupsDataMemo);
  }, [searchConsoleContentGroupsDataMemo]);

  // Memoize contentData based on `searchConsoleQuery`
  const queryDataMemo = useMemo(() => {
    if (
      searchConsoleQuery === null ||
      searchConsoleQuery === undefined ||
      Object.keys(searchConsoleQuery).length === 0
    )
      return;
    try {
      const jsonResponse = JSON.parse(searchConsoleQuery);
      console.log("jsonResponse data", jsonResponse);

      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.stats1?.Trend === "up",
          diff: row.stats1?.Diff,
          percentage: row.stats1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.stats2?.Trend === "up",
          diff: row.stats2?.Diff,
          percentage: row.stats2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.stats3?.Trend === "up",
          diff: row.stats3?.Diff,
          percentage: row.stats3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.stats4?.Trend === "up",
          diff: row.stats4?.Diff,
          percentage: row.stats4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in contentDataMemo:", error);
    }
    return [];
  }, [searchConsoleQuery]);

  useEffect(() => {
    setQueriesData(queryDataMemo);
  }, [queryDataMemo]);

  // Memoize contentData based on `winnable Query Data Memo`
  const winnableQueryDataMemo = useMemo(() => {
    if (winnableData.data === undefined) return;
    try {
      const jsonResponse = JSON.parse(winnableData.data[1].winnableQueries);
      console.log("jsonResponse data", jsonResponse);

      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.stats1?.Trend === "up",
          diff: row.stats1?.Diff,
          percentage: row.stats1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.stats2?.Trend === "up",
          diff: row.stats2?.Diff,
          percentage: row.stats2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.stats3?.Trend === "up",
          diff: row.stats3?.Diff,
          percentage: row.stats3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.stats4?.Trend === "up",
          diff: row.stats4?.Diff,
          percentage: row.stats4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in contentDataMemo:", error);
    }
    return [];
  }, [winnableData]);

  useEffect(() => {
    setWinnableQueriesData(winnableQueryDataMemo);
  }, [winnableQueryDataMemo]);

  // Memoize contentData based on `winnable Query Data Memo`
  const winnablePagesDataMemo = useMemo(() => {
    if (winnableData.data === undefined) return;
    try {
      const jsonResponse = JSON.parse(winnableData.data[0].winnablePages);
      console.log("jsonResponse data", jsonResponse);

      return jsonResponse.rows.map((row: any) => ({
        queries: row.keys[0],
        group: row.Group,
        clk: {
          value: row.clicks,
          increase: row.stats1?.Trend === "up",
          diff: row.stats1?.Diff,
          percentage: row.stats1?.Percentage,
        },
        imp: {
          value: row.impressions,
          increase: row.stats2?.Trend === "up",
          diff: row.stats2?.Diff,
          percentage: row.stats2?.Percentage,
        },
        ctr: {
          value: row.ctr,
          increase: row.stats3?.Trend === "up",
          diff: row.stats3?.Diff,
          percentage: row.stats3?.Percentage,
        },
        pos: {
          value: row.position,
          increase: row.stats4?.Trend === "up",
          diff: row.stats4?.Diff,
          percentage: row.stats4?.Percentage,
        },
      }));
    } catch (error) {
      console.error("Error parsing JSON in contentDataMemo:", error);
    }
    return [];
  }, [winnableData]);

  useEffect(() => {
    setWinnablePagesData(winnablePagesDataMemo);
  }, [winnablePagesDataMemo]);

  // Memoize landingPageDataAll based on `landingPageData?.landingPage`
  const landingPageDataAllMemo = useMemo(() => {
    if (!landingPageData?.landingPage) return [];
    try {
      const convertJsonData = JSON.parse(landingPageData.landingPage);
      console.log("landingPageData convertJsonData", convertJsonData);

      if (Array.isArray(convertJsonData)) {
        return convertJsonData.map((item: any) => ({
          group: item?.Group,
          landing_page: item["Landing page"],
          sessions: item.Sessions,
          engagement: item["Engagement Rate"],
          bounce: item["Bounce rate"],
          key_events: item["Key events"],
          conversions: item.Conversions,
        }));
      }
    } catch (error) {
      console.error("Error parsing JSON in landingPageDataAllMemo:", error);
    }
    return [];
  }, [landingPageData?.landingPage]);

  useEffect(() => {
    setLandingPageDataAll(landingPageDataAllMemo);
  }, [landingPageDataAllMemo]);

  // Memoize landingPageContentData based on `landingPageData?.firstUserDefaultChannelGroup`
  const landingPageContentDataMemo = useMemo(() => {
    if (!landingPageData?.landingPageContentGroup) return [];
    try {
      const convertJsonData = JSON.parse(
        landingPageData.landingPageContentGroup
      );
      if (Array.isArray(convertJsonData)) {
        return convertJsonData.map((item: any) => ({
          group: item?.Group,
          landing_page: item["Landing page"],
          sessions: item.Sessions,
          engagement: item["Engagement Rate"],
          bounce: item["Bounce rate"],
          key_events: item["Key events"],
          conversions: item.Conversions,
        }));
      }
    } catch (error) {
      console.error("Error parsing JSON in landingPageContentDataMemo:", error);
    }
    return [];
  }, [landingPageData?.landingPageContentGroup]);

  useEffect(() => {
    setLandingPageContentData(landingPageContentDataMemo);
  }, [landingPageContentDataMemo]);

  //GDP

  const memoizedChartLocalPresenceDate = useMemo(() => {
    if (
      localPresence?.interactions === undefined ||
      localPresence?.interactions === null ||
      JSON.parse(localPresence.interactions)?.err
    )
      return {
        data: [
          {
            name: "Interactions",
            data: [0],
          },
        ],
        keys: [dayjs().format("YYYY-MM-DD")],
      };

    try {
      const jsonResponse = JSON.parse(localPresence.interactions);
      const timeSeriesData: any = JSON.parse(
        jsonResponse.timeSeriesDatedValues
      );
      const totalInteractions = JSON.parse(jsonResponse.totalinteractions);

      // Set total interactions in trend data
      setInterActionTrendData({ totalinteractions: totalInteractions });
      // Prepare keys and series data for chart
      const chartKeys = timeSeriesData.map((row: any) =>
        dayjs(
          `${row.Date.slice(4, 8)}-${row.Date.slice(2, 4)}-${row.Date.slice(
            0,
            2
          )}`
        ).format("YYYY-MM-DD")
      );
      const seriesData: any = [
        {
          name: "Interactions",
          data: timeSeriesData.map((row: any) => row.Value ?? 0),
        },
      ];

      return { data: seriesData, keys: chartKeys };
    } catch (error) {
      console.error(
        "Error parsing JSON in memoizedChartLocalPresenceDate:",
        error
      );
      return {
        data: [
          {
            name: "Interactions",
            data: [0], // Placeholder value for the graph
          },
        ],
        keys: [dayjs().format("YYYY-MM-DD")],
      };
    }
  }, [localPresence]);

  // Set interactions when chart data is memoized
  useEffect(() => {
    setInteractions(memoizedChartLocalPresenceDate);
  }, [memoizedChartLocalPresenceDate]);

  // Memoized calculation for queries data
  const memoizedChartLocalPresenceQueries = useMemo(() => {
    if (localPresence?.queries === undefined || localPresence.queries === null)
      return [];

    try {
      const jsonResponse = JSON.parse(localPresence.queries);
      const rows = JSON.parse(jsonResponse.rows);

      return rows.map((row: { Query: string; Value: number }) => ({
        queries: row.Query,
        searches: { value: row.Value },
      }));
    } catch (error) {
      console.error(
        "Error parsing JSON in memoizedChartLocalPresenceQueries:",
        error
      );
      return [];
    }
  }, [localPresence]);

  // Set queries data when memoized
  useEffect(() => {
    setLocalPresenceQueries(memoizedChartLocalPresenceQueries);
  }, [memoizedChartLocalPresenceQueries]);

  // Memoized calculation for queries data
  const memoizedChartLocalPresenceReviews = useMemo(() => {
    if (localPresence?.reviews === undefined || localPresence.reviews === null)
      return [];

    try {
      const jsonResponse = JSON.parse(localPresence.reviews);
      return jsonResponse;
    } catch (error) {
      console.error(
        "Error parsing JSON in memoizedChartLocalPresenceReviews:",
        error
      );
      return [];
    }
  }, [localPresence]);

  // Set queries data when memoized
  useEffect(() => {
    setReviewsData(memoizedChartLocalPresenceReviews);
  }, [memoizedChartLocalPresenceReviews]);

  const [month, setMonth] = useState<string>("3 months");
  const dashboardRef = useRef(true);
  const [location, setLocation] = useState<string>("");

  useEffect(() => {
    if (profileData.Locations && profileData.Locations.length > 0) {
      setLocation(profileData.Locations[0].Name);
    }
  }, [profileData]);

  // console.log("profileData.UniqueId",profileData.UniqueId);
  const fetchSearchAnalyticsData = useCallback(async () => {
    const { Website, UniqueId } = profileData; // Destructure for clarity
    if (Website && UniqueId) {
      const values = {
        uniqueId: UniqueId,
        website: Website,
        origin: "ui",
        Period: month,
        dimensions: ["date", "page", "query"],
      };

      try {
        await FetchSearchAnalytics(values);
      } catch (error) {
        console.error("Error fetching search analytics data:", error);
      }
    }
  }, [month, profileData.Website, profileData.UniqueId]);

  const fetchGA4Data = useCallback(async () => {
    const { Website, UniqueId } = profileData;
    if (Website && UniqueId) {
      const GA4Payload = {
        uniqueId: UniqueId,
        website: Website,
        origin: "ui",
        period: month,
        mode: "changemetric",
        dimensions: [
          {
            name: "landingPage",
            metrics: [
              { name: "sessions" },
              { name: "engagementRate" },
              { name: "bounceRate" },
              { name: "keyEvents" },
            ],
          },
          {
            name: "firstUserDefaultChannelGroup",
            metrics: [{ name: "sessions" }, { name: "keyEvents" }],
          },
          {
            name: "date",
            metrics: [{ name: "Key events" }, { name: "Sessions" }],
          },
        ],
      };

      try {
        await FetchGA4Data(GA4Payload);
      } catch (error) {
        console.error("Error fetching GA4 data:", error);
      }
    }
  }, [month, profileData.Website, profileData.UniqueId]);

  const fetchLocalPresence = useCallback(async () => {
    const { UniqueId } = profileData;
    if (UniqueId && location) {
      const payload = {
        uniqueId: UniqueId,
        period: month,
        location,
      };

      try {
        await FetchLocalPresence(payload);
      } catch (error) {
        console.error("Error fetching local presence data:", error);
      }
    }
  }, [month, profileData.UniqueId, location]);

  const fetchWinnableData = useCallback(async () => {
    const { Website, UniqueId } = profileData;
    if (Website && UniqueId) {
      const values = {
        uniqueId: UniqueId,
        website: Website,
        origin: "ui",
        Period: month,
        dimensions: ["date", "page", "query"],
      };

      try {
        await FetchWinnableData(values);
      } catch (error) {
        console.error("Error fetching winnable data:", error);
      }
    }
  }, [month, profileData.Website, profileData.UniqueId]);

  const handleChange = (e: any) => {
    setLocation(e.target.value);
  };

  useEffect(() => {
    const winnableDataFunc = async () => {
      setLoading(true);
      await fetchWinnableData();
      setLoading(false);
    };
    if (winnableTab.current && selectedTab === 1) {
      winnableDataFunc();
      winnableTab.current = false;
    }
  }, [winnableTab.current, selectedTab]);

  useEffect(() => {
    const localPresc = async () => {
      await fetchLocalPresence();
    };

    if (location !== "" && selectedTab === 0) {
      localPresc();
    }
  }, [location, month, selectedTab]);


  useEffect(() => {
    // Only run the functions once when the component mounts
    if (profileData.Website && dashboardRef.current && selectedTab === 0) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
      const allfunc = async () => {
        setLoading(true);
        try {
          await fetchSearchAnalyticsData();
          setLoading(false);
          await fetchGA4Data();
        } catch (error) {
          console.error("Error fetching data:", error);
        }finally{
          setLoading(false)
        }
      };
      allfunc();
      dashboardRef.current = false;
    }
  }, [dashboardRef.current, selectedTab, month, profileData]);

  const transformBusinessProfileData = (data: any): BusinessProfileSettings => {
    const transformedData: BusinessProfileSettings = {
      uniqueId: data.UniqueId,
      userUniqueId: data.UserUniqueId,
      brandedKeywords: JSON.parse(data.BrandedKeywords),
      topicClusters: JSON.parse(data.TopicClusters).map((cluster: any) => ({
        name: cluster.Name,
        conditions: cluster.Conditions.map((condition: any) => ({
          condition: condition.Condition,
          value: condition.Value,
        })),
      })),
      contentGroups: JSON.parse(data.ContentGroups).map((group: any) => ({
        name: group.Name,
        conditions: group.Conditions.map((condition: any) => ({
          condition: condition.Condition,
          value: condition.Value,
        })),
      })),
    };

    return transformedData;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 2) {
       secondTabRef.current = true;
    }
  };

  useEffect(() => {
    dashboardRef.current = true;
    winnableTab.current = true;
  }, [month]);

  const timePerios = useMemo(
    () => [
      {
        id: 1,
        label: "7 days",
        value: "7 days",
      },
      {
        id: 2,
        label: "14 days",
        value: "14 days",
      },
      {
        id: 3,
        label: "28 days",
        value: "28 days",
      },
      {
        id: 4,
        label: "3 months",
        value: "3 months",
      },
      {
        id: 5,
        label: "6 months",
        value: "6 months",
      },
      {
        id: 6,
        label: "12 months",
        value: "12 months",
      },
    ],
    []
  );

  const handleViewOrientation = (
    _event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabs(
      (prevTabs) =>
        prevTabs.includes(newValue)
          ? prevTabs.filter((tab) => tab !== newValue) // Deselect if already selected
          : [...prevTabs, newValue] // Select if not already selected
    );
  };

  useEffect(() => {
    if (
      businessProfileSettings !== null &&
      Object.keys(businessProfileSettings).length > 0
    ) {
      setKeywords(businessProfileSettings.brandedKeywords);
      setClusters(businessProfileSettings.topicClusters);
      setContentGroups(businessProfileSettings.contentGroups);
    }
  }, [businessProfileSettings]);

  const handleAddKeyword = () => {
    if (keyword.trim() && !keywords.includes(keyword.trim())) {
      setKeywords((prev) => [...prev, keyword.trim()]);
      settingsControl.current = true;
    }
    setKeyword("");
  };

  const handleRemoveKeyword = (removedKeyword: string) => {
    setKeywords((prev) => prev.filter((kw) => kw !== removedKeyword));
    settingsControl.current = true;
  };

  const fetchBusinessProfileSettings = useCallback(async () => {
    const fetchBusinessProfileSettingsConfig: IAPIConfig = {
      URL: `Profile/GetBusinessProfileSettings?businessUniqueId=${profileData.UniqueId}&userUniqueId=${profileData.UserUniqueId}`,
      method: "GET",
    };

    const res: any = await doApi(
      fetchBusinessProfileSettingsConfig,
      "seedcore"
    );
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData);
      const normalizedData = transformBusinessProfileData(parsedData);
      setBusinessProfileSettings(normalizedData);
    } else {
      CustomToast("Failed to fetch business profile settings.", "error");
    }
  }, [profileData, doApi, CustomToast]);

  const updateBusinessProfileSettings = useCallback(async () => {
    const finalClusters = clusters.map(
      ({
        index,
        ...rest
      }: {
        index?: number;
        name: string;
        conditions: { condition: string; value: string }[];
      }) => rest
    );
    const finalContentGroups = contentGroups.map(
      ({
        index,
        ...rest
      }: {
        index?: number;
        name: string;
        conditions: { condition: string; value: string }[];
      }) => rest
    );

    const inputPayload = {
      id: profileData.id,
      uniqueId: profileData.UniqueId,
      userUniqueId: profileData.UserUniqueId,
      brandedKeywords: keywords,
      topicClusters: finalClusters,
      contentGroups: finalContentGroups,
    };

    const updateBusinessProfileSettingsConfig = {
      URL: `Profile/UpdateBusinessProfileSettings`,
      method: "POST",
      payLoad: inputPayload,
    };

    try {
      const res: any = await doApi(
        updateBusinessProfileSettingsConfig,
        "seedcore"
      );

      if (res.status === 200) {
        fetchBusinessProfileSettings();
        dashboardRef.current = true;
      } else {
        console.log("Failed to update business profile settings.");
      }
    } catch (error) {
      console.log(
        "Failed to update business profile settings due to an error."
      );
    }
  }, [profileData, keywords, doApi, CustomToast]);

  const secondTabRef = useRef(false);

  useEffect(() => {
    if (selectedTab === 2 && secondTabRef.current) {
      fetchBusinessProfileSettings();
      secondTabRef.current = false;
    }
  }, [selectedTab]);

  useEffect(() => {
    if (settingsControl.current) {
      updateBusinessProfileSettings();
      settingsControl.current = false;
    }
  }, [settingsControl.current]);

  // Initialize a new content group with one condition
  const startNewContentGroup = () => {
    setNewContentGroup({ name: "", conditions: [], index: -1 });
    setNewContentCondition({ condition: "contains", value: "" });
  };

  // Set the content group to edit
  const editContentGroup = (index: number) => {
    setNewContentGroup({ ...contentGroups[index], index });
  };

  // Remove the content group by index
  const removeContentGroup = (index: number) => {
    setContentGroups(contentGroups.filter((_: any, i: number) => i !== index));
    settingsControl.current = true;
    setNewContentGroup(null);
  };

  // Save content group - updates if editing, adds new if creating
  const saveContentGroup = () => {
    if (newContentGroup.index >= 0) {
      // Update existing content group
      setContentGroups(
        contentGroups.map((content: any, index: number) =>
          index === newContentGroup.index
            ? { ...newContentGroup, index: -1 }
            : content
        )
      );
    } else {
      // Add new content group
      setContentGroups([...contentGroups, { ...newContentGroup, index: -1 }]);
    }
    settingsControl.current = true;
    setNewContentGroup(null); // Reset new content group state
  };

  // Cancel the content group creation/editing
  const cancelContentGroup = () => {
    setNewContentGroup(null);
    setNewContentCondition(null);
  };

  // Save the current condition and initialize a new empty one
  const saveContentCondition = () => {
    if (newContentCondition.value) {
      setNewContentGroup({
        ...newContentGroup,
        conditions: [...newContentGroup.conditions, newContentCondition],
      });
      setNewContentCondition({ condition: "contains", value: "" });
    }
  };

  // Initialize a new cluster with one condition
  const startNewCluster = () => {
    setNewCluster({ name: "", conditions: [], index: -1 });
    setNewCondition({ condition: "contains", value: "" });
  };

  // Set the cluster to edit
  const editCluster = (index: number) => {
    setNewCluster({ ...clusters[index], index }); // Store index to identify edited cluster
  };

  // Remove the cluster by index
  const removeCluster = (index: number) => {
    setClusters(clusters.filter((_: any, i: number) => i !== index));
    setNewCluster(null);
    settingsControl.current = true;
  };

  // Save cluster - updates if editing, adds new if creating
  const saveCluster = () => {
    if (newCluster.index >= 0) {
      // Update existing cluster
      setClusters(
        clusters.map((cluster: any, index: number) =>
          index === newCluster.index ? { ...newCluster, index: -1 } : cluster
        )
      );
    } else {
      // Add new cluster
      setClusters([...clusters, { ...newCluster, index: -1 }]);
    }
    settingsControl.current = true;
    setNewCluster(null); // Reset newCluster state
  };

  // Cancel the cluster creation/editing
  const cancelCluster = () => {
    setNewCluster(null);
    setNewCondition(null);
  };

  // Save the current condition and initialize a new empty one
  const saveCondition = () => {
    if (newCondition.value) {
      setNewCluster({
        ...newCluster,
        conditions: [...newCluster.conditions, newCondition],
      });
      setNewCondition({ condition: "contains", value: "" });
    }
  };
  const handleLinkClick = () => {
    setSelectedTab(2);
  };

  return (
    <Box className="dashboard-container" key={profileData.UniqueId}>
      {/* Profile Header */}

      <Typography variant="h4">Dashboard</Typography>
      {/* Profile Tabs */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "flex-end",
          columnGap: "1.5rem",
          rowGap: "1rem",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className="dashboard-tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Dashboard" className="tab" />
          <Tab label="Opportunities" className="tab" />
          <Tab label="Settings" className="tab" />
        </Tabs>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month} // Make sure this matches one of the timePerios values
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              sx={{
                color: "#6e6b6b",
                height: "40px",
                outline: "none",
                background: "#fff",
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {timePerios.map((timePeriod) => (
                <MenuItem key={timePeriod.id} value={timePeriod.value}>
                  {timePeriod.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Tab Content */}
      <Loader spinning={loading}>
        <Box className="tab-content">
          {selectedTab === 0 && (
            <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
              <Box>
                <Card className="review-info-card">
                  <Box>
                    <Typography variant="h5">Organic Search</Typography>
                    <Typography variant="body2" marginTop={1}>
                      Source: Google Search Console
                    </Typography>
                  </Box>

                  <Box>
                    <ToggleButtonGroup
                      value={tabs}
                      className="toggleButtonGroup"
                      exclusive
                      onChange={handleViewOrientation}
                      sx={{ height: "40px", color: "#fff" }}
                    >
                      <ToggleButton
                        disableRipple
                        className="toggleButton"
                        value="CLK"
                      >
                        CLK
                      </ToggleButton>
                      <ToggleButton
                        disableRipple
                        className="toggleButton"
                        value="IMP"
                      >
                        IMP
                      </ToggleButton>
                      <ToggleButton
                        disableRipple
                        className="toggleButton"
                        value="CTR"
                      >
                        CTR
                      </ToggleButton>
                      <ToggleButton
                        disableRipple
                        className="toggleButton"
                        value="POS"
                      >
                        POS
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Card>

                <Box sx={{ marginTop: 1 }}>
                  {/* <ChartComponent /> */}
                  {ChartData.length > 0 && ChartKey.length > 0 && (
                    <CustomAreaChartComponent
                      key={JSON.stringify(ChartKey)}
                      loading={loading}
                      chartType="area"
                      colors={[
                        { clk: "#007bff" },
                        { imp: "#6f42c1" },
                        { ctr: "#00897B" },
                        { pos: "#fd7e14" },
                      ]}
                      categories={ChartKey}
                      overViews={trendData}
                      seriesData={ChartData}
                      title="Custom Chart Title"
                    />
                  )}
                </Box>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1.4 }}
                >
                  <Grid size={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                    <StatsCard
                      title="Branded"
                      description={`vs prev.${month}`}
                      value={cards?.branded?.Percentage || ""}
                      percentage={cards?.branded?.Diff || ""}
                      increase={cards?.branded?.Trend === "down" ? false : true}
                      handleDefineClick={handleLinkClick}
                      message="branded in settings to activate this widget."
                      con={false}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                    <StatsCard
                      title="Product"
                      description=""
                      value="0"
                      percentage=""
                      increase={false}
                      handleDefineClick={handleLinkClick}
                      message="products in settings to activate this widget."
                      con={false}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                    <StatsCard
                      title="In Top 10"
                      description={`vs prev.${month}`}
                      value={cards?.intop10?.Percentage || ""}
                      percentage={cards?.intop10?.Diff || ""}
                      increase={cards?.intop10?.Trend === "down" ? false : true}
                      handleDefineClick={handleLinkClick}
                      message="intop10 in settings to activate this widget."
                      con={false}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1.4 }}
                  sx={{ marginTop: 1 }}
                >
                  <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                    <TableComponent
                      title="Queries"
                      data={queriesData}
                      colSpan={5}
                      linkText="No data available"
                      columns={tabs}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                    <TableComponent
                      title="Content"
                      data={contentData}
                      colSpan={5}
                      linkText="No data available"
                      columns={tabs}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                    <TableComponent
                      title="Query Clusters"
                      data={queryClustersData}
                      colSpan={5}
                      message="Missing Query Clusters"
                      linkText="a query cluster to activate this widget."
                      onLinkClick={handleLinkClick}
                      columns={tabs}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                    <TableComponent
                      title="Content Groups"
                      data={queryContentGroupsData}
                      colSpan={5}
                      message="Missing Content Groups"
                      linkText="a content group to activate this widget."
                      onLinkClick={handleLinkClick}
                      columns={tabs}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: 6 }}>
                  <Card className="review-info-card">
                    <Box>
                      <Typography variant="h5">Website Performance</Typography>
                      <Typography variant="body2" marginTop={1}>
                        Source: Google Analytics
                      </Typography>
                    </Box>
                  </Card>
                  <Box sx={{ marginTop: 1 }}>
                    <WebsitePerformance />
                  </Box>
                </Box>
                <Box marginTop={1}>
                  <TableComponent2
                    title="Landing Page (All)"
                    data={landingPageDataAll}
                    colSpan={5}
                    linkText="No data available."
                  />
                </Box>
                <Box marginTop={1}>
                  <TableComponent2
                    title="Landing Page (Content Groups)"
                    data={landingPageContentData}
                    colSpan={5}
                    message="Missing Content Groups"
                    linkText="a content group to activate this widget."
                    onLinkClick={handleLinkClick}
                  />
                </Box>

                {true && (
                  <Box sx={{ marginTop: 6 }}>
                    <Card className="review-info-card">
                      <Box>
                        <Typography variant="h5">Local Presence</Typography>
                        <Typography variant="body2" marginTop={1}>
                          Source: Google Business Profile
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ minWidth: 200 }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={location}
                              onChange={handleChange}
                              sx={{
                                color: "#6e6b6b",
                                height: "40px",
                                outline: "none",
                                background: "#fff",
                              }}
                            >
                              {profileData.Locations !== undefined &&
                                profileData.Locations.map(
                                  (loc: any, index: number) => (
                                    <MenuItem key={loc.Name} value={loc.Name}>
                                      {loc.DisplayName}- {index + 1}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Card>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 1, md: 1.4 }}
                      sx={{ marginTop: 1 }}
                    >
                      <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                        {/* <WebsitePerformance /> */}

                        {interactions.data.length > 0 && (
                          <CustomAreaChartComponent
                            key={JSON.stringify(interactions.keys)}
                            loading={loading}
                            chartType="area"
                            colors={[{ interactions: "#007bff" }]}
                            categories={interactions.keys}
                            overViews={interActionTrendData}
                            seriesData={interactions.data}
                            title="Custom Chart Title"
                          />
                        )}
                      </Grid>
                      <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                        <TableComponent3
                          title="Queries"
                          data={localPresenceQueries}
                          colSpan={5}
                          linkText="No data available"
                          columns={["searches"]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1.4 }}
                  marginTop={1}
                >
                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCard
                      title="All Time Reviews"
                      description=""
                      value={reviewsData?.alltimereviews?.Total || "0"}
                      percentage="0%"
                      increase={
                        reviewsData?.alltimereviews?.Diff === "up"
                          ? true
                          : false
                      }
                      con={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4, lg: 6 }} sx={{ flexGrow: 1 }}>
                    <StatsCard
                      title={`Reviews Past ${month}`}
                      description="Growth in reviews"
                      value={reviewsData?.reviewspast?.Total || "0"}
                      percentage={
                        `${parseFloat(reviewsData?.reviewspast?.Diff).toFixed(
                          1
                        )}%` || "0%"
                      }
                      increase={
                        reviewsData?.reviewspast?.Diff === "up" ? true : false
                      }
                      con={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCardWithStars
                      title="All Time Average Rating"
                      description="Average rating"
                      value={reviewsData?.alltimerating?.Total || "0"}
                      percentage={
                        `${parseFloat(reviewsData?.alltimerating?.Diff).toFixed(
                          1
                        )}%` || "0%"
                      }
                      increase={
                        reviewsData?.alltimerating?.Diff === "up" ? true : false
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCardWithStars
                      title={`Ratings Past ${month}`}
                      description="Average rating"
                      value={reviewsData?.ratingspast?.Total || "0"}
                      percentage={
                        `${parseFloat(reviewsData?.ratingspast?.Diff).toFixed(
                          1
                        )}%` || "0%"
                      }
                      increase={
                        reviewsData?.ratingspast?.Diff === "up" ? true : false
                      }
                    />
                  </Grid>

                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCard2
                      title="Top Likes"
                      description={
                        <Box>
                          <ul>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                          </ul>
                        </Box>
                      }
                      warning={false}
                    />
                  </Grid>

                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCard2
                      title="Top Likes"
                      description={
                        <Box>
                          <ul>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                          </ul>
                        </Box>
                      }
                      warning={false}
                    />
                  </Grid>

                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCard2
                      title="Top Issues"
                      description={
                        <Box>
                          <ul>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                          </ul>
                        </Box>
                      }
                      warning={true}
                    />
                  </Grid>

                  <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                    <StatsCard2
                      title="Raising Issues"
                      description={
                        <Box>
                          <ul>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                            <li>Lorem ipsum</li>
                          </ul>
                        </Box>
                      }
                      warning={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </LazyLoad>
          )}

          {selectedTab === 1 && (
          <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0.5rem",
              }}
            >
              {/* Opportunities tab content goes here... */}

              <TableComponent
                title="Winnable Queries"
                data={winnableQueriesData}
                colSpan={5}
                linkText="No data available"
                columns={tabs}
              />

              <TableComponent
                title="Winnable Pages"
                data={winnablePagesData}
                colSpan={5}
                linkText="No data available"
                columns={tabs}
              />
            </Box>
            </LazyLoad>
          )}
          {selectedTab === 2 && (
           <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
            <Box>
              <>
                <Card sx={{ padding: 3, bgcolor: "#F6F8F8" }}>
                  <Typography variant="h6" className="customcolor">
                    Branded Keywords
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ marginTop: 1 }}
                  >
                    Add branded keywords that are unique to your brand. There
                    can be multiple, the simplest example is the name of your
                    business.
                  </Typography>

                  {/* Input field and Add Button */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                      marginTop: 2,
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Enter a keyword"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleAddKeyword();
                        }
                      }}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddKeyword();
                      }}
                      disabled={!keyword.trim()}
                      sx={{ fontSize: "14px" }}
                    >
                      Add
                    </Button>
                  </Box>

                  {/* Chip list of keywords */}
                  <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                    {keywords !== undefined &&
                      keywords.length > 0 &&
                      keywords.map((kw) => (
                        <Chip
                          key={kw}
                          label={kw}
                          onDelete={() => handleRemoveKeyword(kw)}
                          deleteIcon={
                            <CancelIcon
                              sx={{ width: "16px", height: "16px" }}
                            />
                          }
                          sx={{
                            fontSize: "13px",
                            minHeight: "24px",
                            height: "24px",
                          }}
                        />
                      ))}
                  </Stack>
                </Card>

                <Card sx={{ marginTop: 1, padding: 3, bgcolor: "#F6F8F8" }}>
                  <Box sx={{ marginTop: "16px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "171D1A" }}
                    >
                      Query Clusters
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Clusters are a collection of related keywords. Grouping
                      allows for more efficient analysis. The simplest example
                      are terms you use to describe your core products and
                      services.
                    </Typography>

                    {/* Display saved clusters */}
                    {!newCluster && (
                      <Box
                        sx={{
                          marginTop: 3,
                          display: "flex",
                          flexWrap: "wrap",
                          rowGap: 2,
                        }}
                      >
                        {clusters !== undefined &&
                          clusters.length > 0 &&
                          clusters.map((cluster: any, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                width: "252px",
                                cursor: "pointer",
                              }}
                              onClick={() => editCluster(index)}
                            >
                              <Typography
                                variant="subtitle2"
                                fontWeight="500"
                                gutterBottom
                              >
                                {cluster.name}
                              </Typography>
                              {cluster.conditions.map(
                                (cond: any, condIndex: number) => (
                                  <Typography
                                    key={condIndex}
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {cond.condition}: {cond.value}
                                  </Typography>
                                )
                              )}

                              {/* Edit and Remove buttons */}
                              {/* <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: 2,
                                }}
                              >
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => editCluster(index)}
                                  sx={{ color: "#1976d2" }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => removeCluster(index)}
                                  sx={{ color: "#d32f2f" }}
                                >
                                  Remove
                                </Button>
                              </Box> */}
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* New Cluster Creation */}
                    {!newCluster ? (
                      <Button
                        variant="outlined"
                        onClick={startNewCluster}
                        sx={{
                          bgcolor: "#1976d2",
                          color: "#fff",
                          "&:hover": { bgcolor: "#1565c0" },
                          marginTop: 3,
                        }}
                      >
                        New Query Cluster
                      </Button>
                    ) : (
                      <Box sx={{ marginTop: 3 }}>
                        <TextField
                          label="Cluster Name"
                          variant="outlined"
                          size="small"
                          sx={{ marginBottom: 2, width: "220px" }}
                          value={newCluster.name}
                          onChange={(e) =>
                            setNewCluster({
                              ...newCluster,
                              name: e.target.value,
                            })
                          }
                        />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: "14px", color: "#171D1A" }}
                        >
                          Conditions
                        </Typography>

                        {/* Display saved conditions for the current new cluster */}
                        {newCluster.conditions.map(
                          (cond: any, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                marginBottom: 1,
                              }}
                            >
                              {/* Condition Select */}
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: "296px" }}
                              >
                                <Select
                                  value={cond.condition}
                                  onChange={(e) => {
                                    const updatedConditions = [
                                      ...newCluster.conditions,
                                    ];
                                    updatedConditions[index] = {
                                      ...updatedConditions[index],
                                      condition: e.target.value as string,
                                    };
                                    setNewCluster({
                                      ...newCluster,
                                      conditions: updatedConditions,
                                    });
                                  }}
                                >
                                  <MenuItem value="contains">Contains</MenuItem>
                                  <MenuItem value="equals">Equals</MenuItem>
                                  <MenuItem value="startsWith">
                                    Starts with
                                  </MenuItem>
                                  <MenuItem value="endsWith">
                                    Ends with
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              {/* Value Input */}
                              <TextField
                                size="small"
                                label="Value"
                                variant="outlined"
                                fullWidth
                                value={cond.value}
                                onChange={(e) => {
                                  const updatedConditions = [
                                    ...newCluster.conditions,
                                  ];
                                  updatedConditions[index] = {
                                    ...updatedConditions[index],
                                    value: e.target.value,
                                  };
                                  setNewCluster({
                                    ...newCluster,
                                    conditions: updatedConditions,
                                  });
                                }}
                              />

                              {/* Delete Icon */}
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  const updatedConditions =
                                    newCluster.conditions.filter(
                                      (_: any, i: number) => i !== index
                                    );
                                  setNewCluster({
                                    ...newCluster,
                                    conditions: updatedConditions,
                                  });
                                }}
                                sx={{ color: "#d32f2f" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )
                        )}
                        {/* New Condition Input */}
                        {newCondition && (
                          <>
                            <Box sx={{ display: "flex", gap: 1, marginTop: 2 }}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: "296px" }}
                              >
                                <Select
                                  value={newCondition.condition}
                                  onChange={(e) =>
                                    setNewCondition({
                                      ...newCondition,
                                      condition: e.target.value as string,
                                    })
                                  }
                                >
                                  <MenuItem value="contains">Contains</MenuItem>
                                  <MenuItem value="equals">Equals</MenuItem>
                                  <MenuItem value="startsWith">
                                    Starts with
                                  </MenuItem>
                                  <MenuItem value="endsWith">
                                    Ends with
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                size="small"
                                label="Value"
                                variant="outlined"
                                fullWidth
                                value={newCondition.value}
                                onChange={(e) => {
                                  setFilterText(e.target.value);
                                  setNewCondition({
                                    ...newCondition,
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={saveCondition}
                              >
                                Add
                              </Button>
                            </Box>
                          </>
                        )}

                        {/* Save or cancel the entire cluster */}
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            marginTop: 3,
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={saveCluster}
                            sx={{ bgcolor: "#388e3c" }}
                          >
                            Save
                          </Button>
                          <Button variant="outlined" onClick={cancelCluster}>
                            Cancel
                          </Button>
                          <IconButton
                            aria-label="delete"
                            sx={{
                              color: "#0F4343",
                              width: "24px",
                              height: "24px",
                            }}
                          >
                            {!newCondition && (
                              <DeleteIcon
                                onClick={() => removeCluster(newCluster?.index)}
                              />
                            )}
                          </IconButton>
                        </Box>

                        <Box sx={{ marginTop: 3 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#171D1A" }}
                          >
                            {filteredQueries.length} matching queries
                          </Typography>
                          {filteredQueries
                            .slice(0, 20)
                            .map((query: any, index: number) => (
                              <Box sx={{ marginTop: 1 }} key={index}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "12px" }}
                                >
                                  {query}
                                </Typography>
                              </Box>
                            ))}
                          {filteredQueries.length > 20 && (
                            <Box sx={{ marginTop: 1 }}>
                              <Typography
                                sx={{ fontSize: "13px", color: "#0266D1" }}
                              >
                                + {filteredQueries.length - 20} other queries
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Card>

                <Card sx={{ marginTop: 1, padding: 3, bgcolor: "#F6F8F8" }}>
                  <Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "171D1A" }}
                    >
                      Content Groups
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginBottom: 3 }}
                    >
                      These are a collection of related pages. Simplest examples
                      are pages related to blogs, or a similar topic.
                    </Typography>

                    {/* Display saved content group */}
                    {!newContentGroup && (
                      <Box
                        sx={{
                          marginBottom: 3,
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          rowGap: 2,
                          justifyContent: "flex-start",
                        }}
                      >
                        {contentGroups !== undefined &&
                          contentGroups.length > 0 &&
                          contentGroups.map((content: any, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                width: "252px",
                              }}
                              onClick={() => editContentGroup(index)}
                            >
                              <Typography
                                variant="subtitle2"
                                fontWeight="500"
                                gutterBottom
                              >
                                {content.name}
                              </Typography>
                              {content.conditions.map(
                                (cond: any, condIndex: number) => (
                                  <Typography
                                    key={condIndex}
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {cond.condition}: {cond.value}
                                  </Typography>
                                )
                              )}

                              {/* Edit and Remove buttons */}
                              {/* <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 2,
                            }}
                          >
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => editContentGroup(index)}
                              sx={{ color: "#1976d2" }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => removeContentGroup(index)}
                              sx={{ color: "#d32f2f" }}
                            >
                              Remove
                            </Button>
                          </Box> */}
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* New ContentGroup Creation */}
                    {!newContentGroup ? (
                      <Button
                        variant="outlined"
                        onClick={startNewContentGroup}
                        sx={{
                          bgcolor: "#1976d2",
                          color: "#fff",
                          "&:hover": { bgcolor: "#1565c0" },
                        }}
                      >
                        New Content Group
                      </Button>
                    ) : (
                      <Box>
                        <TextField
                          label="Cluster Name"
                          variant="outlined"
                          size="small"
                          sx={{ marginBottom: 2, width: "220px" }}
                          value={newContentGroup.name}
                          onChange={(e) =>
                            setNewContentGroup({
                              ...newContentGroup,
                              name: e.target.value,
                            })
                          }
                        />

                        {/* Display saved conditions for the current new content group */}
                        {newContentGroup.conditions.map(
                          (cond: any, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                marginBottom: 1,
                              }}
                            >
                              {/* Dropdown for selecting condition */}
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: "250px" }}
                              >
                                <Select
                                  value={cond.condition}
                                  onChange={(e) => {
                                    const updatedConditions = [
                                      ...newContentGroup.conditions,
                                    ];
                                    updatedConditions[index] = {
                                      ...updatedConditions[index],
                                      condition: e.target.value as string,
                                    };
                                    setNewContentGroup({
                                      ...newContentGroup,
                                      conditions: updatedConditions,
                                    });
                                  }}
                                >
                                  <MenuItem value="contains">Contains</MenuItem>
                                  <MenuItem value="equals">Equals</MenuItem>
                                  <MenuItem value="startsWith">
                                    Starts with
                                  </MenuItem>
                                  <MenuItem value="endsWith">
                                    Ends with
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              {/* Text field for value */}
                              <TextField
                                size="small"
                                label="Value"
                                fullWidth
                                variant="outlined"
                                value={cond.value}
                                onChange={(e) => {
                                  const updatedConditions = [
                                    ...newContentGroup.conditions,
                                  ];
                                  updatedConditions[index] = {
                                    ...updatedConditions[index],
                                    value: e.target.value,
                                  };
                                  setNewContentGroup({
                                    ...newContentGroup,
                                    conditions: updatedConditions,
                                  });
                                }}
                              />

                              {/* Delete icon */}
                              <IconButton
                                aria-label="delete-condition"
                                onClick={() => {
                                  const updatedConditions =
                                    newContentGroup.conditions.filter(
                                      (_: any, condIndex: number) =>
                                        condIndex !== index
                                    );
                                  setNewContentGroup({
                                    ...newContentGroup,
                                    conditions: updatedConditions,
                                  });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )
                        )}

                        {/* New Condition Input */}
                        {newContentCondition && (
                          <>
                            <Box sx={{ display: "flex", gap: 1, marginTop: 2 }}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: "292px" }}
                              >
                                <Select
                                  value={newContentCondition.condition}
                                  onChange={(e) =>
                                    setNewContentCondition({
                                      ...newContentCondition,
                                      condition: e.target.value as string,
                                    })
                                  }
                                >
                                  <MenuItem value="contains">Contains</MenuItem>
                                  <MenuItem value="equals">Equals</MenuItem>
                                  <MenuItem value="startsWith">
                                    Starts with
                                  </MenuItem>
                                  <MenuItem value="endsWith">
                                    Ends with
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                size="small"
                                label="Value"
                                variant="outlined"
                                fullWidth
                                value={newContentCondition.value}
                                onChange={(e) => {
                                  setFilterTextContent(e.target.value);
                                  setNewContentCondition({
                                    ...newContentCondition,
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={saveContentCondition}
                              >
                                Add
                              </Button>
                            </Box>
                          </>
                        )}

                        {/* Save or cancel the entire content group */}
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            marginTop: 3,
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={saveContentGroup}
                            sx={{ bgcolor: "#388e3c" }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={cancelContentGroup}
                          >
                            Cancel
                          </Button>
                          <IconButton
                            aria-label="delete"
                            sx={{
                              color: "#0F4343",
                              width: "24px",
                              height: "24px",
                            }}
                          >
                            {!newContentCondition && (
                              <DeleteIcon
                                onClick={() =>
                                  removeContentGroup(newContentGroup?.index)
                                }
                              />
                            )}
                          </IconButton>
                        </Box>

                        <Box sx={{ marginTop: 3 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#171D1A" }}
                          >
                            {filteredContentData.length} matching pages
                          </Typography>
                          {filteredContentData
                            .slice(0, 20)
                            .map((page: any, index: number) => (
                              <Box sx={{ marginTop: 1 }} key={index}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "12px" }}
                                >
                                  {page}
                                </Typography>
                              </Box>
                            ))}
                          {filteredContentData.length > 20 && (
                            <Box sx={{ marginTop: 1 }}>
                              <Typography
                                sx={{ fontSize: "13px", color: "#0266D1" }}
                              >
                                + {filteredContentData.length - 20} other pages
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Card>
              </>
            </Box>
          </LazyLoad>
          )}
        </Box>
      </Loader>
    </Box>
  );
};

export default BusinessDashboard;
