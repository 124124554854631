/* eslint-disable react-hooks/exhaustive-deps */

import "./GoogleAnalyticsSpecificBusinessProfile.scss";

import { Button, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {  useApi, IAPIConfig } from "../../custom_hooks/useApi";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomToast from "../../utils/cutomToast/CustomToast";
import GA4DataDisplay from "../../components/datadisplay/GA4DataDisplay";
import GA4UserDefaultChannelGroup from "../../components/datadisplay/GA4UserDefaultChannelGroup";
import Loader from "../../utils/loader/Loader";

const { Option } = Select;

const GoogleAnalyticsSpecificBusinessProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<string>("7 days");
  const { state } = location;
  const { doApi } = useApi();
  const firstTime = useRef(true);
  const [loading, setLoading] = useState(false);
  const [landingPageDetails, setLandingPageDetails] = useState<any>([]);
  const [firstUserDefaultChannelGroup, setFirstUserDefaultChannelGroup] = useState<any>([]);
  const handleBack = () => {
    navigate(-1);
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    firstTime.current = true;
  };

  useEffect(() => {
    setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
  }, [loading]);

  const fetchLandingPageDetails = async () => {
    setLoading(true);
    const fetchLandingPageConfig: IAPIConfig = {
      URL: `GA4/FetchGA4SiteData`,
      method: "POST",
      payLoad: {
        ...state,
        period: dateRange,
        dimensions: [{ name: "landingPage" }],
        metrics: [
          {
            name: "sessions",
          },
          {
            name: "totalUsers",
          },
          {
            name: "newUsers",
          },
          {
            name: "userEngagementDuration",
          },
          {
            name: "keyEvents",
          },
          {
            name: "grossPurchaseRevenue",
          },
          {
            name: "sessionKeyEventRate",
          },
        ],
      },
    };

    const res: any = await doApi(fetchLandingPageConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData);
      setLandingPageDetails(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
  };

  const fetchFirstUserDefaultChannelGroup = async () => {
    const fetchFirstUserDefaultChannelGroupConfig: IAPIConfig = {
      URL: `GA4/FetchGA4SiteData`,
      method: "POST",
      payLoad: {
        ...state,
        period: dateRange,
        dimensions: [{ name: "firstUserDefaultChannelGroup" }],
        metrics: [
          {
            name: "newUsers",
          },
          {
            name: "engagedSessions",
          },
          {
            name: "engagementRate",
          },
          {
            name: "userEngagementDuration",
          },
          {
            name: "eventCount",
          },
          {
            name: "keyEvents",
          },
        ],
      },
    };

    const res: any = await doApi(fetchFirstUserDefaultChannelGroupConfig, "seedcore");
    if (res.status === 200) {
      const rawData = res.data.data;
      const parsedData = JSON.parse(rawData);
      setFirstUserDefaultChannelGroup(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (firstTime.current) {
      fetchLandingPageDetails();
      fetchFirstUserDefaultChannelGroup();
      firstTime.current = false;
    }
  }, [dateRange]);

  return (
    <div className="container-ga4-specific-profile">
      <div className="date-btn-specific-profile-ga4">
        <Button
          className="back-button-ga4-specific-profile"
          onClick={handleBack}
        >
          <ArrowLeftOutlined /> Back
        </Button>
        <Select
          style={{ width: 200, marginBottom: 16 }}
          value={dateRange}
          onChange={handleDateRangeChange}
        >
          <Option value="7 days">7 days</Option>
          <Option value="14 days">14 days</Option>
          <Option value="28 days">28 days</Option>
          <Option value="3 months">3 months</Option>
          <Option value="6 months">6 months</Option>
          <Option value="8 months">8 months</Option>
          <Option value="12 months">12 months</Option>
          <Option value="16 months">16 months</Option>
        </Select>
      </div>
      <Loader spinning={loading}>
        <div className="table-container-specific-profile-ga4">
          <div className="ga4-specific-profile-title">{state.website}</div>
          <GA4DataDisplay data={landingPageDetails} />
          <GA4UserDefaultChannelGroup data={firstUserDefaultChannelGroup} />
        </div>
      </Loader>
    </div>
  );
};

export default GoogleAnalyticsSpecificBusinessProfile;
