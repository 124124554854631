import "./DataArchives.scss";

import React, { useEffect, useRef, useState } from "react";
import  {useApi, IAPIConfig } from "../../custom_hooks/useApi";

import { Box } from "@mui/material";
import BusinessSection from "../../components/BusinessSection/BusinessSection";
import CustomToast from "../../utils/cutomToast/CustomToast";
import PaginationComponent from "../../components/Pagination/PaginationComponent";

interface Document {
  url: string;
  filename: string;
  type: string;
}

interface Business {
  name: string;
  archiveList: Document[];
}

const DataArchives = () => {
  const { doApi } = useApi();
  const [trainDataArchives, setTrainDataArchives] = useState<Business[]>([]);
  const firstTime = useRef(true);

  const [currentPage, setCurrentPage] = useState(0);
  const recordsPerPage = 10;
  const totalRecords = trainDataArchives.length;

  const startRange = currentPage * recordsPerPage + 1;
  const endRange = Math.min((currentPage + 1) * recordsPerPage, 100);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value - 1);
  };

  // Derive document type based on the filename extension
  const getFileType = (filename: string): string => {
    const ext = filename.split(".").pop()?.toLowerCase();
    switch (ext) {
      case "pdf":
        return "pdf";
      case "xls":
      case "xlsx":
        return "excel";
      case "csv":
         return "excel";
      case "jpg":
      case "jpeg":
      case "png":
        return "image";
      default:
        return "unknown";
    }
  };

  const getTrainDataArchives = async () => {
    try {
      const fetchUserProfilesConfig: IAPIConfig = {
        URL: `/Profile/GetTrainDataArchives`,
        method: "GET",
      };
      const res: any = await doApi(fetchUserProfilesConfig, "seedcore");
      if (!res.data.err) {
        // Parse and add file type for each document
        const parsedData: Business[] = JSON.parse(res.data.data).map(
          (business: Business) => ({
            ...business,
            archiveList: business.archiveList.map((doc) => ({
              ...doc,
              type: getFileType(doc.filename),
            })),
          })
        );
        setTrainDataArchives(parsedData);
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    }
  };

  useEffect(() => {
    if (firstTime.current) {
      getTrainDataArchives();
      firstTime.current = false;
    }
  });

  return (
    <div className="data-archives-container">
      {trainDataArchives.map((business, index) => (
        <BusinessSection
          key={index}
          businessName={business.name}
          documents={business.archiveList}
        />
      ))}

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "8px",
        }}
      >
        <PaginationComponent
          count={Math.ceil(totalRecords / recordsPerPage)}
          page={currentPage + 1}
          onChange={handlePageChange}
        />

        <Box className="text-14 text-center mt-20">
          Showing results {startRange}-{endRange} of {totalRecords}
        </Box>
      </Box>
    </div>
  );
};

export default DataArchives;
